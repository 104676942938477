import * as generalMethods from "@/core/mixins/general_methods";
import axios from "axios";
import { JsonData } from "@/core/formdata/JsonData";

export function openInNewWindow(number) {
	window.open(
		"https://api.whatsapp.com/send?phone=" + number,
		"_blank",
		"toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=1000000, top=1000000, width=10, height=10, visible=none"
	);
}

export function sendInNewWindow(number, text) {
	window.open(
		"https://api.whatsapp.com/send?phone=" + number + "&text=" + encodeURIComponent(text),
		"_blank",
		"toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=1000000, top=1000000, width=10, height=10, visible=none"
	);
}

export function formatMessage(name, arabicMessage, englishMessage, headerMessage) {
	let messageText = "";

	if (generalMethods.getAccountData()["send_whatsapp_arabic_message"] == 1) {
		if (headerMessage) {
			messageText +=
				"\n\n" +
				"شكرا لإختياركم " +
				generalMethods.getAccountData()["arabic_name"] +
				"\n" +
				generalMethods.getAccountData()["city_arabic_name"] +
				" - " +
				generalMethods.getAccountData()["arabic_address"];
		}

		messageText += "\n\n" + arabicMessage;
	}

	if (generalMethods.getAccountData()["send_whatsapp_english_message"] == 1) {
		if (generalMethods.getAccountData()["send_whatsapp_arabic_message"] == 1) {
			messageText += "\n\n-----------------\n\n";
		}

		messageText += "Dear " + name;

		if (headerMessage) {
			messageText +=
				"\n\n" +
				"Thank you for choosing " +
				generalMethods.getAccountData()["english_name"] +
				"\n" +
				generalMethods.getAccountData()["city_english_name"] +
				" - " +
				generalMethods.getAccountData()["english_address"];
		}

		messageText += "\n\n" + englishMessage;
	}

	return messageText;
}

export function sendInvoiceLink(id, number, invoiceLink, name) {
	if (generalMethods.getAccountData()["qbot_instance_status"] == 1) {
		const jsonData = new JsonData();
		jsonData.append("id", id);

		axios
			.post("invoice/whatsapp_send_invoice", jsonData.getData())
			.then((response) => {
				generalMethods.showSuccessToast(response.data);
			})
			.catch(() => {
				generalMethods.showErrorToast("please_try_again_later");
			});
	} else {
		sendInNewWindow(
			number,
			formatMessage(name, "الرجاء التكرم بالضغط على الرابط أدناه لمشاهدة الفاتورة", "Kindly find your invoice for provided services", true) +
				"\n\n" +
				invoiceLink
		);
	}
}

export function recheckCustomerWhatsapp(customerId, successFunction) {
	if (!generalMethods.valueExist(customerId)) {
		return false;
	}

	const jsonData = new JsonData();
	jsonData.append("id", customerId);

	axios
		.post("customers/recheck_whatsapp", jsonData.getData())
		.then((response) => {
			generalMethods.showSuccessToast(response.data.message);

			if (generalMethods.valueExist(successFunction)) {
				successFunction();
			}
		})
		.catch((error) => {
			generalMethods.showErrorToast("please_try_again_later");
		});
}
