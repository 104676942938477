export function initLanguage() {
	const currentLocation = window.location.pathname;

	if (currentLocation == "/en" || currentLocation == "/en/" || currentLocation.startsWith("/en/") || currentLocation.startsWith("en/")) {
		localStorage.setItem("lang", "en");
	} else {
		localStorage.setItem("lang", "ar");
	}

	/*const currentLanguage = localStorage.getItem("lang") ? (localStorage.getItem("lang") as string) : "en";

	let direction = "ltr";
	if (currentLanguage == "ar") {
		direction = "rtl";
	}

	document.getElementsByTagName("html")[0].setAttribute("direction", direction);
	document.getElementsByTagName("html")[0].style.direction = direction;
	document.getElementsByTagName("html")[0].setAttribute("lang", currentLanguage);

	if (direction == "ltr") {
		document.querySelector("link[data-melcha-head='dir-style']")?.setAttribute("href", "/css/style.css");
	} else {
		document.querySelector("link[data-melcha-head='dir-style']")?.setAttribute("href", "/css/style.rtl.css");
	}*/
}
