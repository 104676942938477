import { App } from "vue";
import VueSocialSharing from "vue-social-sharing";

/**
 * Initialize VueApexChart component
 * @param app vue instance
 */
export function initSocialSharing(app: App<Element>) {
	app.use(VueSocialSharing);
}
