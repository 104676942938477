import { initLanguage } from "@/core/plugins/init-application";

initLanguage();

import { createApp } from "vue";
import App from "./App.vue";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initElementPlus } from "@/core/plugins/element-plus";
import { initSocialSharing } from "./core/plugins/social-sharing";
import { initLanguageRouterLink } from "@/core/plugins/inline-language-router-link";
import "@/core/plugins/bootstrap-daterangepicker";

const app = createApp(App);

app.use(store);
app.use(router);
initSocialSharing(app);

ApiService.init(app);
initInlineSvg(app);
initElementPlus(app);
initLanguageRouterLink(app);

app.use(i18n);

/*import Echo from "laravel-echo";
import Pusher from "pusher-js";

declare global {
	interface Window {
		Echo: Echo;
		Pusher: typeof Pusher;
	}
}

window.Pusher = Pusher;
window.Echo = new Echo({
	broadcaster: "pusher",
	key: process.env.VUE_APP_WEBSOCKETS_KEY,
	wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
	wsPort: 6001,
	forceTLS: false,
	disableStatus: true,
	cluster: process.env.VUE_APP_WEBSOCKETS_CLUSTER,
});*/

/*if (process.env.VUE_APP_ENVIRONMENT == "Production") {
	Sentry.init({
		app,
		dsn: "https://9046b4cbb7222f28474a4f33d14c7a64@o4506024789475328.ingest.sentry.io/4506024791179264",
		integrations: [
			new BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				tracePropagationTargets: ["localhost", "melcha.org", /^\//],
			}),
			new Sentry.Replay(),
		],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,
		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,
	});
}*/

app.mount("#app");
