import { translate, valueExist } from "@/core/mixins/general_methods";

export function isIntegerWithoutRequired(rule, value, callback, label, min = null, max = null) {
	if (value == undefined || value == null || value == "") {
		callback();
	} else {
		const integerValue = parseInt(value);

		if (isNaN(integerValue)) {
			callback(new Error(translate(label) + " " + translate("should_be_a_number")));
		} else if (!/^[0-9]+$/.test(value)) {
			callback(new Error(translate(label) + " " + translate("should_be_an_integer")));
		} else if (min != null && integerValue < min) {
			callback(new Error(translate(label) + " " + translate("should_be_more_than") + " " + min));
		} else if (max != null && integerValue > max) {
			callback(new Error(translate(label) + " " + translate("should_be_less_than") + " " + max));
		} else {
			callback();
		}
	}
}

export function isInteger(rule, value, callback, label, min = null, max = null) {
	const integerValue = parseInt(value);

	if (isNaN(integerValue)) {
		callback(new Error(translate(label) + " " + translate("should_be_a_number")));
	} else if (!/^[0-9]+$/.test(value)) {
		callback(new Error(translate(label) + " " + translate("should_be_an_integer")));
	} else if (min != null && integerValue < min) {
		callback(new Error(translate(label) + " " + translate("should_be_more_than") + " " + min));
	} else if (max != null && integerValue > max) {
		callback(new Error(translate(label) + " " + translate("should_be_less_than") + " " + max));
	} else {
		callback();
	}
}

export function isNumber(rule, value, callback, label) {
	const floatValue = parseFloat(value);

	if (isNaN(floatValue)) {
		callback(new Error(translate(label) + " " + translate("should_be_a_number")));
	} else if (!/^-?\d+(\.\d+)?$/.test(value)) {
		callback(new Error(translate(label) + " " + translate("should_be_a_number")));
	} else {
		callback();
	}
}

export function isNumberWithoutRequired(rule, value, callback, label) {
	if (value == undefined || value == null || value == "") {
		callback();
	} else {
		const floatValue = parseFloat(value);

		if (isNaN(floatValue)) {
			callback(new Error(translate(label) + " " + translate("should_be_a_number")));
		} else if (!/^-?\d+(\.\d+)?$/.test(value)) {
			callback(new Error(translate(label) + " " + translate("should_be_a_number")));
		} else {
			callback();
		}
	}
}

export function isLetters(rule, value, callback, label) {
	if (!valueExist(value)) {
		callback();
	} else {
		let valid = true;
		for (let i = 0; i < value.length; i++) {
			if (value[i] >= "0" && value[i] <= "9") {
				valid = false;
				break;
			}
		}

		if (!valid) {
			callback(new Error(translate(label) + " " + translate("should_be_letters")));
		} else {
			callback();
		}
	}
}

export function isPrice(rule, value, callback, label, minValue = 0, maxValue = null) {
	const floatValue = parseFloat(value);

	if (value == undefined || value == null || value == "") {
		callback();
	} else if (isNaN(floatValue)) {
		callback(new Error(translate(label) + " " + translate("should_be_a_number")));
	} else if (!/^-?\d+(\.\d{1,2})?$/.test(value)) {
		callback(new Error(translate(label) + " " + translate("should_be_a_number_with_max_two_decimals")));
	} else if (floatValue < minValue) {
		callback(new Error(translate(label) + " " + translate("should_be_more_than_or_equal_to") + " " + minValue));
	} else if (maxValue != null && floatValue > maxValue) {
		callback(new Error(translate(label) + " " + translate("should_be_less_than_or_equal_to") + " " + maxValue));
	} else {
		callback();
	}
}

export function isNumeric(rule, value, callback, label) {
	if (value == undefined || value == null || value == "") {
		callback();
	} else if (!/^[0-9]+$/.test(value)) {
		callback(new Error(translate(label) + " " + translate("should_contain_only_digits")));
	} else {
		callback();
	}
}

export function isSame(rule, value, callback, label, sameValue, sameValueLabel) {
	if (value != sameValue) {
		callback(new Error(translate(label) + " " + translate("should_be_same_as") + " " + translate(sameValueLabel)));
	} else {
		callback();
	}
}

export function isAfter(rule, value, callback, label, date) {
	if (value <= date) {
		callback(translate(label) + " " + translate("should_be_after") + " " + date);
	} else {
		callback();
	}
}

export function isAfterOrEqual(rule, value, callback, label, date) {
	if (value < date) {
		callback(translate(label) + " " + translate("should_be_after_or_equal") + " " + date);
	} else {
		callback();
	}
}

export function isMultiple(rule, value, callback, label, multipleOf) {
	if (value % multipleOf != 0) {
		callback(translate(label) + " " + translate("should_be_multiple_of") + " " + multipleOf);
	} else {
		callback();
	}
}

export function maxLength(rule, value, callback, label, maxLength) {
	if (value != undefined && value != null && value.length > maxLength) {
		callback(translate(label) + " " + translate("length_should_be_maximum") + " " + maxLength);
	} else {
		callback();
	}
}

export function minLength(rule, value, callback, label, maxLength) {
	if (value != undefined && value != null && value != "" && value.length < maxLength) {
		callback(translate(label) + " " + translate("length_should_be_minimum") + " " + maxLength);
	} else {
		callback();
	}
}

export function length(rule, value, callback, label, length) {
	if (value != undefined && value != null && value.length != length) {
		callback(translate(label) + " " + translate("length_should_be") + " " + length);
	} else {
		callback();
	}
}

export function custom(rule, value, callback, customValue, message) {
	if (customValue) {
		callback(new Error(message));
	} else {
		callback();
	}
}

export function customNot(rule, value, callback, customValue, message) {
	custom(rule, value, callback, !customValue, message);
}

export function isAliasName(rule, value, callback, label) {
	if (!/^[a-zA-Z0-9]*$/.test(value)) {
		callback(translate(label) + " " + translate("should_contain_only_numbers_zero_to_nine_and_english_letters_small_capital"));
	} else {
		callback();
	}
}

export function isEnglishText(rule, value, callback, label) {
	if (value == null || value == undefined) {
		callback();
	} else if (/^[A-Za-z\s]*$/.test(value)) {
		callback();
	} else {
		callback(translate(label) + " " + translate("is_not_english_text"));
	}
}

export function isArabicText(rule, value, callback, label) {
	if (value == null || value == undefined) {
		callback();
	} else if (/^[\u0600-\u06FF\s]*$/.test(value)) {
		callback();
	} else {
		callback(translate(label) + " " + translate("is_not_arabic_text"));
	}
}

export function isEnglishOrArabicText(rule, value, callback, label) {
	if (value == null || value == undefined) {
		callback();
	} else if (/^[A-Za-z\u0600-\u06FF\s]*$/.test(value)) {
		callback();
	} else {
		callback(translate(label) + " " + translate("is_not_arabic_or_english_text"));
	}
}

export function searchForCustomer(rule, value, callback, label) {
	if (value == null || value == undefined || value == "") {
		callback();
	} else if (/^[A-Za-z\u0600-\u06FF\s]*$/.test(value)) {
		if (/^[0-9]+$/.test(value)) {
			callback(new Error(translate(label) + " " + translate("should_be_either_arabic_or_english_text_or_numbers")));
		} else {
			callback();
		}
	} else if (/^[0-9]+$/.test(value)) {
		if (/^[A-Za-z\u0600-\u06FF\s]*$/.test(value)) {
			callback(new Error(translate(label) + " " + translate("should_be_either_arabic_or_english_text_or_numbers")));
		} else {
			callback();
		}
	} else {
		callback(translate(label) + " " + translate("should_be_either_arabic_or_english_text_or_numbers"));
	}
}

export function validateKsaTrnNumber(rule, value, callback, label, countryId) {
	if (countryId == 1) {
		if (value != undefined && value != null && value != "" && value.length > 2 && (value[0] != "3" || value[value.length - 1] != "3")) {
			callback(translate("trn_number_for_ksa_should_start_with_three_and_end_with_three"));
		} else {
			callback();
		}
	} else {
		callback();
	}
}

export function ignoreCallBack(valid) {
	//console.log(ignored);
}
