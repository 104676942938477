import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createBlock(_component_el_form_item, {
    label: _ctx.hideLabel ? null : _ctx.generalMethods.translate(_ctx.label),
    for: _ctx.id == null ? _ctx.prop : _ctx.id,
    prop: _ctx.prop,
    rules: _ctx.rules,
    class: _normalizeClass(`${_ctx.elFormItemClassses}`)
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "above-input"),
      _createVNode(_component_el_input, {
        id: _ctx.id == null ? _ctx.prop : _ctx.id,
        modelValue: _ctx.localModelValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localModelValue) = $event)),
        autocomplete: _ctx.autocomplete,
        dir: _ctx.dir,
        onInput: _cache[1] || (_cache[1] = (value) => _ctx.emitEvent('input', value)),
        type: _ctx.generalMethods.valueExist(_ctx.rows) ? 'textarea' : _ctx.generalMethods.valueExist(_ctx.type) ? _ctx.type : 'text',
        rows: _ctx.rows,
        label: _ctx.ariaLabel,
        disabled: _ctx.disabled,
        readonly: _ctx.readonly,
        placeholder: _ctx.placeholder,
        size: _ctx.size,
        inputmode: _ctx.inputmode
      }, _createSlots({ _: 2 }, [
        (_ctx.showPrepend)
          ? {
              name: "prepend",
              fn: _withCtx(() => [
                _renderSlot(_ctx.$slots, "prepend")
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["id", "modelValue", "autocomplete", "dir", "type", "rows", "label", "disabled", "readonly", "placeholder", "size", "inputmode"]),
      _renderSlot(_ctx.$slots, "under-input")
    ]),
    _: 3
  }, 8, ["label", "for", "prop", "rules", "class"]))
}