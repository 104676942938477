import { MenuComponent, ScrollComponent, StickyComponent, ToggleComponent, DrawerComponent, SwapperComponent } from "@/assets/ts/components";
import { Tooltip, Popover, Progress } from "bootstrap";
import { DataUtil } from "@/assets/ts/_utils";

let reinitializeComponentsIsRunning = false;
let reinitializeSpecificComponentsIsRunning = false;

/**
 * @description Initialize KeenThemes custom components
 */
const initializeComponents = () => {
	setTimeout(() => {
		ToggleComponent.bootstrap();
		StickyComponent.bootstrap();
		MenuComponent.bootstrap();
		ScrollComponent.bootstrap();
		DrawerComponent.bootstrap();
		SwapperComponent.bootstrap();
	}, 0);
};

/**
 * @description Reinitialize KeenThemes custom components
 */
const reinitializeComponents = () => {
	if (reinitializeComponentsIsRunning) {
		return false;
	}

	reinitializeComponentsIsRunning = true;

	setTimeout(() => {
		ToggleComponent.reinitialization();
		StickyComponent.reInitialization();
		MenuComponent.reinitialization();
		reinitializeScrollComponent().then(() => {
			ScrollComponent.updateAll();
		});
		DrawerComponent.reinitialization();
		SwapperComponent.reinitialization();

		/*const progressbarTriggerList = [].slice.call(document.querySelectorAll('[role="progressbar"]')) as Array<any>;
		progressbarTriggerList.map(function (tooltipTriggerEl) {
			return new Progress(tooltipTriggerEl);
		})*/

		if (document.querySelectorAll("tooltip.fade.show").length > 0) {
			document.querySelectorAll(".tooltip.fade.show").forEach((tooltip) => {
				tooltip.remove();
			});
		}
		const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
		tooltipTriggerList.map(function (tooltipTriggerEl) {
			return new Tooltip(tooltipTriggerEl, {
				container: "body",
				trigger: "hover",
			});
		});

		const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
		popoverTriggerList.map(function (tooltipTriggerEl) {
			return new Popover(tooltipTriggerEl);
		});

		reinitializeComponentsIsRunning = false;
	}, 500);
};

const reinitializeSpecificComponents = (components) => {
	if (reinitializeComponentsIsRunning || reinitializeSpecificComponentsIsRunning) {
		return false;
	}

	reinitializeSpecificComponentsIsRunning = true;

	setTimeout(() => {
		if (components.includes("toggle")) {
			ToggleComponent.reinitialization();
		}

		if (components.includes("sticky")) {
			StickyComponent.reInitialization();
		}

		if (components.includes("menu")) {
			MenuComponent.reinitialization();
		}

		if (components.includes("scroll")) {
			reinitializeScrollComponent().then(() => {
				ScrollComponent.updateAll();
			});
		}

		if (components.includes("drawer")) {
			DrawerComponent.reinitialization();
		}

		if (components.includes("swapper")) {
			SwapperComponent.reinitialization();
		}

		/*if (components.includes("progressbar")) {
			const progressbarTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="progressbar"]')) as Array<any>;
			progressbarTriggerList.map(function (tooltipTriggerEl) {
				return new Progress(tooltipTriggerEl);
			});
		}*/

		if (components.includes("tooltip")) {
			document.querySelectorAll(".tooltip.fade.show").forEach((tooltip) => {
				tooltip.remove();
			});
			const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')) as Array<any>;
			tooltipTriggerList.map(function (tooltipTriggerEl) {
				return new Tooltip(tooltipTriggerEl, {
					container: "body",
					trigger: "hover",
				});
			});
		}

		if (components.includes("popover")) {
			document.querySelectorAll(".popover.fade.show").forEach((tooltip) => {
				tooltip.remove();
			});
			const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
			popoverTriggerList.map(function (tooltipTriggerEl) {
				return new Popover(tooltipTriggerEl);
			});
		}

		reinitializeSpecificComponentsIsRunning = false;
	}, 0);
};

const reinitializeScrollComponent = async () => {
	await ScrollComponent.reinitialization();
};

const clearDataUtil = () => {
	DataUtil.store.clear();
};

export { initializeComponents, reinitializeComponents, reinitializeSpecificComponents, reinitializeScrollComponent, clearDataUtil };
