
import { computed, defineComponent } from "vue";

import * as generalMethods from "@/core/mixins/general_methods";
import * as customValidation from "@/core/mixins/custom_validation";

export default defineComponent({
	name: "melcha-element-plus-input",
	props: {
		id: { type: String, default: null },
		label: { type: String, default: null },
		prop: { type: String, default: null },
		modelValue: {},
		required: { type: Boolean, default: false },
		email: { type: Boolean, default: false },
		autocomplete: { type: String, default: null },
		dir: { type: String, default: null },
		rows: { type: Number, default: null },
		ariaLabel: { type: String, default: null },
		disabled: { type: Boolean, default: false },
		readonly: { type: Boolean, default: false },
		extraRules: { type: Array, default: null },
		showPrepend: { type: Boolean, default: false },
		placeholder: { type: String, default: null },
		hideLabel: { type: Boolean, default: false },
		size: { type: String, default: "default" },
		elFormItemClassses: { type: String, default: "" },
		inputmode: { type: String, default: "" },
		type: { type: String, default: null },
	},
	setup(props, { emit }) {
		const localModelValue = computed({
			get() {
				return props.modelValue;
			},
			set(newValue) {
				emit("update:modelValue", newValue);
			},
		});

		const rules = computed(() => {
			let rules = [] as Array<any>;

			if (props.required) {
				rules.push({ required: true, message: generalMethods.getRequiredMessage(props.label) });
			}

			if (props.email) {
				rules.push({ type: "email", message: generalMethods.translate(props.label) + " " + generalMethods.translate("is_email_field") });
			}

			if (props.extraRules != null) {
				rules = rules.concat(props.extraRules);
			}

			return rules;
		});

		const emitEvent = (event, value) => {
			emit(event, value);
		};

		return {
			generalMethods,
			customValidation,

			localModelValue,

			rules,

			emitEvent,
		};
	},
});
