
import { computed, defineComponent, nextTick, onMounted, ref, watch } from "vue";

import * as generalMethods from "@/core/mixins/general_methods";
import * as customValidation from "@/core/mixins/custom_validation";

export default defineComponent({
	name: "melcha-element-plus-mobile-input",
	props: {
		id: { type: String },
		label: { type: String },
		hideLabel: { type: Boolean, default: false },
		prop: { type: String, required: true },
		modelValue: {},
		required: { type: Boolean, default: true },
		countryOptions: { type: Array },
		countryValidation: { type: Boolean, default: true },
		form: {},
		validTextTop: { type: String, default: null },
		validateMaxLength: { type: Boolean, default: false },
		validateNumeric: { type: Boolean, default: true },
		hideList: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		autocomplete: { type: String, default: null },
		ariaLabel: { type: String, default: null },
		darkAndWhiteSelect: { type: Boolean, default: false },
		extraRules: { type: Array, default: null },
		hidePrepend: { type: Boolean, default: false },
		whatsappIcon: { type: Boolean, default: null },
		elFormItemClassses: { type: String, default: "" },
	},
	setup(props, { emit }) {
		onMounted(() => {
			nextTick(() => {
				if (generalMethods.valueExist(localModelValue)) {
					updateCountryFromMobile(props.modelValue, false);
					(props.form as any).resetFields();
				}
			});
		});

		const localModelValue = computed({
			get() {
				return props.modelValue;
			},
			set(newValue) {
				emit("update:modelValue", newValue);
			},
		});

		const country = ref(null as any);

		const mobileValid = ref(false as boolean);

		const rules = computed(() => {
			let rules = [] as Array<any>;

			rules.push({ required: props.required, message: generalMethods.getRequiredMessage(props.label) });

			if (props.validateNumeric) {
				rules.push({
					validator: (rule, value, callaback) => {
						customValidation.isNumeric(rule, value?.startsWith("+") ? value.substring(1, value.length) : value, callaback, props.label);
					},
				});
			}

			if (props.countryValidation) {
				rules.push({
					validator: (rule, value, callaback) => {
						customValidation.custom(
							rule,
							value,
							callaback,
							generalMethods.valueExist(value, true) && !generalMethods.valueExist(country.value),
							generalMethods.getRequiredMessage("prefix")
						);
					},
				});
			}

			if (generalMethods.valueExist(country.value)) {
				if (props.validateMaxLength) {
					rules.push({
						validator: (rule, value, callaback) => {
							customValidation.maxLength(rule, value, callaback, props.label, generalMethods.getMobileValidationLength(value));
						},
					});
				} else {
					rules.push({
						validator: (rule, value, callaback) => {
							customValidation.length(rule, value, callaback, props.label, generalMethods.getMobileValidationLength(value));
						},
					});
				}
			}

			if (generalMethods.valueExist(props.extraRules)) {
				rules = rules.concat(props.extraRules);
			}

			return rules;
		});

		const updateMobileFromCountry = () => {
			localModelValue.value = null;
			if (generalMethods.valueExist(country)) {
				localModelValue.value = "+" + country.value.prefix;
			}

			emitEvent("valueChanged", localModelValue.value);

			updateMobileValid();
		};

		const updateCountryFromMobile = (value, withValidate = true) => {
			const countryOptions = props.countryOptions as Array<any>;

			if (value == null || value == "") {
				country.value = null;
			} else if (value?.startsWith("+")) {
				value = value.substring(1, value.length);
			} else if (value?.startsWith("00")) {
				value = value.substring(2, value.length);
			}

			let exist = false;
			for (let i = 0; i < countryOptions.length; i++) {
				if (countryOptions[i].prefix == 970) {
					if (value?.startsWith(970) || value?.startsWith(972)) {
						country.value = countryOptions[i];
						exist = true;
					}
				} else if (value?.startsWith(countryOptions[i].prefix)) {
					country.value = countryOptions[i];
					exist = true;
				}
			}

			if (!exist) {
				country.value = null;
			}

			if (withValidate) {
				updateMobileValid();
			}
		};

		const updateMobileValid = () => {
			(props.form as any).validateField(props.prop, (mobileValidateValid) => {
				mobileValid.value = mobileValidateValid;
			});
		};

		const emitEvent = (event, value) => {
			if (value != localModelValue.value) {
				emit(event, value);
			}
		};

		watch(
			() => props.modelValue,
			() => {
				updateCountryFromMobile(props.modelValue, true);
			}
		);

		return {
			generalMethods,
			customValidation,

			localModelValue,

			rules,

			country,
			updateMobileFromCountry,
			updateCountryFromMobile,

			mobileValid,

			emitEvent,
		};
	},
});
