
import { defineComponent, nextTick, onMounted, onUnmounted, reactive } from "vue";
import { useI18n } from "vue-i18n/index";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";
import * as generalMethods from "@/core/mixins/general_methods";
import { sendInNewWindow } from "@/core/mixins/whatsapp";

export default defineComponent({
	name: "app",

	setup() {
		const router = useRouter();
		const i18n = useI18n();

		i18n.locale.value = localStorage.getItem("lang") ? (localStorage.getItem("lang") as string) : "en";

		const internetConnection = reactive({
			connected: true as boolean,

			updateInternetConnection: () => {
				if (!navigator.onLine && internetConnection.connected) {
					generalMethods.showInternetDisconnectLoading();
				} else if (navigator.onLine && !internetConnection.connected) {
					generalMethods.closeSwal();
				}

				internetConnection.connected = navigator.onLine;
			},
		});

		onMounted(() => {
			nextTick(() => {
				initializeComponents();
			});

			window.addEventListener("online", internetConnection.updateInternetConnection);
			window.addEventListener("offline", internetConnection.updateInternetConnection);
		});

		onUnmounted(() => {
			window.removeEventListener("online", internetConnection.updateInternetConnection);
			window.removeEventListener("offline", internetConnection.updateInternetConnection);
		});

		(window as any).goToCustomerProfile = (customerId) => {
			generalMethods.goTo({ name: "customer", params: { id: customerId } });
			Swal.close();
		};

		(window as any).goToBillingForCustomer = (customerId) => {
			generalMethods.goTo({ name: "billing", query: { customer_id: customerId } });
			Swal.close();
		};

		(window as any).closeSwal = () => {
			Swal.close();
		};

		(window as any).closeSwalAndSendWhatsappError = () => {
			sendInNewWindow("971506970666", "");
			Swal.close();
		};

		return {
			generalMethods,
		};
	},
});
