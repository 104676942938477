import { createI18n } from "vue-i18n/index";

const messages = {
	en: {
		dashboard: "Dashboard",
		appointments: "Appointments",
		customers: "Customers",
		employees: "Employees",
		employee: "Employee",
		roles_and_permissions: "Roles & Permissions",
		services: "Services",
		groups: "Groups",
		packages: "packages",
		products: "Products",
		stock_management: "Stock Management",
		accounting: "Accounting",
		total_sales: "Total Sales",
		expenses: "Expenses",
		payments: "Payments",
		receivable: "Receivable",
		accounting_reports: "Accounting Reports",
		setting: "Setting",
		general_setting: "General Setting",
		invoice_setting: "Invoice Setting",
		accounting_setting: "Accounting Setting",
		online_setting: "Online Setting",
		notification_setting: "Notifications Setting",
		cs_setting: "Customer Service Setting",
		logout: "Logout",
		edit: "Edit",
		activate: "Activate",
		disable: "Disable",
		filter: "Filter",
		export: "Export",
		actions: "Action",
		name: "Name",
		mobile: "Mobile",
		email: "Email",
		type: "Type",
		loyality_points: "Loyality Points",
		account_balace: "Account Balance",
		identification_method: "Identification Method",
		notes: "Notes",
		status: "Status",
		not_exist_create_new_customer: "Not Exist, Create New Customer?",
		walk_in_customer: "Walk-in Customer",
		general_reports: "General Reports",
		inventory_reports: "Inventory Reports",
		management_reports: "Management Reports",
		marketing_tools: "Marketing Tools",
		date_and_time: "Date & Time",
		invoice_number: "Invoice #",
		subtotal: "SubTotal",
		vat: "VAT",
		total: "Total",
		salesman: "Sales Man",
		description: "Descriptions",
		supplier: "Supplier",
		supplier_invoice: "Supplier Invoice",
		supplier_trn: "Supplier TRN",
		paid_vat: "Paid VAT",
		payment_method: "Payment Method",
		payment_date: "Payment Date",
		reference_number: "Reference #",
		paid_amount: "Paid Amount",
		remaining: "Remaining",
		credit_note_number: "Credit Note #",
		returned_invoice_numner: "Returned Invoice #",
		returned_amount: "Returned Amount",
		returned_vat: "Returned VAT",
		debit_note_number: "Debit Note #",
		supplier_invoice_number: "Supplier Invoice #",
		picture: "Picture",
		experience: "Experience",
		passport_expiry: "Passport Expiry",
		residancy_expiry: "Residancy Expiry",
		joining_date: "Joining Date",
		leaving_date: "Leaving Date",
		show_in_appointment_table: "Show in Appointment table",
		have_access_login: "Have Access Login",
		order: "Order",
		english_name: "English Name",
		arabic_name: "Arabic Name",
		show_to_customer: "Show to Customer",
		group: "Group",
		original_price: "Original Price",
		price_include_vat: "Price including VAT",
		job_time: "Job Time (MIN)",
		offer_price: "Offer Price",
		from: "From",
		to: "To",
		barcode: "Barcode",
		average_cost_price: "Average Cost Price",
		warning_if_reach: "ًWarning if Reach",
		print_barcode: "Print Barcode",
		add: "Add",
		define: "Define",
		appointment_reports: "Appointment Reports",
		appointment_report: "Appointment Report",
		inventory_status: "Inventory Status",
		inventory_movements: "Inventory Movements",
		internal_use_report: "Internal Use Report",
		inventory_evaluation: "Inventory Evaluation",
		booking_source: "Booking Source",
		payment_status: "Payment Status",
		service: "Service",
		service_without_the: "Service",
		booking_date: "Booking Date",
		start_time: "Start Time",
		google_map: "Google Map",
		send_reminder: "Send Reminder",
		sent: "Sent",
		send: "Send",
		marketing_sms: "Marketing SMS",
		inventory_movements_period: "Inventory Movements (Period)",
		internal_use: "Internal Use",
		added_quantity: "Added Quantity",
		sold: "Sold",
		lost: "Lost",
		returned: "Returned",
		remaining_quantity: "Remaining Quantity",
		product_name: "Product Name",
		operation: "Operation",
		quantity: "Quantity",
		service_product: "Service/Product",
		activity_report: "Activity Report",
		daily_payment_summary: "Daily Payment Summary",
		cash: "Cash",
		credit_card: "Credit Card",
		transfer: "Transfer",
		cheuqe: "Cheuqe",
		payment_link: "Payment Link",
		website: "Website",
		date: "Date",
		monthly_payment_summary: "Monthly Payment Summary",
		month: "Month",
		employees_report: "Employees Report",
		employee_commission: "Employee Commission",
		employee_part_of_sales: "Employee Part of Sales",
		details: "Details",
		services_report: "Services Report",
		number_of_time_used: "Number of time used",
		most_used_products: "Most used Products",
		time_of_used: "Time of Used",
		number_of_selling: "Number of Selling",
		most_selled_products: "Most Selled Products",
		online_daily_visits: "Online Daily Visit",
		number_of_visits: "Number of Visits",
		coupons_report: "Coupons Report",
		coupon_id: "Coupon ID",
		serial_number: "Serial Number",
		value: "Value",
		re_print: "Re-Print",
		complain_report: "Complain Report",
		complain_id: "Complain ID",
		customer_discount_report: "Customer Discount Report",
		discount_percente: "Discount %",
		discount_value: "Discount Value",
		survey_report: "Survey Report",
		excellent: "Excellent",
		good: "Good",
		weak: "Weak",
		very_weak: "Very Weak",
		ultimate_tech_product: "Ultimate Technologies Product",
		search: "Search",
		products_management_reports: "Products Management Reports",
		customers_relations_reports: "Customers Relations Reports",
		important_reports: "Important Reports",
		filter_options: "Filter Options",
		include_disabled: "Include Disabled",
		include_disabled_expired: "Include Disabled / Expired",
		show_more: "Show More",
		show_less: "Show Less",
		Ultimate_tech_product: "Ultimate Tech Product",
		melcha: "Melcha",
		signin: "Signin",
		signout: "Signout",
		account_id: "Account ID",
		forget_account_id: "Forget Account ID?",
		remember_me: "Remember me",
		continue: "Continue",
		password: "Password",
		forget_password: "Forget Password?",
		login: "Login",
		sms_marketing: "SMS Marketing",
		business_start_time: "Business Start Time",
		business_end_time: "Business End Time",
		holiday: "Holiday",
		day: "Day",
		total_income: "Total Income",
		acheived_services: "Acheived Services",
		birth_date: "Birth Date",
		nationality: "Nationality",
		address: "Address",
		gender: "Gender",
		overview: "Overview",
		working_hours: "Working Hours",
		Activity: "Activity",
		holidays: "Holidays",
		salary: "Salary",
		commission: "Commission",
		holiday_name: "Holiday Name",
		holiday_start_date: "Holiday Start Date",
		holiday_end_date: "Holiday End Date",
		product_price_search: "Product Price Search",
		add_internal_use: "Add Internal Use",
		send_daily_reports: "Send Daily Reports",
		send_backup_reports: "Send Backup Reports",
		language: "Language",
		lock_screen: "Lock Screen",
		training: "Training",
		quick_setup: "Quick Setup",
		english: "English",
		arabic: "Arabic",
		employee_details: "Employee Details",
		extra_information: "Extra Information",
		rights: "rights!",
		add_employee: "Add Employee",
		save: "Save",
		customer_details: "Customer Details",
		trn: "TRN",
		view_permissions: "View Permissions",
		user_has: "User Has",
		rights_to_the_system: "Rights to the System,",
		control_access_login: "Control Access Login",
		add_a_customer: "Add a Customer",
		packages_and_membership: "Packages & Memberships",
		offers_and_membership: "Offers & Memberships",
		coupons: "Coupons",
		new_invoice: "New Invoice",
		new_deposit: "New Deposit",
		transactions: "Transactions",
		balance_till_the_moment: "Balance till the Moments",
		this_year: "This Year",
		add_appointment: "Add Appointment",
		profit_and_loss_report: "Profit & Loss Report",
		cash_flow_report: "Cash Flow Report",
		other_accounting_reports: "Other Accounting Reports",
		debit_notes_report: "Debit Notes Report",
		credit_notes_report: "Credit Notes Report",
		credit_and_debit_notes: "Credit & Debit Notes",
		edit_employee_info: "Edit Employee Informations",
		customer_invoices: "Customer Invoices",
		saturday: "Saturday",
		sunday: "Sunday",
		monday: "Monday",
		tuesday: "Tuesday",
		wednesday: "Wednsday",
		thursday: "Thursday",
		friday: "Friday",
		edit_employee: "Edit Employee",
		edit_extra_information: "Edit Extra Informations",
		expiry_date: "Expiry Date",
		show_in_billing: "Show In Billing",
		yes: "Yes",
		no: "No",
		show_in_appointments: "Show In Appointments",
		upload_photo: "Upload Photo",
		administrator: "Administrator",
		total_users_with_roles: "Total Users With Roles:",
		add_a_Role: "Add a Role",
		role_name: "Role Name",
		enter_a_role_name: "Enter A Role Name",
		role_permissions: "Role Permissions",
		read: "Read",
		write: "Write",
		create: "Create",
		administrator_access: "Administrator Access",
		select_all: "Select All",
		cancel: "Cancel",
		add_new_role: "Add New Role",
		assign_permissions: "Assign Permissions",
		add_roles: "Add Roles",
		edit_roles: "Edit Roles",
		stocks_management: "Stock Managements",
		add_quantity: "Add Quantity",
		adjust_quantity: "Adjust Quantity",
		delete: "Delete",
		show: "show",
		adjust: "Adjust",
		all_features: "All Features",
		reports: "Reports",
		most_used: "Most Used",
		most_selled: "Most Selled",
		receipt: "Receipt",
		loyality_program: "Loyality Program",
		discount_codes: "Discount Codes",
		tax: "Tax",
		tax_name: "Tax Name",
		percentage: "Percentage %",
		business_hours: "Business Hours",
		discount_code: "Discount Code",
		loyality_code: "Loyality Code",
		points_vs_paid_currency: "Points / Paid Currency",
		activate_qr: "Activate QR",
		admin_email: "Admin Email",
		country: "Country",
		city: "City",
		address_in_arabic: "Address in Arabic",
		address_in_english: "Address in English",
		trn_number: "TRN Number",
		import_data: "Import Data",
		make_sure_to_use_system_format_from_action_button: "Make Sure to use system format from action button",
		upload_now: "Upload Now",
		link_employee_with_service: "Link Employee with service",
		link_employee_with_product: "Link Employee with product",
		allow_hold_invoice: "Allow hold Invoice",
		controller: "Controller",
		download_groups_template: "Download Groups Template",
		download_services_template: "Download Services Template",
		download_products_template: "Download Products Template",
		edit_business_informations: "Edit Business Informations",
		invoice_controller: "Invoice Controller",
		show_employee_in_customer_invoice: "Show Employees Names In Customer Invoice",
		show_customer_information_in_invoice: "Show Customer information in Invoice ",
		send_email_to_employee_with_done_service: "Send Email to employee with done service",
		send_email_to_employee_with_ordered_service: "Send Email to employee with ordered service",
		activate_winning_box: "Activate Winning Box",
		invoice_header: "Invoice Header",
		invoice_footer: "Invoice Footer",
		add_customer: "Add Customer",
		add_more_data: "Add more data",
		birthday: "Birthday",
		gifts_cards_reports: "Gifts Cards Reports",
		redeem_loyality_points: "Redeem Loyality Points",
		reminder: "Reminder",
		add_balance_to_customer_through_loyality_points: "Add Balance to Customer through redeeming Loyality Points,",
		gifts_cards: "Gifts Cards",
		send_email: "Send Email",
		whatsapp: "Whatsapp",
		use_service: "Use Service",
		header_and_footer: "Invoice Header & Footer",
		active_gifts_cards: "Active Gifts Cards",
		suppliers: "Suppliers",
		expenses_types: "Expenses Types",
		online_controller: "Online Services Controller",
		is_working_day: "Working Day",
		add_automatically: "Add Automatically",
		primary_informations: "Primary Informations",
		setup_your_account_details: "Setup Your Account Details",
		activate_vat: "Activate VAT",
		activate_vat_in_your_business: "Activate VAT in your Business",
		employee_info: "Employees Info",
		add_employees_info: "Add Employees Info",
		set_business_hours: "Set Business Hours",
		set_how_invoice_looklike: "Set how Invoice looklike",
		activate_services: "Activate Services",
		activate_services_and_set_prices: "Activate Services & set prices",
		completed: "Completed",
		woah_we_are_here: "Woah, we are here",
		aaccount_info: "Account Info",
		if_you_need_to_switch_languages: "If you need to switch languages, ",
		switch_language: "Switch Language",
		upload_business_logo: "Upload Business Logo",
		search_products: "Search Products",
		search_by_barcode_or_name: "Search By Barcode OR Name",
		balance_due: "Balance Due",
		sell_as_package: "Sell As Package",
		add_new_payment: "Add New Payment",
		remove_payment: "Remove Payment",
		issue_invoice: "Issue Invoice",
		cancel_issue_invoice: "Cancel Issue Invoice",
		number_of_items: "Number of Items",
		proceed_payment: "Proceed Payment",
		hold_invoice: "Hold Invoice",
		add_gift_card: "Add Gift Card",
		submit: "Submit",
		add_free_text: "Add Free Text",
		free_text: "Free Text",
		invoice: "Invoice",
		remove: "Remove",
		price: "Price",
		excel: "Excel",
		print: "Print",
		export_options: "Export Options",
		disabled: "Disabled",
		allow_dept_in_invoice: "Allow Debit in Invoice",
		invoice_notes: "Invoice Notes",
		forget_accoubt_id: "Forget Account ID?",
		coupon_gift_card: "Coupon / Gift Card",
		ok: "Ok",
		enter_your_email_to_receive_your_account_id: "Enter Your Email to receive your account ID",
		back: "Back",
		enter_your_email_to_reset_your_password: "Enter your Email to reset your Password",
		hide_from_customer: "Hide from Customer",
		add_group: "Add Group",
		add_service: "Add Service",
		sell_price: "Sell Price",
		sell_price_including_vat: "Sell Price including VAT",
		add_package: "Add Package",
		edit_package: "Edit Package",
		sell_price_including_price: "Sell Price including price",
		initial_quantity: "Initial Quantity",
		warning_if_stock_reach: "Warning if stock reach",
		define_product: "Define Product",
		edit_product: "Edit Product",
		working_day: "Working Day",
		new_times_warning: "** Times will be applied on employees and appointment table",
		edit_business_hours: "Edit Business Hours",
		edit_tax: "Edit TAX",
		confirm: "Confirm",
		editing_tax_will_affect_all_service_and_products: "** Editing Tax will affect all services and products.",
		this_will_change_all_services_and_products_prices_according_to_new_vat:
			"This will change all services and products prices according to the new VAT",
		code: "Code",
		category: "Category",
		add_discount_code: "Add Discount Code",
		edit_discount_code: "Edit Discount Code",
		superadmin: "Superadmin",
		disable_service: "Disable Service",
		edit_loyality_program: "Edit Loyality Program",
		edit_invoice_setting: "Edit Invoice Setting",
		edit_invoice_header_and_footer: "Edit Invoice Header & Footer",
		header: "Header",
		footer: "Footer",
		receptionist: "Receptionist",
		manager: "Manager",
		hair_services: "Hair Services",
		hair_treatment: "Hair Treatment",
		hair_color: "Hair Color",
		makeup: "Makeup",
		manicurist: "Manicurist",
		pedicurist: "Pedicurist",
		morrocan_bath: "Morrocan Bath",
		henna: "Henna",
		message: "Message",
		facial: "Facial",
		wax: "Wax",
		halawa: "Halawa",
		threding: "Threding",
		eyebrow: "Eyebrow",
		lashes: "Lashes",
		general_services: "General Services",
		not_specific: "Not Specific",
		relatives: "Relative",
		relatives_acquaintances: "Relative / Acquaintances",
		facebook: "FaceBook",
		instagram: "Instagram",
		snapchat: "Snapchat",
		google: "Google",
		blogger_ads: "Blogger Ads",
		coincidence: "Coincidence",
		other: "Other",
		male: "Male",
		female: "Female",
		business: "Business",
		profile: "Profile",
		activities: "Activities",
		this_month: "This Month",
		last_month: "Last Month",
		invoice_payments_should_be_equal_to_invoice_total: "Payments total should be equal to invoice total",
		invoice_payments_should_be_less_or_equal_to_invoice_total: "Payments total should be less or equal to invoice total",
		invoice_payments_is_not_the_same_as_invoice_total: "Invoice payments is not the same as invoice total",
		package_price_should_be_less_than_invoice_total: "Package price should be less than invoice total",
		edit_service: "Edit Service",
		select_package_expiry: "Select Package Expiry",
		days: "Days",
		select_date_manually: "Select Date Manually",
		employees_information: "Employees Informations",
		send_whatsapp: "Send Whatsapp",
		disable_employee: "Disable Employee",
		employee_timing: "Employee Timing",
		remaining_amount_will_be_registered_in_receivable: "Remaining amount will be registered in receivable",
		no_show: "No Show",
		pos: "POS",
		today: "Today",
		yesturday: "Yesturday",
		whats_up_today: "What's up Today",
		booking: "Booking",
		profit_and_loss: "Sales & Expenss",
		profit: "profit",
		loss: "Loss",
		net_vat: "Net VAT",
		collected_vat: "Collected VAT",
		due_on_goverment: "Due On Geverment",
		due_to_goverment: "Due To Geverment",
		search_by_mobile_or_name: "Search by Mobile or Name...",
		invoice_added_successfully: "Invoice Issued Successfully",
		order_submitted_successfully: "Order Submitted Successfully",
		my_profile: "My Profile",
		change_password: "Change Password",
		custom_range: "Custom Range",
		yesterday: "Yesterday",
		last_quarter: "Last Quarter",
		advanced: "Advanced",
		bank_status: "Bank Status",
		create_invoice: "Create Invoice",
		appointment: "Appointment",
		block_time: "Block Time",
		cancelled: "Cancelled",
		scheduled: "Scheduled",
		check_in: "Check-in",
		acheived: "Acheived",
		frequent: "Frequent",
		missed: "Missed",
		new: "New",
		end_time: "End Time",
		is_required: "is Required",
		apply: "Apply",
		show_alone: "Show Alone",
		show_all: "Show All",
		am: "AM",
		pm: "PM",
		groups_and_services: "Groups & Services",
		selected_appointments: "Selected Appointments",
		minutes_short: "Min",
		by: "By",
		search_customer: "Search Customer",
		appointment_added_successfully: "Appointment Added Successfully",
		check_in_now: "Check-in Now",
		summary: "Summary",
		invoices: "Invoices",
		no_email: "No Email",
		select: "Select",
		customer: "Customer",
		system: "System",
		online: "Online",
		showing: "Showing",
		records: "Records",
		employee_changed: "Employee Changed",
		expired: "Expired",
		id: "ID",
		used_gifts_cards: "Used Gifts Cards",
		whatsapp_marketing: "Whatsapp Marketing",
		reset_all: "Reset All",
		active: "Active",
		unpaid: "Unpaid",
		paid: "Paid",
		waiting: "Waiting",
		used: "Used",
		authorization_code: "Authorization Code",
		add_deposit: "Add Deposit",
		amount: "Amount",
		payment: "Payment",
		customer_form: "Customer Form",
		balance: "Balance",
		loyality: "Loyality",
		package_expiry: "Package Expiry",
		appointments_controller: "Appointments Controller",
		checkout: "Checkout",
		cancel_all: "Cancel All",
		add_more_services: "Add More Services",
		daily_activity_logs: "Daily Activity Logs",
		redeemed_amount: "Redeemed Amount",
		payments_details: "Payments Details",
		access_level: "Access Level",
		control_access: "Control Access",
		role: "Role",
		edit_working_hours: "Edit Working Hours",
		stock_operations: "Stock Operations",
		change_question: "Change?",
		sell_without_stocks: "Sell without Stocks",
		welcome: "Welcome",
		expired_gifts_cards: "Expired Gifts Cards",
		prefrered_language: "Preferred Language",
		left_working: "Left Working",
		if_you_need_more_info_please_check_out: "If you need more info, please check out",
		save_and_start: "Save And Start",
		please_wait_three_dots: "Please Wait...",
		data_saved_successfully: "Data Saved Successfully",
		backup: "Backup",
		backup_account_data: "Backup Account Data",
		email_it_now: "Email it now",
		categories: "Categories",
		offers: "Offers",
		menu: "Menu",
		preferred_language: "Preferred Language",
		add_offer: "Add Offer",
		renew: "Renew",
		disable_offer: "Disable Offer",
		renew_offer: "Renew Offer",
		are_you_sure_you_want_to_renew_the_offer: "Are you sure you want to renew_the_offer?",
		offer_expiry: "Offer Expiry",
		select_offer_expiry: "Select Offer Expiry",
		offer_service_usage: "Offer Service Usage",
		add_custom_category: "Add Custom Category",
		add_category: "Add Category",
		edit_category: "Edit Category",
		disable_category: "Disable Category",
		invoice_gift_card_value_more_than_invoice_total_warning:
			"Gift card value is more than total sales, add more services to be able to use this gift card",
		suggest_development: "Suggest Development",
		home: "Home",
		wizard: "Wizard",
		select_category: "Select Category",
		add_custom_service: "Add Custom Service",
		coming_soon: "Coming Soon",
		expired_gift_card: "Expired Gift Card",
		used_gift_card: "Used Gift Card",
		legacy_version: "Legacy Version",
		invoice_logo: "Invoice Logo",
		on: "On",
		off: "Off",
		menu_off: "Menu Off",
		menu_on: "Menu On",
		setup_menu_now: "Setup Menu Now",
		business_setup_guide: "Business Setup Guide",
		owner_email: "Owner Email",
		request_free_trial: "Request Free Trial",
		login_by_email: "Login By Email",
		login_by_account_id: "Login By Account ID",
		thank_you_for_your_time: "Thank you for your time!",
		request_sent_successfully: "Request sent successully",
		we_will_send_you_instructions_to_get_started_via_email_or_whatsapp_within_24_hours:
			"We will send you instructions to get started via email or whatsapp within 24 hours",
		privacy_policy: "Privacy Policy",
		features: "Features",
		programs: "Programs",
		contact_us: "Contact Us",
		website_login: "Login",
		any_time_any_device_any_where: "Any Time, Any Where, Any Device",
		smart_simple_secure: "Smart. Simple. Secure",
		customers_support: "Customers Support",
		rules_and_permissions: "Rules and Permissions",
		reports_and_analysis: "Reports and Analysis",
		secure_cloud_system: "Secured Cloud Systems",
		i_confirm_that_i_have_read_understood_and_agree_to_the: "I confirm that I have read, understood and agree to the",
		you_agree_with_the_handling_business_data_in_accordance_to_our:
			"you agree with the storage and handling of your data by this website in accordance with our",
		all_rights_reserved: "All Rights Reserved",
		terms_and_conditions: "Terms & Conditions",
		ultimate_technology: "Ultimate Technolgies",
		more: "More",
		melcha_connect: "Melcha Connect",
		main_office: "Main Office",
		uae: "UAE",
		ksa: "KSA",
		kuwait: "Kuwait",
		later: "Later",
		melcha_management_software_enhance_business_opearations_and_allow_oweners_and_decision_maker_complete_overview:
			"Melcha management software enhance business opearations and allow oweners and decision maker complete overview",
		it_include_online_booking_digital_menu_home_services_booking_social_media_links_and_others:
			"It includes online booking, digital menu (either from link or QR), Home services booking, Social media links, and others.",
		our_services: "Our Services",
		ultimate_is_palestinian_information_technologies:
			"Ultimate is Palestinian information Technologies Company specialize in software business development, Web & apps development, Graphics Design, Online Marketing, SEO, Web hosting, Business Email.",
		technical_support_and_backup: "Technical Support & backup",
		our_team_consist:
			"Our Team have an experience for more than 15 years in business software development, and we are train customers on how to use software, and taking a daily backup to avoid lossing customers data.",
		melcha_is_cloud_base_software: "Traditional marriage the following feautures: ",
		respoinsive: "Responsive for screen device",
		online_software: "Online Software",
		multi_languages_support_arabic: "Multi languages & Support Arabic",
		send_sms_through_whatsapp: "Send SMS Through Whatsapp",
		appointment_system: "Appointment System",
		accounting_and_vat_calculations: "Accounting & VAT Calculations",
		customers_survey_system: "Customers Survey System",
		daily_and_backup_reports: "Daily & Backup Reports",
		respoinsive_descriptions:
			"All programs developed by us are built to be compatible with different screen sizes, so you can use them either from a mobile phone or a computer screen.",
		online_software_descriptions:
			"The programs that we develop can work on them from the cloud internet from anywhere in the world, so you have no limits to manage your business wherever you are and at any time.",
		roles_and_permissions_descriptions:
			"You can simply control the access rights of the employees, and grant the appropriate permissions to each of the employees according to his job grade and the information that is allowed to be viewed.",
		multi_languages_support_arabic_descriptions:
			"Our programs automatically contain English and Arabic, so regardless of the languages ​​and skills your employee holds. Our programs will suit everyone.",
		send_sms_through_whatsapp_descriptions:
			"Our programs will solve for you the issue of communicating with customers for issues of correspondence or reminders using the famous WhatsApp platform, as it is secured, fully encrypted.",
		appointment_system_descriptions:
			"The software contains an advanced reservation system, easy to use, with a clear schedule that will cover all your appointment scheduling needs",
		accounting_and_vat_calculations_descriptions:
			"Get a detailed report of sales and payment methods for customers with their debts and all your accounting needs for daily and monthly sales, as well as calculating sales tax on goods sold.",
		customers_survey_system_descriptions:
			"Our software contains an electronic system for customer questionnaires, to find out their satisfaction with the services or goods provided by the work team, as well as to receive complaints in a modern manner.",
		daily_and_backup_reports_descriptions:
			"You will automatically receive daily reports to your email inbox to keep you up to date on all your business matters, sales volumes, payments and business opportunities.",
		try_software_for_seven_days_free: "Try Software for Seven Days Free",
		training_descriptions:
			"We believe that watching video is the best way to learn and train, and that effective global best practices for training are by watching short videos of specific treatments in the software system.",
		already_have_an_account: "Already Have An Account?",
		valid: "Valid",
		send_message: "Send Message",
		contact_us_message: "Message",
		now_you_can_contact_us_any_time: "Now .. you can contact us any time",
		commercial_partners_in_gulf: "Commercial partners in GULF",
		telephone: "Telephone",
		faq: "Frequent Asked Questions",
		we_received_your_message_our_team_will_contact_you_soon: "We received your message, our team will contact you soon",
		business_ready: "Business Ready",
		wrong_number: "Wrong Number",
		online_services: "Online Services",
		english_qr: "English QR",
		arabic_qr: "Arabic QR",
		business_alias: "Business Alias",
		disable_online_setting: "Disable Online Setting",
		your_online_services_will_be_disabled_if_you_want_to_change_the_alias_name_please_contact_support:
			"Your online services will be disabled, if you want to change the alias name please contact support",
		activate_online_setting: "Activate Online Setting",
		arabic_link: "Arabic Link",
		english_link: "English Link",
		price_list: "Price List",
		incomplete_number: "Incomplete Number",
		percentage_can_not_be_zero_when_status_is_active: "Percentage Can't be zero when status is active",
		show_price_list: "Show Price List",
		show_price_include_vat_message: "Show Price Include VAT Message",
		show_offers: "Show Offers",
		press_ctrl_with_plus_or_minus_to_adjust_the_screen: "Press (CTRL +) or (CTRL -) to adjust program with your screen",
		show_menu: "Show Menu",
		online_booking: "Online Booking",
		complains: "Complains & Suggestions",
		home_services: "Home Services",
		link: "Link",
		edit_customer: "Edit Customer",
		add_note: "Add Note",
		edit_note: "Edit Note",
		working: "Working",
		activated_products: "Activated Products",
		not_exist_request_define_product: "Not Exist, Request Define Product?",
		active_products: "Active Products",
		request_define_product: "Request Define Product",
		proceed: "Proceed",
		add_expense: "Add Expense",
		time: "Time",
		i_do_not_want_to_add_expense: "I do not want to add expense",
		move_up: "Move Up",
		move_down: "Move Down",
		appointments_whatsapp: "Appointments Whatsapp",
		offers_whatsapp: "Offers Whatsapp",
		alias_name: "Alias Name",
		social_media: "Social Media",
		google_maps: "Google Maps",
		facebook_page_id: "Facebook Page ID",
		owner: "Owner",
		this_will_change_all_services_and_products_prices_according_to_the_new_vat:
			"This will change all services and products prices according to the new VAT",
		customer_added_successfully: "Customer Added Successfully",
		employee_form: "Employee Form",
		online_setting_privacy_policy_aknowledge:
			"You acknowledge that you have read Melcha “site” Privacy Policy and agree to all of its terms and conditions, as well as, you acknowledge that you aware of online portal privacy policy“Melcha Connect ”& aware that information’s mentioned above will be accessed by (customer/s) from Melcha website, MELCHA IOS App,MELCHA Android App.By continuing to use the Site and Services, you agree to be bound by this policy. If you do not agree to be bound by this Policy, you are not authorized to use or access the MELCHA site.",
		apply_timing_for_all_days: "Apply timing for all days",
		screen_adjust: "Screen Adjust",
		coinsedence: "Coinsendence",
		primary_informations_updated_successfully: "Primary informations updated successfully",
		tax_updated_successfully: "Tax updated successfully",
		tax_status_updated_successfully: "Tax status updated successfully",
		new_updated_business_timing_will_be_applied_to_all_employees_working_hours:
			"New/Updated Business timing will be applied to all Employees Working Hours",
		new_updated_business_timing_will_be_applied_appointment_screen: "New/Updated Business iiming will be applied on Appointment Screen",
		working_hours_updated_successfully: "Working hours updated successfully",
		employee_added_successfully: "Employee Added Successfully",
		services_activated_successfully: "Services Activated Successfully",
		select_group: "Select Group",
		there_are_services_not_saved_do_you_want_to_save_them_before_change_group:
			"There are services not saved, do you want to save them before change group?",
		in: "in",
		email_should_be_correct: "Booking email should be correct",
		style: "Style",
		background_image: "Background Image",
		primary_color: "Primary Color",
		add_role: "Add Role",
		edit_role: "Edit Role",
		view_role: "View Role",
		view: "View",
		no_access: "No Access",
		profile_and_activities: "Profile & Activities",
		full_access: "Full Access",
		cashier: "Cashier",
		sell_without_stock: "Sell Without Stock",
		i_would_like_to_book_home_service_please: "I would like to book home service please",
		complain_sent_successfully: "Complain sent successfully",
		book_appointment: "Book Appointment",
		telephone_should_start_with_plus_or_zero_zero: "Telephone should start with (+ or 00)",
		whatsapp_should_not_start_with_plus_or_zero_zero: "Whatsapp should not start with (+ or 00)",
		username_is_incorrect: "Username is incorrect",
		password_is_incorrect: "Password is incorrect",
		should_be_a_number: "should be a number",
		should_be_an_integer: "should be an integer",
		should_be_more_than: "should be more than",
		should_be_less_than: "should be less than",
		should_be_letters: "should be letters",
		should_be_more_than_or_equal_to: "should be more than or equal to",
		should_be_less_than_or_equal_to: "should be less than or equal to",
		should_be_a_number_with_max_two_decimals: "should be a number with max 2 decimals",
		should_contain_only_digits: "should contain only digits",
		should_be_same_as: "should be same as",
		should_be_after: "should be after",
		should_be_after_or_equal: "should be after or equal",
		should_be_multiple_of: "should be multiple of",
		length_should_be_maximum: "length should be maximum",
		length_should_be: "length should be",
		should_contain_only_numbers_zero_to_nine_and_english_letters_small_capital: "should contain only number (0 - 9) and english letters (a-z)(A-Z)",
		prefix: "Prefix",
		is_email_field: "is email field",
		this_field_should_be_email: "This field should be email",
		access: "Access",
		allow_change_price_in_invoice: "Allow Change Price In Invoice",
		customers_care_services: "Customers Care Services",
		others: "Others",
		sell: "Sell",
		sell_gift_card: "Sell Gift Card",
		gift_card_value: "Gift Card Value",
		country_of_residency: "Country Of Residency",
		you_are_connected_to: "You are connected to",
		current_password: "Current Password",
		new_password: "New Password",
		confirm_new_password: "Confirm New Password",
		i_want_to_add_expense: "I want to add expense",
		quantity_added_successfully: "Quantity Added Successfully",
		stock_operations_report: "Stock Operations Report",
		quantity_adjusted_successfully: "Quantity Adjusted Successfully",
		quantity_used_successfully: "Quantity Used Successfully",
		create_credit_note: "Create Credit Note",
		credit_note: "Credit Note",
		service_on_item: "Service / Item",
		qty: "Qty",
		returned_value_including_vat: "Returned Value Including VAT",
		i_want_to_generate_manual_credit_note: "I want to generate manual credit note",
		edit_payment: "Edit Payment",
		business_name: "Business Name",
		daily_reports: "Daily Reports",
		remove_appointment: "Remove Appointment",
		are_you_sure_you_want_remove_the_appointment: "Are you sure you want to remove the appointment?",
		are_you_sure_you_want_to_deposit_amount_to_this_customer: "Are you sure you want to deposit ({amount}) to this customer?",
		are_you_sure_to_add_penalty_with_amount_to_this_customer: "Are you sure you want to add penalty with ({amount}) to this customer?",
		deposit: "Deposit",
		customer_edited_successfully: "Customer edited successfully",
		gifts_cards_used: "Gifts Cards Used",
		gift_card: "Gift Card",
		custom: "Custom",
		allow_add_free_gift_card: "Allow Add Free Gift Card",
		add_free_gift_card: "Add Free Gift Card",
		add_expenses_type: "Add Expense Type",
		add_supplier: "Add Supplier",
		expense_type_added_successfully: "Expense Type Added Successfully",
		supplier_added_successfully: "Supplier Added Successfully",
		expense_type_status_updated_successfully: "Expense Type Status Updated Successfully",
		expense_type_already_exist: "Expense Type Already Exist",
		supplier_status_updated_successfully: "Supplier Status Updated Successfully",
		supplier_already_exist: "Supplier Already Exist",
		supplier_edited_successfully: "Supplier Edited Successfully",
		expense_type_edited_successfully: "Expense Type Edited Successfully",
		edit_expense_type: "Edit Expense Type",
		edit_supplier: "Edit Supplier",
		employee_profile_updated_successfully: "Employee Profile Updated Successfully",
		pay_pending: "Pay Pending",
		add_payment: "Add Payment",
		payment_added_successfully: "Payment Added Successfully",
		partially_paid: "Partially Paid",
		offer_added_successfully: "Offer Added Successfully",
		offer_edited_successfully: "Offer Edited Successfully",
		some_block_times_conflict_with_previous_block_times: "Some block times conflict with previous block times",
		block_times_added_successfully: "Block Times added successfully",
		some_employees_are_not_allowed: "Some employees are not allowed",
		employee_does_not_have_email: "Employee does not have email",
		email_sent_successfully: "Email sent successfully",
		block_time_cancelled_successfully: "Block Time cancelled successfully",
		service_added_successfully: "Service Added Successfully",
		customer_has_another_appointment_at_the_same_time: "Customer has another appointment at the same time",
		you_have_another_appointment_at_the_same_time: "You have another appointment at the same time",
		please_add_at_least_one_service_to_the_appointment: "Please add at least 1 service to the appointment",
		employee_has_another_appointment_at_the_same_time: "Employee has another appointment at the same time",
		some_appointments_conflict_with_block_times: "Some appointments conflict with block times",
		employee_changed_successfully: "Employee changed successfully",
		employee_is_the_same_as_old_employee: "Employee is the same as old employee",
		employee_not_exist: "Employee not exist",
		item_not_exist: "Item not exist",
		changed: "Changed",
		edit_gift_card: "Edit Gift Card",
		gift_card_edited_successfully: "Gift Card Edited Successfully",
		reset_all_customers: "Reset All Customers",
		are_you_sure_you_want_to_reset_the_sent_flag_for_all_customers: "Are you sure you want to reset the sent flag for all customers?",
		sent_status_reseted_successfully: "Sent status reseted successfully",
		customer_status_updated_successfully: "Customer status updated successfully",
		employee_status_updated_successfully: "Employee status updated successfully",
		employee_access_edited_successfully: "Employee access edited successfully",
		employee_edited_successfully: "Employee edited successfully",
		new_updated_timing_will_be_applied_to_the_selected_employee_in_appointment_screen:
			"New/Updated timing will be applied to the selected employee in appointment screen",
		new_updated_timing_will_be_applied_to_the_selected_employee_in_online_booking_screen:
			"New/Updated timing will be applied to the selected employee in online booking screen",
		add_holiday: "Add Holiday",
		holiday_added_successfully: "Holiday Added Successfully",
		edit_holiday: "Edit Holiday",
		holiday_edited_successfully: "Holiday Edited Successfully",
		holiday_deleted_successfully: "Holiday Deleted Successfully",
		note_added_successfully: "Note Added Successfully",
		note_edited_successfully: "Note Edited Successfully",
		note_deleted_successfully: "Note Deleted Successfully",
		role_added_successfully: "Role Added Successfully",
		role_edited_successfully: "Role Edited Successfully",
		credit_note_created_successfully: "Credit Note Created Successfully",
		new_supplier: "New Supplier",
		new_expense_type: "New Expense Type",
		expense_added_successfully: "Expense Added Successfully",
		payment_edited_successfully: "Payment Edited Successfully",
		billing: "Billing",
		category_edited_successfully: "Category Edited Successfully",
		remove_category_and_it_is_services_from_billing_screen: "Remove category & its services from billing screen",
		remove_category_and_it_is_services_from_appointment_screen: "Remove category & its services from appointment screen",
		remove_category_and_it_is_services_from_menu_screen: "Remove category & its services from menu screen",
		remove_services_within_group_from_assigned_employee: "Remove services within group from assigned employee",
		hide_category_from_customers: "Hide category from customers",
		status_updated_successfully: "Status Updated Successfully",
		show_to_customer_updated_successfully: "Show to customer updated successfully",
		order_updated_successfully: "Order Updated Successfully",
		category_added_successfully: "Category Added Successfully",
		category_already_exist: "Category Already Exist",
		category_exist_in_categories_to_activate_you_can_activate_it_from_there: "Category exist in categories to activate, you can activate it from there",
		service_edited_successfully: "Service Edited Successfully",
		remove_service_from_billing_appointment_menu_screens: "Remove service from (Billing, Appointment, Menu) screens",
		remove_service_from_assigned_group: "Remove service from assigned group",
		remove_service_from_assigned_employee: "Remove service from assigned employee",
		hide_service_from_customers: "Hide service from customers",
		remove_offer_from_billing_menu_screens: "Remove offer from (Billing & Menu) screens",
		hide_offer_from_customers: "Hide offer from customers",
		stock: "Stock",
		product_edited_successfully: "Product Edited Successfully",
		disable_product: "Disable Product",
		disable_selling_adding_adjusting_withdraw_from_inventory: "Disable selling, adding, adjusting, withdraw from inventory",
		product_status_updated_successfully: "Product status updated successfully",
		product_defined_successfully: "Product Defined Successfully",
		image_updated_successfully: "Image Updated Successfully",
		image_deleted_successfully: "Image Deleted Successfully",
		controller_updated_successfully: "Controller Updated Successfully",
		account_backup_sent_successfully_to: "Account backup sent successfully to",
		payment_type_status_updated_successfully: "Payment type status updated successfully",
		trn_number_should_be_15_characters: "TRN number should be 15 characters",
		everything: "Everything",
		discount_program_edited_successfully: "Discount program edited successfully",
		discount_program_already_exist: "Discount program already exist",
		discount_program_added_successfully: "Discount program added successfully",
		discount_program_status_updated_successfully: "Discount program status updated successfully",
		loyality_program_edited_successfully: "Loyality program edited successfully",
		loyality_program_status_updated_successfully: "Loyality program status updated successfully",
		invoice_setting_controller_edited_successfully: "Invoice setting controller edited successfully",
		logo_edited_successfully: "Logo Edited Successfully",
		logo_deleted_successfully: "Logo Deleted Successfully",
		account_not_exist: "Account not exist",
		invoice_setting_header_and_footer_edited_successfully: "Invoice setting header and footer edited successfully",
		online_setting_disabled_successfully: "Online setting disabled successfully",
		online_setting_activated_successfully: "Online setting activated successfully",
		price_list_edited_successfully: "Price list edited successfully",
		online_booking_edited_successfully: "Online booking edited successfully",
		social_media_edited_successfully: "Social media edited successfully",
		complains_edited_successfully: "Complains Edited Successfully",
		home_services_edited_successfully: "Home services edited successfully",
		style_edited_successfully: "Style Edited Successfully",
		background_image_edited_successfully: "Background image edited successfully",
		background_image_deleted_successfully: "Background image deleted successfully",
		online_setting_not_exist: "Online setting not exist",
		notification_setting_edited_successfully: "Notification setting edited successfully",
		body: "Body",
		some_data_not_allowed: "Some data not allowed",
		edit_appointment_sms: "Edit Appointment SMS",
		edit_offers_whatsapp: "Edit Offers Whatsapp",
		start_date: "Start Date",
		end_date: "End Date",
		url: "URL",
		appointment_cancelled_successfully: "Appointment Cancelled Successfully",
		appointments_cancelled_successfully: "Appointments Cancelled Successfully",
		appointment_attended_successfully: "Appointment Attended Successfully",
		cancel_appointments: "Cancel Appointments",
		are_you_sure_you_want_to_cancel_the_appointments: "Are you sure you want to cancel the appointments?",
		suggest_development_sent_successfully: "Suggest development sent successfully",
		current_password_is_incorrect: "Current password is incorrect",
		password_changed_successfully: "Password Changed Successfully",
		sorry_there_is_an_error_happened: "Sorry there is an error happened",
		send_to_buyer: "Send To Buyer",
		send_to_beneficiary: "Send To Beneficiary",
		gift_card_logo: "Gift Card Logo",
		beneficiary: "Benificiary",
		this_payment_was_already_cancelled: "This payment was already cancelled",
		only_deposit_payments_can_be_cancelled: "Only deposit payments can be cancelled",
		data_was_not_updated_successfully: "Data was not updated successfully",
		payment_cancelled_successfully: "Payment cancelled successfully",
		authorization_code_is_required_when_the_payment_is_credit_card: "Authorization Code is required when there is credit card payment",
		customer_does_not_have_enough_balance: "Customer does not have enough balance",
		payment_method_not_exist: "Payment method not exist",
		payment_method_not_changed: "Payment method not changed",
		payment_not_exist: "Payment not exist",
		service_added_sucessfully: "Service Added Successfully",
		item: "Item",
		all_invoice: "All Invoice",
		part_from_invoice: "Part From Invoice",
		free: "Free",
		daily_reports_sent_successfully: "Daily reports sent successfully",
		complain_send: "Send Complain",
		seems_there_is_nothing_here: "Seems there is nothing here",
		return_home: "Return Home",
		backup_reports: "Backup Reports",
		tiktok: "TikTok",
		tiktok_should_start_with: "TikTok should start with",
		employee_email_already_exist: "Employee email already exist",
		paid_voucher: "Paid Voucher",
		loyality_voucher: "Loyality Voucher",
		free_voucher: "Free Voucher",
		notifications: "Notifications",
		updates: "Updates",
		issued_gifts_cards: "Issued Gifts Cards",
		is_not_arabic_text: "is not arabic text",
		is_not_english_text: "is not english text",
		password_reset_link_sent_successfully_to_the_email: "Password reset link sent successfully to the email",
		set_new_password: "Set New Password",
		enter_you_new_password: "Enter your new password",
		confirm_password: "Confirm Password",
		submit_send: "Submit",
		password_set_successfully: "Password set successfully",
		prices_are_vat_inclusinve: "Prices are VAT inclusive",
		appointment_rescheduled: "Appointment Rescheduled",
		copy_url_for_share: "Copy URL for share",
		copied_to_clipboard: "Copied to clipboard",
		trn_number_for_ksa_should_start_with_three_and_end_with_three: "TRN number for ksa should start with 3 and end with 3",
		alias_name_is_incorrect: "Alias name is incorrect",
		service_not_exist: "Service not exist",
		please_select_booking_date: "Please select booking date",
		book_today: "Book Today",
		do_you_want_to_add_another_service: "Do you want to add another service?",
		customer_information: "Customer Information",
		services_are_incorrect: "Services are incorrect",
		employees_are_incorrect: "Employees are incorrect",
		appointment_booked_successfully: "Appointment Booked Successfully",
		add_more: "Add More",
		review_booking: "Review Booking",
		total_services: "Total Services",
		confirm_booking: "Confirm Booking",
		light_primary_color: "Light primary color",
		there_is_no_available_times_for_the_selected_date: "There is no available times for the selected date",
		please_select_another_date: "Please select another date",
		reset: "Reset",
		incorrect_data: "Incorrect Data",
		reset_color: "Reset Color",
		are_you_sure_you_want_to_reset_the_color: "Are you sure you want to reset the color?",
		your_appointment_has_been_booked_successfully: "Your appointment has been booked successfully",
		booking_id: "Booking ID",
		booking_details: "Booking Details",
		please_select_date: "Please Select Date",
		wizard_activate_vat: "Activate VAT",
		review: "Review",
		change_booking_date: "Change Booking Date",
		this_option_will_delete_all_selected_services_from_basket: "This option will delete all selected services from basket",
		remove_last_service: "Remove Last Service",
		this_option_will_return_you_to_home_page: "This option will return you to home page",
		mobile_already_exist: "Mobile already exist",
		employee_mobile_already_exist: "Employee mobile already exist",
		email_already_exist: "Email already exist",
		email_is_not_valid: "Email is not correct",
		edit_customer_email: "Edit Customer Email",
		customer_email_already_exist: "Customer email already exist",
		customer_email_edited_successfully: "Customer email edited successfully",
		customer_mobile_already_exist: "Customer mobile already exist",
		current_quantity: "Current Quantity",
		powered_by: "Powered By",
		are_you_sure_you_want_to_cancel_the_payment_this_will_change_your_accounting_statements:
			"Are you sure you want to cancel the payment? this will change your accounting statements",
		cancel_payment: "Cancel Payment",
		daily_reports_sent_successfully_to: "Daily reports sent successfully to",
		deposit_return: "Deposit Return",
		to_activate_this_option_paid_amount_should_be_less_than_total_sales: "To activate this option, paid amount should be less than total sales",
		to_activate_this_option_paid_amount_should_be_less_than_total_sales_and_payment_method_is_selected_for_all_payments:
			"To activate this option, paid amount should be less than total sales and payment method is selected for all payments",
		f11_for_full_mode: "F11 for full mode",
		select_date: "Select Date",
		product_request_sent_successfully: "Product request sent successfully",
		product: "Product",
		invoice_not_exist: "Invoice not exist",
		invoice_has_vat_and_can_not_be_cancelled: "Invoice has VAT and can't be cancelled",
		can_not_cancel_invoice_contains_products: "Can't cancel invoice contains products",
		can_not_cancel_sell_gift_card_invoice: "Can't cancel sell gift card invoice",
		invoice_cancelled_successfully: "Invoice cancelled successfully",
		cancel_invoice: "Cancel Invoice",
		are_you_sure_you_want_to_cancel_the_invoice: "Are you sure you want to cancel the invoice?",
		can_not_use_this_gift_card_since_it_is_for_another_customer: "Can't use this gift card since it's for another customer",
		you_dont_have_permission_to_use_this_screen_please_contact_system_admin:
			"You don't have permission to use this screen, please contact system admin",
		percentage_should_be_zero_when_status_is_disabled: "Percentage should be zero when status is disabled",
		start: "Start",
		all: "All",
		residancy_exipry: "Residancy Expiry",
		service_english_name_is_already_exist_in_active_or_disabled_services: "English service name is already exist in active or disabled services",
		service_arabic_name_is_already_exist_in_active_or_disabled_services: "Arabic service name is already exist in active or disabled services",
		group_english_name_is_already_exist_in_active_or_disabled_services: "English group name is already exist in active or disabled groups",
		group_arabic_name_is_already_exist_in_active_or_disabled_services: "Arabic group name is already exist in active or disabled groups",
		english_name_is_already_exist: "English name is already exist",
		arabic_name_is_already_exist: "Arabic name is already exist",
		change_branch: "Change Branch",
		remember_password: "Remeber Password",
		the_alias_is_not_correct_or_account_is_disabled: "The alias is not correct or account is disabled",
		offer_type: "Offer Type",
		fixed: "Fixed",
		variable: "Variable",
		max_number_of_services_to_use: "Max number of services to use",
		max_number_of_services_to_use_should_be_less_than_count_of_services: "Max number of services to use should be less than count of services",
		max_number_of_services_to_use_is_required: "Max number of services to use is required",
		some_offer_services_are_disabled: "Some offer services are disabled",
		incorrect_parameters_for_offer: "Incorrect parameters for offer",
		offer_not_exist: "Offer not exist",
		offer_is_disabled: "Offer is disabled",
		offer_expired: "Offer expired",
		fixed_services: "Fixed services",
		selection_from_customer: "Selection from customer",
		owner_name: "Owner Name",
		service_date_and_time: "Service Date & Time",
		this_product_is_already_defined: "This product is already defined",
		this_product_is_already_exist: "This product is already exist",
		there_is_already_a_request_for_this_product: "There is already a request for this product",
		already_defined: "Already defined",
		due_date: "Due Date",
		bill_from: "Bill From",
		expense_type: "Expense Type",
		bill_to: "Bill To",
		past: "Past",
		system_updates: "System Updates",
		offer_price_with_vat_without_vat: "Offer Price (With / Without VAT)",
		sell_price_without_offer: "Sell Price Without Offer",
		some_appointments_to_add_are_at_the_same_time: "Some appointments to add are at the same time",
		appointment_overbooking: "Appointment Overbooking",
		activate_product: "Activate Product",
		please_activate_some_products_for_sell_or_for_internal_use: "Please activate some products for sell or for internal use",
		employee_report: "Employee Report",
		employee_commission_percentage: "Employee Commission Percentage",
		use_package_service: "Use package service",
		reminder_sent: "Reminder Sent",
		refresh_the_page: "Refresh the page",
		full_pay: "Full Payment",
		partial_pay: "Partial Payment",
		remaining_amount: "Remaining Amount",
		this_product_is_already_exist_with_different_name: "This product is already exist with different name",
		old_sales: "Old Sales",
		appointment_not_exist_or_cancelled: "Appointment not exist or cancelled",
		this_product_is_not_exist: "This product is not exist",
		group_exist_in_groups_to_activate_you_can_activate_it_from_there: "Group exist in groups to activate, you can activate it from there",
		group_already_exist: "Group already exist",
		group_not_exist: "Group not exist",
		service_exist_in_services_to_activate_you_can_activate_it_from_there: "Service exist in services to activate. you can activate it from there",
		service_already_exist: "Service already exist",
		your_request_has_been_submitted_successfully_suggested_product_will_be_approved_within_24_hours:
			"Your request has been submitted successfully, suggested product will be approved within 24 hours",
		allow_employee_overbooking: "Allow Employee Overbooking",
		remember: "Remember",
		print_services: "Print Services",
		total_products: "Total Products",
		services_total: "Services Total",
		products_total: "Products Total",
		employee_profile: "Employee Profile",
		invoice_hash: "Invoice #",
		supplier_invoice_date: "Supplier Invoice Date",
		edit_expense: "Edit Expense",
		expense_edited_successfully: "Expense Edited Successfully",
		cashier_summary: "Cashier Summary",
		authorization_code_is: "Authorization Code is",
		are_you_sure_you_want_to_add_the_quantity: "Are you sure you want to add quantity?",
		notify_management: "Notify Management",
		notifications_sent_to_management_successfully: "Notifications sent to management successfully",
		change_order: "Change Order",
		please_add_the_services_that_the_employee_is_fluent_in_from_services_page:
			"Please add the services that the employee are fluent in from services page",
		new_block_times_conflict_with_existing_block_times: "New block times conflict with previous block times",
		new_block_times_conflict_with_existing_appointments: "New block times conflict with existing appointments",
		there_is_a_product_with_this_barcode_is_already_defined: "There is a product with this barcode is already defined",
		there_is_a_product_with_this_barcode_is_already_defined_you_need_to_activate_it:
			"There is a product with this barcode is already defined, you need to activate it",
		services_data_are_incorrect: "Services data are incorrect",
		employees_data_are_incorrect: "Employees data are incorrect",
		cancel_invoice_or_create_credit_note: "Cancel invoice / Create credit note",
		edit_payment_method: "Edit payment method",
		cancel_advanced_payment: "Cancel advanced payment",
		credit: "Credit",
		add_penalty: "Add Penalty",
		penalty_added_successfully: "Penalty Added Successfully",
		customer_no_show: "Customer No Show",
		new_penalty: "New Penalty",
		penalty: "Penalty",
		account_balance_is_not_enough: "Account balance is not enough",
		penalties_reports: "Penalties Reports",
		customers_penalties_report: "Customers Penalties Report",
		customers_penalties: "Customers Penalties",
		customers_offers_report: "Customers Offers Report",
		remaining_services: "Remaining Services",
		offer: "Offer",
		advanced_credit: "Advanced Credit",
		confirm_appointment: "Confirm Appointment",
		other_reasons: "Other Reasons",
		debit_note: "Debit Note",
		unused_customers_offers_report: "Unused Customers Offers Report",
		gift_card_description: "Gift Card Description",
		payments_total_is_not_the_same_as_gift_card_value: "Payments total is not the same as gift card value",
		training_account: "Training Account",
		appointment_reminder: "Appointment Reminder",
		reschedule_reminder: "Reschedule Reminder",
		rate_us: "Rate us",
		customer_care: "Customer Care",
		appointments_reminder: "Appointments Reminder",
		no_show_reschedule: "No show reschedule",
		edit_reschedule_appointments_message: "Edit Reschedule Appointments Message",
		no_expiry: "No Expiry",
		please_spend_the_current_quantity_and_disable_the_product_later: "Please spend the current quantity and disable the product later",
		export_to_excel_and_print: "Export to excel and print",
		customer_account_balance_reminder: "Customer account balance reminder",
		edit_customer_account_balance_reminder_sms: "Edit customer account balance reminder sms",
		customer_account_balance_reminder_sent_successfully: "Customer account balance reminder sent successfully",
		account_balance: "Account Balance",
		rate_us_for_issued_invoices: "Rate us for issued invoices",
		rate_us_for_services_from_offers: "Rate us for services from offers",
		email_booking_reference: "Email (Optional)",
		expense: "Expense",
		supplier_not_exist: "Supplier not exist",
		expense_type_not_exist: "Expense type not exist",
		show_invoice: "Show Invoice",
		submit_survey: "Submit Survey",
		kindly_rate_our_services: "Kindly rate our services",
		rating: "Rating",
		invoice_rated_successfully: "Invoice Rated Successfully",
		invoice_already_rated: "Invoice Already Rated",
		some_items_not_exist: "Some items not exist",
		no_internet_connection: "No internet connection",
		please_check_and_try_again: "Please check and try again",
		rating_from_5: "ٌRating (From 5)",
		send_survey: "Send Survey",
		send_invoice: "Send Invoice",
		survey_has_been_submitted_successfully: "Survey has been submitted successfully",
		new_customers: "New Customers",
		another_session_is_already_exist_for_this_user_do_you_want_to_logout_from_it:
			"Another session is already exist for this user, do you want to logout from it?",
		uploaded_file_must_be_one_of_these_extensions: "Uploaded file must be one of these extensions",
		percentage_of_each_employee: "Percentage of each employee",
		employees_performance: "Employees Performance",
		best_services: "Best Services",
		send_invoice_in_email_to_owner: "Send invoice in email to owner",
		the_session_has_been_expired_or_another_person_logged_in_with_the_same_user_please_login_again: "The session has been expired, please login again",
		another_person_logged_in_with_the_same_user: "Another person logged in with the same user",
		the_session_has_been_expired_please_login_again: "The session has been expired, please login again",
		you_are_not_logged_in_please_login_first: "You are not logged in, please login first",
		account_name: "Account Name",
		total_sales_including_vat: "Total Sales Including VAT",
		vat_summary: "VAT Summary",
		net_income: "Net Income",
		general_accounting_report: "General Accounting Report",
		vat_report: "VAT Report",
		vat_report_collected_vat: "Collected VAT",
		paid_vat_expenses: "Paid VAT (Expenses)",
		vat_paid_to_goverment: "VAT Paid to Goverment",
		monthly_income: "Monthly Income",
		most_active_times: "Most Active Times",
		length_should_be_minimum: "Length should be minimum",
		last_year: "Last Year",
		max: "Max",
		last_three_months: "Last 3 Months",
		reminder_sent_successfully: "Reminder Sent Successfully",
		expense_with_same_supplier_invoice_number_and_date_already_exist_are_you_sure_that_you_want_to_continue:
			"Expense with same supplier invoice number and date already exist, are you sure that you want to continue?",
		all_services: "All Services",
		you_can_choose_services: "You can choose {numberOfServices} services",
		add_item: "Add Item",
		add_custom_business_hours: "Add Custom Business Hours",
		edit_custom_business_hours: "Edit Custom Business Hours",
		custom_business_hours: "Custom Business Hours",
		custom_working_hours_warning: "** Times will be applied on appointment table",
		this_date_already_has_record_you_can_edit_it: "This date already has record, you can edit it",
		custom_working_hours_added_successfully: "Custom working hours added successfully",
		custom_working_hours_edited_successfully: "Custom working hours edited successfully",
		general_business_hours: "General Business Hours",
		orders: "Orders",
		on_hold_report: "On Hold Report",
		order_id: "Order ID",
		cancel_order: "Cancel Order",
		are_you_sure_you_want_to_cancel_the_order: "Are you sure you want to cancel the order?",
		order_cancelled_successfully: "Order Cancelled Successfully",
		employee_performance: "Employee Performance",
		daily_income: "Daily Income",
		show_on_online: "Show On Online",
		hide_from_online: "Hide From Online",
		close: "Close",
		services_without_the: "Services",
		image_should_be_square_width_equal_height: "Image should be square (width = height)",
		image: "Image",
		show_on_online_updated_successfully: "Show on online updated successfully",
		change_image: "Change Image",
		edit_offer: "Edit Offer",
		send_automatic_rating_from_melcha: "Send automatic rating from Melcha",
		whatsapp_setting_edited_successfully: "Whatsapp Setting Edited Successfully",
		whatsapp_setting: "Whatsapp Setting",
		edit_whatsapp_setting: "Edit Whatsapp Setting",
		whatsapp_bot: "Whatsapp Bot",
		whatsapp_bot_setting: "Whatsapp Bot Setting",
		disable_employee_will: "Disable employee will:",
		remove_employee_from_billing_screen: "Remove employee from billing screen",
		remove_employee_from_appointments_screen: "Remove employee from appointments screen",
		remove_employee_from_assigned_services: "Remove employee from assigned services",
		disable_employee_access: "Disable employee access",
		activate_whatsapp_bot: "Activate Whatsapp bot",
		disconnected: "Disconnected",
		connected: "Connected",
		connect: "Connect",
		connect_now: "Connect Now",
		please_contact_sales: "Please Contact Sales",
		whatsapp_already_connected: "Whatsapp Already Connected",
		please_activate_two_step_verification_before_connect_to_whatsapp: "Please activate two step verification before connect to Whatsapp",
		in_case_the_number_is_new_please_do_not_send_many_messages_to_prevent_blocking_the_number:
			"In case the number is new, please do not send many messages to prevent blocking the number",
		connect_to_whatsapp: "Connect to Whatsapp",
		you_can_not_use_offers_in_events_and_national_holidays: "You can't use offers in events and national holidays",
		access_inside: "Access",
		failed_to_get_the_qr: "Failed to get the QR",
		failed_to_send_the_message: "Failed to send the message",
		whatsapp_connected_successfully: "Whatsapp Connected Successfully",
		whatsapp_not_connected_successfully: "Failed to connect to Whatsapp",
		whatsapp_message_sent_successfully: "Whatsapp Message Sent Successfully",
		whatsapp_is_not_connected: "Whatsapp is not connected",
		you_do_not_have_valid_whatsapp_package_please_contact_sales: "You do not have valid Whatsapp package, please contact sales",
		sent_updated_successfully: "Sent Updated Successfully",
		please_connect_to_whatsapp_from_notifications_setting_screen: "Please connect to Whatsapp from notifications setting screen",
		send_message_in_arabic: "Send Message in Arabic",
		send_message_in_english: "Send Message in English",
		one_of_the_messages_english_or_arabic_should_be_activated: "One of the messages (english or arabic) should be activated",
		is_not_arabic_or_english_text: "is not arabic or english text",
		should_be_either_arabic_or_english_text_or_numbers: "should be either (arabic or english) letters or numbers",
		your_package_is_paused_please_activate_it_from_notification_setting_screen:
			"Your package is paused, please activate it from notification setting screen",
		resume_package: "Resume Package",
		are_you_sure_you_want_to_resume_the_package: "Are you sure you want to resume the package?",
		package_resumed_successfully: "Package resumed successfully",
		pause: "Pause",
		resume: "Resume",
		package_paused_successfully: "Pacakge paused successfully",
		pause_package: "Pause Package",
		are_you_sure_you_want_to_pause_the_package: "Are you sure you want to pause the package?",
		offered_on_a_monthly_fee_by_third_party_companies_not_affiliated_with_ultimate_technologies:
			"Offered on a monthly fee by third party companies not affiliated with Ultimate Technologies",
		your_package_has_been_expired_please_contact_sales: "Your package has been expired, please contact sales",
		day_without_the: "Day",
		created_by: "Created By",
		please_try_again_later: "Please try again later",
		payments_summary: "Payments Summary",
		paused: "Paused",
		send_issued_invoice_to_customer: "Send issued invoice to customer",
		send_employees_rating_from_melcha: "Send employees rating from Melcha",
		send_appointment_confirmation_to_customer: "Send appointment confirmation to customer",
		send_appointment_confirmation_to_customer_on_booking_system_or_online: "Send appointment confirmation to customer on booking (system or online)",
		send_online_appointment_confirmation_to_customer: "Send online appointment confirmation to customer",
		send_system_appointment_confirmation_to_customer: "Send system appointment confirmation to customer",
		send_appointment_confirmation_for_bookings_from_online: "Send appointment confirmation for bookings from online",
		send_appointment_confirmation_for_bookings_from_system: "Send appointment confirmation for bookings from system",
		whatsapp_bot_setting_edited_successfully: "Whatsapp Bot setting edited successfully",
		please_enter_a_number_which_has_whatsapp_service_activated_on_it: "please enter a number which has Whatsapp service activated on it",
		include_employees_assessment_link: "Include employees assessment link",
		this_option_is_not_allowed_when_automatic_whatsapp_is_connected: "This option is not allowed when automatic Whatsapp is connected",
		customer_not_exist: "Customer not exist",
		user_has_no_rights_to_the_system: "User has no rights to the system",
		send_appointment_confirmation: "Send Appointment Confirmation",
		menu_qr: "Menu QR",
		send_automatic_online_booking_confirmation_from_melcha: "Send automatic online booking confirmation from Melcha",
		this_option_is_not_allowed_when_automatic_whatsapp_is_connected_or_online_setting_is_disabled:
			"This option is not allowed when automatic Whatsapp is connected or online setting is diasabled",
		it_is_not_allowed_to_change_alias_name_after_selection: "* It's not allowed to change alias name after selection",
		download_graphics_for_reception: "Download graphics for reception",
		basic: "Basic",
		advanced_package: "Advanced",
		premium: "Premium",
		subscription_expired_at: "Subscription expired at",
		expired_at: "Expired at",
		renew_subscription: "Renew subscription",
		allow_sell_products: "Allow Sell Products",
		this_number_does_not_have_active_whatsapp: "This number does not have active Whatsapp",
		this_number_have_active_whatsapp: "This number have active whatsapp",
		activate_customer: "Activate Customer",
		are_you_sure_you_want_to_activate_the_customer: "Are you sure you want to activate the customer?",
		customer_activated_successfully: "Customer activated successfully",
		not_allowed: "Not Allowed",
		check_whatsapp_number_in_process: "Check Whatsapp number in process",
		whatsapp_number_checked_successfully: "Whatsapp number checked successfully",
		verification_code: "Verification Code",
		please_enter_the_code_you_received_on_your_whatsapp: "Please enter the code you received on your Whatsapp",
		resend_code: "Resend code?",
		the_code_you_entered_is_incorrect: "The code you entered is incorrect",
		customer_is_disabled_please_activate_him_first: "Customer is disabled, please activate him first",
		you_can_not_request_code_in_less_than_1_minute_please_try_again: "You can not request code in less than 1 minutes, please try again",
		added_at: "Added at:",
		ads_campaigns: "Ads Campaigns",
		campaign_period: "Campaign Period",
		customer_type: "Customer Type",
		campaign_name: "Campaign Name",
		the_code_has_been_expired_we_resend_another_one_to_you: "The code has been expired, we resend another one to you",
		send_otp_for_new_customers: "Send OTP for new customers",
		server_disconnected: "Server Disconnected",
		server_is_disconnected_please_try_again_later: "Server is disconnected, please try again later",
		send_otp_for_new_customers_from_melcha: "Send OTP for new customers from Melcha",
		print_daily_activities_for_employees: "Print daily activities for employees",
		employee_daily_activities: "Employee daily activities",
		campaign_start: "Campaign Start",
		campaign_end: "Campaign End",
		customers_count_total: "Customers Count Total",
		add_campaign: "Add Campaign",
		done_services: "Done Services",
		starts_with_letter: "Starts With Letter",
		operand: "operand",
		less_than_or_equal: "Less than or equal",
		less_than: "Less than",
		equal: "Equal",
		greater_than: "Greater than",
		greater_than_or_equal: "Greater than or equal",
		starts_with: "Starts With",
		contains: "Contains",
		customers_count_within_campaign: "Customers count within campaign",
		campaign_content: "Campaign Content",
		customers_count_within_campaign_should_be_more_than_zero: "Customers count within campaign should be more than 0",
		customers_total_count: "Customers Total Count",
		success: "Success",
		failed: "Failed",
		sending: "Sending",
		campaign_not_exist_or_not_allowed_to_edit: "Campaign not exist or not allowed to edit",
		temporary_save: "Temporary Save",
		temporary_saved: "Temporary Saved",
		can_not_change_the_status_of_this_campaign: "Can't change the status of this campaign",
		status_changed_successfully: "Status Changed Successfully",
		start_sending: "Start Sending",
		disable_campaign: "Disable Campaign",
		when_you_disable_campaign_you_can_not_activate_it_again: "When you disable campaign you can't activate it again",
		report: "Report",
		ads_campaign_report: "Ads Campaign Report",
		drop_file_here: "Drop file here",
		campaign_ready: "Campaign Ready",
		you_can_not_edit_customer_list_after_save: "- You can't edit customer list after save",
		campaign_added_successfull: "Campaign added successfully",
		customer_selection: "Customer Selection",
		sent_date_and_time: "Sent date&time",
		campaign_content_edited_successfully: "Campaign content edited successfully",
		google_review: "Google Review",
		product_have_barcode: "Product have barcode",
		product_does_not_have_barcode: "Product does not have barcode",
		expired_only: "Expired",
		only_images_videos_pdf_files_are_supported: "Only image, video, and PDF file are supported",
		file_size_should_be_less_5_mb: "File size should be less than 5 MB",
		please_complete_form_to_calculate_number_of_campaign_customers: "Please complete form to calculate number of campaign customers",
		start_now: "Start Now",
		max_size_5_mb: "Max size 5 MB",
		pending: "Pending",
		show_customers: "Show Customers",
		hide_customers: "Hide Customers",
		adding_date: "Adding Date",
		resend_faild: "Resend Failed",
		there_are_no_failed_customers_for_this_campaign: "There are no failed customers for this campaign",
		resend_failed_started_successfully: "Resend failed started successfully",
		renew_campaign: "Renew Campaign",
		campaign_renewed_successfully: "Campaign Renewed Successfully",
		send_test: "Send Test",
		campaign_test_send_successfully: "Campaign Test Send Successfully",
		messages_in_the_campaign_are_sent_with_a_time_difference_of_30_to_90_seconds_between_each_client_and_another:
			"- Messages in the campaign are sent with a time difference of 30 - 90 seconds between each client and another",
		the_sending_stops_for_approximately_5_to_15_minutes_after_sending_100_messages:
			"- The sending stops for approximately 5 - 15 minutes after sending 100 messages",
		messages_will_be_sent_between_9am_to_11pm_only: "- Messages will be sent between 9:00 AM to 11:00 PM only",
		customer_serial: "Customer Serial",
		full_name: "Full Name",
		first_name: "First Name",
		allow_whatsapp_contact: "Allow Whatsapp Contact",
		we_serve_only_ladies_customers: "We serve only ladies customers",
		we_serve_only_men_customers: "We serve only men customers",
		we_serve_only_kids_customers: "We serve only kids customers",
		campaign_starts_sending_after_approximately_10_minutes_of_starting: "- Campaign starts sending after approximately 10 minutes of starting",
		melcha_is_not_responsible_of_banning_the_number_from_whatsapp_company_due_to_misuse:
			"- Melcha is not responsible of banning the number from Whatsapp company due to misuse",
		remove_package: "Remove Package",
		are_you_sure_you_want_to_remove_the_package: "Are you sure you want to remove the package",
		please_connect_your_whatsapp_automatic_whatsapp_is_disconnected: "Please connect your Whatsapp, your Whatsapp is disconnected",
		whatsapp_number_has_been_disconnected_successfully_from_melcha: "Whatsapp number has been disconnected successfully from Melcha",
		birth_month: "Birth Month",
		january: "January",
		february: "February",
		march: "March",
		april: "April",
		may: "May",
		june: "June",
		july: "July",
		august: "August",
		september: "September",
		october: "October",
		november: "November",
		december: "December",
		frequent_has_1_invoice_and_last_invoice_is_before_less_than_60_days: "Frequent: has 1 invoice and last invoice is before than less than 60 days",
		missed_has_no_invoices_or_last_invoice_is_before_more_than_60_days: "Missed: has no invoices or last invoice is before more than 60 days",
		new_the_others_are_new_customers: "New: the others are new customers",
		current_commission: "Current Commission",
		employees_commissions_report: "Employees Commissions Report",
		activities_report_summary: "Activities Report Summary",
		activities_summary: "Activities Summary",
		reception_and_stuff_treatment: "Reception and Stuff Treatment",
		cleanliness_and_place_arrangment: "Cleanliness and Place Arrangment",
		prices: "Prices",
		quality_of_provided_services: "Quality of Provided Services",
		complains_report: "Complains Report",
		suggestions_and_complains_report: "Suggestions and Complains Report",
		suggestions_and_complains: "Suggestions and Complains",
		surveys_report: "Surveys Report",
		remove_connection: "Remove Connection",
		are_you_sure_you_want_to_remove_whatsapp_from_your_account: "Are you sure you want to remove Whatsapp from your account?",
		pdf: "PDF",
		edit_name_request: "Edit Name Request",
		edit_name_request_sent_successfully: "Edit name request sent successfully",
		main_header: "Main Header",
		website_setting: "Website Setting",
		secondary_header: "Secondary Header",
		paragraph: "Paragraph",
		main_image: "Main Image",
		home_page: "Home Page",
		images: "Images",
		ads_banner: "Ads Banner",
		show_counters: "Show Counters",
		favicon: "Favicon",
		english_logo: "English Logo",
		arabic_logo: "Arabic Logo",
		home_page_setting_updated_successfully: "Home page setting updated successfully",
		favicon_updated_successfully: "Favicon Updated Successfully",
		image_deleted_succesfully: "Image Deleted Successfully",
		sign_up: "Sign Up",
		english_subject: "English Subject",
		arabic_subject: "Arabic Subject",
		english_description: "English Description",
		arabic_description: "Arabic Description",
		english_question: "English Question",
		arabic_question: "Arabic Question",
		english_answer: "English Answer",
		arabic_answer: "Arabic Answer",
		add_term_and_condition: "Add Term & Condition",
		edit_term_and_condition: "Edit Term & Condition",
		term_and_condition_added_successfully: "Term & Condition added successfully",
		term_and_condition_edited_successfully: "Term & Condition edited successfully",
		add_faq: "Add FAQ",
		edit_faq: "Edit FAQ",
		faq_added_successfully: "FAQ Added Successfully",
		faq_edited_successfully: "FAQ Edited Successfully",
		add_privacy_policy: "Add Privacy Policy",
		edit_privacy_policy: "Edit Privacy Policy",
		privacy_policy_added_successfully: "Privacy Policy Added Successfully",
		privacy_policy_edited_successfully: "Privacy Policy Edited Successfully",
		me: "Me",
		woman: "Woman",
		man: "Man",
		show_name: "Show Name",
		the_activation_code_will_be_sent: "the activation code will sent to it",
		membership_email_will_not_be_visible_to_members: "Membership email (will not be visible to members)",
		analysis: "Analysis",
		requested_customers: "Requested Customers",
		men: "Men Customers",
		women: "Women Customers",
		current_relations: "Current Relations",
		banned_relations: "Banned Relations",
		db_connections: "DB Connections",
		backup_database: "Backup Database",
		whatsapp_connection: "Whatsapp Connection",
		welcome_message: "Welcome Message",
		approval_message: "Approval",
		boqsha_product: "Boqsha Product",
		countries: "Countries",
		currency: "Currency",
		add_country: "Add Country",
		edit_country: "Edit Country",
		country_added_successfully: "Country Added Successfully",
		country_edited_successfully: "Country Edited Successfully",
		cities: "Cities",
		add_city: "Add City",
		edit_city: "Edit City",
		city_added_successfully: "City Added Successfully",
		city_edited_successfully: "City Edited Successfully",
		mobile_number_will_not_be_visible_to_members: "Mobile Number (will not be visible to members)",
		age: "Age",
		weight: "Weight",
		weight_kg: "Weight (KG)",
		religion: "Religion",
		occupation: "Occupation",
		residance_country: "Residance Country",
		marital_status: "Martial Status",
		education_level: "Education Level",
		marriage_type: "Marriage Type",
		traditional_marriage_is_the_official_marriage_known_between_the_parents_legally_documented_and_fulfilling_all_marital_rights:
			"1- Traditional marriage is the official marriage known between the parents, legally documented and fulfilling all marital rights.",
		marriage_under_special_conditions_marriage_under_conditions_agreed_upon_by_both_parties_each_according_to_their_circumstances_such_as_not_committing_to_daily_overnight_stays_or_accepting_the_womans_children_from_a_previous_marriage_to_live_in_the_new_marriage_or_any_conditions_agreed_upon_by_both_parties:
			"2- Marriage under special conditions: Marriage under conditions agreed upon by both parties, each according to their circumstances, such as not committing to daily overnight stays or accepting the woman’s children from a previous marriage to live in the new marriage, or any conditions agreed upon by both parties.",
		accept_pluralism: "Accept Pluralism",
		about_me: "About Me",
		my_desires: "My Desires",
		islam: "Islam",
		christianity: "Christianity",
		single: "Single",
		married: "Married",
		divorced_without_children: "Divorced without children",
		divorced_with_children: "Divorced with children",
		seperated_without_divorce: "Seperated without divorce",
		widowed_without_children: "Widowed without children",
		widowed_with_children: "Widowed with children",
		uneducated: "Uneducated",
		primary: "Primary",
		middle: "Middle",
		secondary: "Secondary",
		bachelor: "Bachelor",
		master: "Master",
		phd: "PHD",
		diploma: "Diploma",
		hijab: "Hijab",
		veiled: "Veiled",
		not_veiled: "Not Veiled",
		traditional_marriage: "Traditional Marriage",
		marriage_under_special_conditions: "Marriage under special conditions",
		sign_up_done_successfully: "Sign up done successfully",
		we_sent_an_email_to_your_email_address: "We sent an email to your email address",
		please_verify_your_email: "please verify your email",
		sign_in_please_verify_your_email: "Please verify your email",
		please_check_the_email_either_in_inbox_or_spam_folder: "Please check the email either in inbox or spam folder",
		invalid_token: "Invalid Token",
		token_already_verified: "Token already verified",
		user_verified_successfully: "User verified successfully",
		token_expired: "Token expired",
		with_hijab: "With Hijab",
		without_hijab: "Without Hijab",
		i_am_woman: "I'm woman",
		i_am_man: "I'm man",
		subscription: "Subscription",
		subscription_updated_successfully: "Subscription updated successfully",
		customers_requests: "Customers Requests",
		verified: "Verified",
		created_at: "Created At",
		ip: "IP",
		device_type: "Device Type",
		browser_type: "Browser Type",
		age_year: "Age year",
		and_are_non_refundable: "and are non refundable",
		subscription_fees_are: "Subscription fees are",
		i_have_read_and_agree_on_terms_and_conditions: "I have read and agree on terms and conditions",
		sponsor_name: "Sponsor Name",
		sponsor_relation: "Sponsor Relation",
		sponsor_mobile: "Sponsor Mobile",
		pay_and_complete_registration: "Pay and complete registration",
		father: "Father",
		mother: "Mother",
		brother: "Brother",
		sister: "Sister",
		uncle: "Uncle",
		approve: "Approve",
		reject: "Reject",
		customers_editing: "Customers Editing",
		preferred_partners: "Preferred Partners",
		whom_i_liked: "Whom I Liked",
		whom_liked_me: "Whom Liked Me",
		profile_edited_successfully: "Profile Edited Successfully",
		employee_sign_in: "Employee Sign In",
		height_cm: "Height (CM)",
		height: "Height",
		islam_soni: "Islam Soni",
		islam_sheaa: "Islam Sheaa",
		white: "White",
		white_brown: "White Brown",
		brown: "Brown",
		bronze: "Bronze",
		light_black: "Light Black",
		black: "Black",
		skin_color: "Skin Color",
		medical_status: "Medical Status",
		good_condition: "Good Condition",
		have_a_chronic_disease: "Have a chronic disease",
		medical_disabled: "Disabled",
		hijab_type: "Hijab Type",
		sponsor: "Sponsor",
		student: "Student",
		goverment_employee: "Goverment Employee",
		private_sector_employee: "Private Sector Employee",
		retired: "Retired",
		apple_touch_icon: "Apple Touch Icon",
		apple_touch_icon_updated_successfully: "Apple touch icon updated successfully",
		mutual_admiration: "Mutual Admiration",
		approval_after_edit_message: "Approval After Edit",
		like_message: "Like",
		database_backup_sent_successfully_to: "Database backup sent successfully to",
		men_who_like_women: "Men who like women",
		women_who_like_men: "Women who like men",
		email_setting: "Email Setting",
		subscription_and_verify_email_message: "Subscript & Verify Email",
		whatsapp_resumed_successfully: "Whatsapp resumed successfully",
		whatsapp_paused_successfully: "Whatsapp paused successfully",
		whatsapp_connection_removed_successfully: "Whatsapp connection removed successfully",
		resume_whatsapp: "Resume Whatsapp",
		are_you_sure_you_want_to_resume_whatsapp: "Are you sure you want to resume whatsapp?",
		pause_whatsapp: "Pause Whatsapp",
		are_you_sure_you_want_to_pause_whatsapp: "Are you sure you want to pause whatsapp?",
		remove_whatsapp_connection: "Remove Whatsapp Connection",
		are_you_sure_you_want_to_remove_whatsapp_connection: "Are you sure you want to remove whatsapp connection?",
		mail_mailer: "Mail Mailer",
		mail_host: "Mail Host",
		mail_port: "Mail Port",
		mail_encryption: "Mail Encryption",
		mail_email: "Mail Email",
		mail_email_password: "Mail Email Password",
		mail_from: "Mail From",
		new_sign_up_email: "New sign up email",
		new_edit_request_email: "New edit request email",
		current_relation_email: "Current relation email",
		lost_whatsapp_connection_email: "Lost whatsapp connection email",
		email_setting_edited_successfully: "Email setting edited successfully",
		email_subject: "Email Subject",
		sponsor_mobile_should_not_be_the_same_as_mobile: "Sponsor mobile should not be the same as mobile",
		waiting_email_verification: "Waiting email verification",
		delete_my_account: "Delete My Account",
		main_data: "Main Data",
		subscription_fees: "Subscription Price",
		payment_methods: "Payment Methods",
		main_data_edited_successfully: "Main Data Edited Successfully",
		extra_information_edited_successfully: "Extra Information Edited Successfully",
		about_me_edited_successfully: "About Me Edited Successfully",
		my_desires_edited_successfully: "My Desires Edited Successfully",
		resend_email: "Resend Email",
		verify: "Verify",
		rejected_requests: "Rejected Requests",
		change_email: "Change Email",
		email_changed_successfully: "Email Changed Successfully",
		reject_message: "Reject Message",
		customers_women: "Customers (Women)",
		customers_men: "Customer (Men)",
		waiting_user_to_change: "Waiting user to change",
		user_changed_profile: "User changed profile",
		customer_request_not_exist: "Customer Request not exist",
		delete_customer: "Delete Customer",
		are_you_sure_you_want_to_delete_the_customer: "Are you sure you want to delete the customer?",
		customer_deleted_successfully: "Customer Deleted Successfully",
		my_account_deleted_successfully: "My account Deleted Successfully",
		are_you_sure_you_want_to_delete_the_account: "Are you sure you want to delete the account?",
		owner_whatsapp: "Owner Whatsapp",
		contract: "Contract",
		we_will_delete_all_your_information_and_you_can_not_return_your_account_after_that:
			"We will delete all your information and you can't return your account after that",
		payment_gateway_setting: "Payment Gateway Setting",
		email_logo: "Email Logo",
		email_is_disabled: "Email is disabled",
		you_need_to_sign_up_first: "You need to sign up first",
		payment_gateway_setting_edited_successfully: "Payment Gateway Setting edited successfully",
		hosting: "Hosting",
		token: "Token",
		no_payment_gateway: "No Payment Gateway",
		my_fatoorah: "My Fatoorah",
		ready_profile: "Ready Profile",
		under_review: "Under Review",
		new_under_review: "New, Under Review",
		all_ages: "All Ages",
		all_nationalities: "All Nationalities",
		all_countries: "All Countries",
		all_cities: "All Cities",
		all_colors: "All Colors",
		all_weights: "All Weights",
		all_heights: "All Heights",
		all_medical_statuses: "All Medical Statuses",
		all_religions: "All Religions",
		all_occupations: "All Occupations",
		all_martial_statuses: "All Marital Statuses",
		all_education_levels: "All Education Levels",
		all_hijab_types: "All Hijab Types",
		not_specified: "Not Specified",
		should_be_between_21_and_60: "should be between 21 and 60",
		men_who_want_a_ligitimate_look: "Men who want a ligitimate look",
		women_who_want_a_ligitimate_look: "Women who want a ligitimate look",
		those_who_agree_on_a_ligitimacy_look: "Those who agree on a ligitimacy look",
		my_requests_for_a_ligitimacy_look: "My requests for a ligitimacy look",
		others_requests_for_a_ligitimacy_look: "Others requests for a ligitimacy look",
		favorite_list: "Favorite List",
		the_customer_has_been_disabled_please_contact_administrator: "The customer has been disabled, please contact administrator",
		legitimacy_look: "Legitmacy Look",
		request_legitimacy_look: "Request Legitimacy Look",
		the_subscriber_has_risen: "The subscripber has risen",
		incomplete_profile: "Incomplete Profile",
		or_waiting_management_approval: "Or waiting management approval",
		pluralism_accepted: "Pluralism Accepted",
		pluralism_rejected: "Pluralism Rejected",
		kg: "KG",
		cm: "CM",
		accept_legitimacy_look: "Accept Legitimacy Look",
		reject_legitimacy_look: "Reject Legitimacy Look",
		research: "Reasearch",
		added_to_favorite_list: "Added to favorite list",
		removed_from_favorite_list: "Removed from favorite list",
		logitimacy_look_has_been_requested_successfully: "Logitimacy look has been requested successfully",
		are_you_sure_you_want_to_request_a_legitimacy_look: "Are you sure you want to request a legitimacy look?",
		will_be_notified_to_respond_to_your_request: "Will be notified to respond to your request",
		will_be_notified_to_respond_to_your_request_and_will_be_moved_to_my_acceptances_on_description:
			'Will be notified to respond to your request and will be moved to "My Acceptances On Description" list',
		i_accept_conditions: "I accept conditions",
		accept_description: "Accept Description",
		accept_other_party_on_description: "Accept other party on description",
		reject_other_party_on_description: "Reject other party on description",
		men_accept_description: "Men Accept Description",
		women_accept_description: "Women Accept Description",
		agreed: "Agreed ",
		years: "Years",
		search_year: "Years",
		i_accept_description: "I accept description",
		are_you_sure_you_accept_description: "Are you sure you accept description",
		description_has_been_accepted_successfully: "Description has been accepted successfully",
		my_acceptances_on_description: "My Acceptances On Description",
		those_are_accepted_my_description: "Those are accepted my description",
		description_accepted: "Description Accepted",
		subscriper_has_been_moved_to_my_acceptances_on_description: 'Subscriper has been moved to "My Acceptances On Description" list',
		cancel_accept_description: "Cancel Accept Description",
		cancel_accept: "Cancel Accept",
		are_you_sure_you_want_to_cancel_accept_description: "Are you sure you want to cancel accept description",
		will_be_notified_and_will_be_moved_to_search: 'Will be notified and will be moved to "search" list',
		subscriper_has_been_moved_to_search: 'Subscriper has moved to "Search" list',
		responses_and_actions: "Responses & Actions",
		this_list_include_subscripers_whom_accepted_your_description: "This list include subscripers whom accepted your description",
		please_accept_or_reject_the_request: "Please accept or reject the request",
		acceptance: "Accept",
		are_you_sure_you_want_to_accept_the_description_of: "Are you sure you want to accept the description of",
		will_be_notified_and_will_be_moved_to_agreed_on_description: 'Will be notified and will be moved to "Agreed On Description" list',
		are_you_sure_you_want_to_reject_the_description_of: "Are you sure you want to reject the description of",
		subscriber_has_been_moved_to_search_list: 'Subscriber has been moved to "Search" list',
		subscriber_has_been_moved_to_favorite_list: 'Subscriber has been moved to "Favorite List" list',
		subscriper_has_been_moved_to_agreed: 'Subscriber has been moved to "Agreed" list',
		waiting_for_management: "Waiting for management",
		agreed_on_description: "Agreed On Description",
		agreed_on_legitimacy_look: "Agreed On Legitimacy Look",
		banned: "Banned",
		married_dashboard: "Married",
		move_to_legitimacy_look: "Move to logitimacy look",
		relation: "Relation",
		moved_to_agreed_on_logitimacy_look: "Moved to agreed on logitimacy look",
		move_to_banned: "Move to banned",
		moved_to_banned: "Moved to banned",
		reject_description: "Reject Description",
		move_to_logitimacy_look: "Move to logitimacy look",
		accept_logitimacy_look: "Accept logitimacy look",
		reject_logitimacy_look: "Reject logitimacy look",
		i_accept_logitimacy_look: "I accept logitimacy look",
		are_you_sure_you_want_to_accept_the_logitimacy_look_with: "Are you sure you want to accept the logitimacy look with",
		are_you_sure_you_want_to_reject_the_logitimacy_look_with: "Are you sure you want to reject the logitimacy look with",
		will_be_notified_and_will_be_moved_to_agreed_on_logitimacy_look: 'Will be notified and will be moved to "Agreed on logitimacy look" list',
		subscriper_has_been_moved_to_agreed_on_logitimacy_look: "Subscriper has been moved to agreed on logitimacy look",
		logitimacy_look_has_been_rejected: "Logitimacy look has been rejected",
		waiting_for_other_subscriber_to_approve: "Waiting for other subscriber to approve",
		logitimacy_look_has_been_agreed_waiting_for_management: "Logitimacy look has been agreed, waiting for management",
		failed_from_our_side: "Failed from our side",
		failed_from_knet: "Failed from KNET",
		knet: "KNET",
		retry_payment: "Retry Payment",
		payment_failed: "Payment Failed",
		payment_id: "Payment ID",
		tran_id: "Tran ID",
		track_id: "Track ID",
		print_a4: "Print A4",
		quantization_process: "Quantization Process",
		plastic_surgery: "Plastic Surgery",
		moved_to_married: "Moved to Married",
		move_to_married: "Move to Married",
		new_sign_up: "New Sign Up",
	},
	ar: {
		dashboard: "الرئيسية",
		appointments: "المواعيد",
		customers: "العملاء",
		employees: "الموظفين",
		employee: "الموظف",
		roles_and_permissions: "صلاحيات الموظفين",
		services: "الخدمات",
		groups: "مجموعات الخدمات",
		original_price: "سعر البيع",
		packages: "الباقات",
		products: "المنتجات",
		stock_management: "ادارة المخزون",
		accounting: "المحاسبة",
		total_sales: "المبيعات",
		expenses: "المصاريف",
		payments: "الدفعات",
		receivable: "ديون العملاء",
		accounting_reports: "تقارير المحاسبة",
		setting: "الاعدادات",
		general_setting: "الاعدادات العامة",
		inventory_reports: "تقارير المخزون",
		management_reports: "التقارير الادارية",
		marketing_tools: "ادوات التسويق",
		invoice_setting: "اعدادت الفاتورة",
		accounting_setting: "اعدادات المحاسبة",
		online_setting: "اعدادات الاونلاين",
		notification_setting: "اعدادات التنبيهات",
		cs_setting: "اعدادات العناية بالعملاء",
		logout: "تسجيل الخروج",
		edit: "تعديل",
		activate: "تفعيل",
		disable: "تعطيل",
		filter: "تخصيص",
		export: "تصدير",
		actions: "الاجراءات",
		name: "الاسم",
		mobile: "الموبايل",
		email: "البريد الالكتروني",
		type: "النوع",
		loyality_points: "نقاط الولاء",
		loyality_program: "برنامج الولاء",
		account_balace: "رصيد الحساب",
		identification_method: "طريقة التعارف",
		notes: "الملاحظات",
		status: "الحالة",
		not_exist_create_new_customer: "غير موجود, اضافة عميل موجود؟",
		walk_in_customer: "عميل بدون رقم",
		general_reports: "التقارير العامة",
		date_and_time: "التاريخ والوقت",
		invoice_number: "رقم الفاتورة",
		subtotal: "المجموع",
		vat: "ضريبة القيمة المضافة",
		total: "المجموع الكلي",
		salesman: "البائع",
		description: "الوصف",
		supplier: "المورد",
		supplierinvoice: "رقم  فاتورة المورد",
		supplier_trn: "الرقم الضريبي للمورد",
		paid_vat: "الضريبة المدفوعة",
		payment_method: "طريقة الدفع",
		payment_date: "تاريخ الدفع",
		reference_number: "الرقم المرجعي",
		paid_amount: "المبلغ المدفوع",
		remaining: "المتبقي",
		credit_note_number: "رقم اشعار دائن",
		returned_invoice_numner: "رقم الفاتورة المرتجعة",
		returned_amount: "المبلغ المرتجع",
		returned_vat: "الضريبة المرتجعة",
		debit_note_number: "رقم اشعار مدين",
		supplier_invoice_number: "رقم فاتورة المورد",
		picture: "الصورة",
		experience: "الخبرات",
		passport_expiry: "انتهاء الجواز",
		residancy_expiry: "انتهاء الاقامة",
		joining_date: "تاريخ الانضمام",
		leaving_date: "تاريخ المغادرة",
		show_in_appointment_table: "اظهار في جدول المواعيد",
		have_access_login: "صلاحيات الدخول للبرنامج",
		order: "الترتيب",
		english_name: "الاسم بالانجليزية",
		arabic_name: "الاسم بالعربية",
		show_to_customer: "اظهار للعميل",
		group: "المجموعة",
		originalprice: "سعر البيع",
		price_include_vat: "سعر البيع شامل الضريبة",
		job_time: "وقت الانجاز (دقيقة)",
		offer_price: "سعر العرض",
		from: "من",
		to: "الى",
		barcode: "الباركود",
		average_cost_price: "متوسط سعر التكلفة",
		warning_if_reach: "ًتنبيه عند كمية مخزون",
		print_barcode: "طباعة الباركود",
		add: "اضافة",
		define: "تعريف منتج",
		appointment_reports: "تقارير المواعيد",
		appointment_report: "تقرير المواعيد",
		inventory_status: "حالة المخزن",
		inventory_movements: "حركة المخزون لفترة",
		internal_use_report: "الاستخدام الداخلي للمخزون",
		inventory_evaluation: "تقييم المخزون",
		booking_source: "طريقة الحجز",
		payment_status: "حالة الدفع",
		service: "الخدمة",
		service_without_the: "خدمة",
		booking_date: "تاريخ الموعد",
		start_time: "الوقت",
		google_map: "خرائط جوجل",
		send_reminder: "تذكير",
		sent: "تم الارسال",
		send: "ارسل",
		marketing_sms: "رسائل التواصل",
		inventory_movements_period: "حركة المخزون (لفترة)",
		internal_use: "الاستخدام الداخلي",
		added_quantity: "الكمية المضافة",
		sold: "مباع",
		lost: "مفقود",
		returned: "مرتجع",
		remaining_quantity: "الكمية المتبقية",
		product_name: "المنتج",
		operation: "العملية",
		quantity: "الكمية",
		service_product: "الخدمة/المنتج",
		activity_report: "تقرير الحركات",
		daily_payment_summary: "ملخص الدفعات اليومي",
		cash: "نقدا",
		credit_card: "بطاقة ائتمانية",
		transfer: "حوالة",
		cheuqe: "شيك بنكي",
		payment_link: "رابط دفع",
		website: "الموقع الالكتروني",
		date: "التاريخ",
		monthly_payment_summary: "ملخص الدفعات الشهري",
		month: "الشهر",
		employees_report: "تقرير الموظفين",
		employee_commission: "عمولة الموظف",
		employee_part_of_sales: "حصة الموظف من المبيعات",
		details: "التفاصيل",
		services_report: "تقرير الخدمات",
		number_of_time_used: "عدد مرات الخدمة",
		most_used_products: "الاكثر استخداما",
		time_of_used: "عدد مرات الاستخدام",
		number_of_selling: "عدد مرات البيع",
		most_selled_products: "الاكثر مبيعا",
		online_daily_visits: "عدد زيارات الاونلاين",
		number_of_visits: "عدد الزيارات",
		coupons_report: "تقرير الكوبونات",
		coupon_id: "رقم الكوبون",
		serial_number: "الرقم التسلسلي",
		value: "القيمة",
		re_print: "اعادة الطباعة",
		complain_report: "تقرير الشكاوى",
		complain_id: "رقم الشكوى",
		customer_discount_report: "تقرير خصومات العملاء",
		discount_percente: "نسبة الخصم",
		discount_value: "قيمة الخصم",
		survey_report: "تقرير الاستبيانات",
		excellent: "ممتاز",
		good: "جيد",
		weak: "ضعيف",
		very_weak: "ضعيف جدا",
		search: "بحث",
		products_management_reports: "تقارير ادارة المخزون",
		customers_relations_reports: "تقارير علاقات العملاء",
		important_reports: "تقارير هامة",
		filter_options: "خيارات التخصيص",
		include_disabled: "اظهار المعطل",
		include_disabled_expired: "إظهار المعطّل / المنتهي",
		show_more: "المزيد",
		show_less: "الافتراضي",
		ultimate_tech_product: "من منتجات التميت للتكنولوجيا",
		melcha: "ملجه",
		signin: "تسجيل الدخول",
		signout: "تسجيل الخروج",
		account_id: "رقم الحساب",
		forget_account_id: "نسيت رقم الحساب",
		remember_me: "تذكرني",
		continue: "متابعة",
		password: "كلمة المرور",
		forget_password: "نسيت كلمة المرور؟",
		login: "تسجيل الدخول",
		website_login: "الدخول",
		sms_marketing: "رسائل التسويق",
		business_start_time: "بداية وقت العمل",
		business_end_time: "نهاية وقت العمل",
		holiday: "عطلة",
		day: "اليوم",
		total_income: "الدخل الكلي",
		acheived_services: "الخدمات المنجزة",
		birth_date: "تاريخ الميلاد",
		nationality: "الجنسية",
		address: "العنوان",
		gender: "الجنس",
		overview: "عام",
		working_hours: "ساعات العمل",
		Activity: "النشاط",
		holidays: "الاجازات",
		salary: "الراتب",
		commission: "العمولات",
		holiday_name: "اسم الاجازة",
		holiday_start_date: "تاريخ بداية الاجازة",
		holiday_end_date: "تاريخ انتهاء الاجازة",
		product_price_search: "البحث عن سعر منتج",
		add_internal_use: "اضافة استخدام داخلي",
		send_daily_reports: "ارسال التقارير اليومية",
		send_backup_reports: "ارسال النسخة الاحتياطية",
		language: "اللغة",
		lock_screen: "اقفال الشاشة",
		training: "التدريب",
		quick_setup: "الاعداد السريع للبرنامج",
		english: "الانجليزية",
		arabic: "العربية",
		employee_details: "تفاصيل الموظف",
		extra_information: "معلومات اضافية",
		rights: "حقوق",
		add_employee: "اضافة موظف",
		save: "حفظ",
		customer_details: "تفاصيل العميل",
		trn: "الرقم الضريبي",
		view_permissions: "مشاهدة الصلاحيات",
		user_has: "المستخدم حاصل على حقوق",
		rights_to_the_system: ",للدخول للنظام ",
		control_access_login: "التحكم بصلاحيات الدخول",
		add_a_customer: "اضافة عميل",
		packages_and_membership: "الباقات والاشتراكات",
		offers_and_membership: "العروض والاشتراكات",
		coupons: "الكوبونات",
		new_invoice: "فاتورة جديدة",
		new_deposit: "اضافة عربون",
		transactions: "العمليات",
		balance_till_the_moment: "الرصيد المتوفر حتى اللحظة",
		this_year: "السنة الحالية",
		add_appointment: "اضافة موعد",
		profit_and_loss_report: "تقرير الربح والخسارة",
		cash_flow_report: "تقرير التدفق النقدي",
		other_accounting_reports: "تقارير محاسبية اخرى",
		debit_notes_report: "تقرير اشعارات مدين",
		credit_notes_report: "تقرير اشعارات دائن",
		credit_and_debit_notes: "اشعارات الدائن والمدين",
		edit_employee_info: "تعديل معلومات موظف",
		customer_invoices: "فواتير العميل",
		saturday: "السبت",
		sunday: "الاحد",
		monday: "الاثنين",
		tuesday: "الثلاثاء",
		wednesday: "الاربعاء",
		thursday: "الخميس",
		friday: "الجمعة",
		edit_employee: "تعديل موظف",
		edit_extra_information: "تعديل البيانات الاضافية",
		expiry_date: "تاريخ انتهاء الصلاحية",
		show_in_billing: "اظهار خلال الفوترة",
		yes: "نعم",
		no: "لا",
		show_in_appointments: "اظهار في المواعيد",
		upload_photo: "تحميل الصورة",
		administrator: "مسؤول",
		total_users_with_roles: "عدد المستخدمين لهذه الصلاحيات:",
		add_a_Role: "اضافة مجموعة صلاحيات",
		role_name: "اسم مجموعة الصلاحيات",
		enter_a_role_name: "ادخل اسم مجموعة الصلاحيات",
		role_permissions: "صلاحيات المجموعة",
		read: "قراءة",
		write: "ادخال",
		create: "انشاء",
		administrator_access: "صلاحيات كاملة",
		select_all: "اختيار الجميع",
		cancel: "الغاء",
		add_new_role: "مجموعة صلاحيات جديدة",
		assign_permissions: "منح الصلاحيات",
		add_roles: "اضافة مجموعة صلاحيات",
		edit_roles: "تعديل صلاحيات لمجموعة",
		stocks_management: "ادارة المخزون",
		add_quantity: "اضافة مخزون",
		adjust_quantity: "تعديل مخزون",
		delete: "مسح",
		show: "عرض",
		adjust: "تنقيص",
		all_features: "جميع الخيارات",
		reports: "التقارير",
		most_used: "الاكثر استخداما",
		most_selled: "الاكثر مبيعا",
		receipt: "الايصال",
		tax: "الضريبة",
		tax_name: "اسم الضريبة",
		percentage: "النسبة%",
		business_hours: "أوقات العمل",
		discount_code: "كود الخصم",
		discount_codes: "كودات الخصم",
		loyality_code: "كود الولاء",
		points_vs_paid_currency: "النقاط / العملة المدفوعة",
		admin_email: "البريد الالكتروني للمدير",
		country: "الدولة",
		city: "المدينة",
		address_in_arabic: "العنوان باللغة العربية",
		address_in_english: "العنوان باللغة الانجليزية",
		trn_number: "الرقم الضريبي",
		import_data: "ادخال بيانات",
		make_sure_to_use_system_format_from_action_button: "الرجاء الالتزام باستخدام ملفات النظام الموجودة في زر الاجراءات",
		upload_now: "تحميل البيانات",
		link_employee_with_service: "ربط الموظف مع الخدمة",
		link_employee_with_product: "ربط الموظف مع المنتج",
		allow_hold_invoice: "السماح بتعليق الفاتورة",
		controller: "التحكم بالنظام",
		download_groups_template: "تحميل نموذج مجموعات الخدمات",
		download_services_template: "تحميل نموذج الخدمات",
		download_products_template: "تحميل نموذج المنتجات",
		edit_business_informations: "تعديل معلومات الشركة",
		invoice_controller: "التحكم بالفاتورة",
		show_employee_in_customer_invoice: "عرض اسم الموظف في فاتورة العميل",
		show_customer_information_in_invoice: "عرض معلومات العميل في الفاتورة ",
		send_email_to_employee_with_done_service: "ارسال بريد الكتروني للموظف في الخدمة المنجزة",
		send_email_to_employee_with_ordered_service: "ارسال بريد الكتروني للموظف بالخدمة المطلوبة",
		activate_winning_box: "تشغيل صندوق الربح",
		invoice_header: "راس الفاتورة",
		invoice_footer: "ذيل الفاتورة",
		add_customer: "اضافة عميل",
		add_more_data: "اضافة بيانات اخرى",
		birthday: "تاريخ الميلاد",
		gifts_cards_reports: "تقارير بطاقات الهدايا",
		redeem_loyality_points: "تحويل نقاط الولاء",
		reminder: "تذكير",
		add_balance_to_customer_through_loyality_points: "اضافة رصيد من خلال تحويل نقاط الولاء,",
		gifts_cards: "بطاقات الهدايا",
		send_email: "ارسال بريد الالكتروني",
		whatsapp: "واتساب",
		use_service: "استخدم الخدمة",
		header_and_footer: "ترويسة وذيل الفاتورة",
		active_gifts_cards: "بطاقات الهدايا الفعالة",
		suppliers: "الموردون",
		expenses_types: "أنواع المصاريف",
		online_controller: "التحكم بخدمات الاونلاين",
		is_working_day: "يوم عمل",
		add_automatically: "إضافة بشكل تلقائي",
		primary_informations: "المعلومات الرئيسية",
		setup_your_account_details: "ادخل تفاصيل حسابك للأعمال",
		activate_vat: "تفعيل ضريبة القيمة",
		activate_vat_in_your_business: "فعل حسابات الضريبة الخاص ",
		employee_info: "معلومات الموظفين",
		add_employees_info: "اضافة معلومات الموظفين",
		set_business_hours: "اضافة ساعات العمل الخاصة ",
		set_how_invoice_looklike: "كيفية عرض الفاتورة للعميل",
		activate_services: "تفعيل الخدمات",
		activate_services_and_set_prices: "تفعيل الخدمات واضافة الاسعار",
		completed: "مكتمل",
		woah_we_are_here: "تم اضافة البيانات بنجاح",
		aaccount_info: "معلومات الحساب",
		if_you_need_to_switch_languages: "في حال رغبت بتغيير اللغة,  ",
		switch_language: "تغيير اللغة",
		upload_business_logo: "تحميل الشعار",
		search_products: "البحث عن منتج",
		search_by_barcode_or_name: "البحث بالباركود او الاسم",
		balance_due: "المبلغ المستحق",
		sell_as_package: "بيع كباقة",
		add_new_payment: "اضافة دفعة جديدة",
		remove_payment: "ازالة الدفعة",
		issue_invoice: "اصدار فاتورة",
		cancel_issue_invoice: "الغاء اصدار الفاتورة",
		number_of_items: "عدد السلع",
		proceed_payment: "متابعة الدفع",
		hold_invoice: "تعليق الفاتورة",
		add_gift_card: "اضافة بطاقة هدايا",
		submit: "اضافة",
		add_free_text: "اضافة نص حر",
		free_text: "نص حر",
		invoice: "الفاتورة",
		remove: "ازالة",
		excel: "ملف اكسل",
		print: "طباعة",
		export_options: "خيارات التصدير",
		disabled: "معطّل",
		allow_dept_in_invoice: "السماح بالدين للعملاء",
		invoice_notes: "ملاحظات الفاتورة",
		forget_accoubt_id: "نسيت رقم الحساب؟",
		ok: "موافق",
		enter_your_email_to_receive_your_account_id: "ادخل البريد الالكتروني الخاص بك",
		back: "رجوع",
		enter_your_email_to_reset_your_password: "أدخل البريد الالكتروني لاستعادة كلمة المرور.",
		coupon_gift_card: "الكوبون / بطاقة هدايا",
		hide_from_customer: "اخفاء امام العميل",
		add_group: "اضافة مجموعة",
		add_service: "اضافة خدمة",
		edit_group: "تعديل مجموعة",
		edit_service: "تعديل خدمة",
		sell_price: "سعر البيع",
		sell_price_including_vat: "سعر البيع شامل الضريبة",
		add_package: "اضافة باقة",
		edit_package: "تعديل باقة",
		initial_quantity: "الكمية المبدئية",
		warning_if_stock_reach: "تحذير عند وصول الكمية",
		define_product: "تعريف منتج",
		edit_product: "تعديل منتج",
		working_day: "يوم عمل",
		new_times_warning: "** سيتم تطبيق الاوقات الجديدة على اوقات الموظفين والمواعيد",
		edit_business_hours: "تعديل ساعات العمل",
		edit_tax: "تعديل الضريبة",
		confirm: "تاكيد",
		editing_tax_will_affect_all_service_and_products: "** تغيير نسبة الضريبة ستؤدي لتغيير اسعار الخدمات و المنتجات",
		this_will_change_all_services_and_products_prices_according_to_new_vat: "سيتم تغيير اسعار الخدمات والمنتجات مع حسابات الضريبة الجديدة",
		code: "الكود",
		category: "المجموعة",
		add_discount_code: "اضافة كود خصم",
		edit_discount_code: "تعديل كود خصم",
		edit_loyality_program: "تعديل برنامج الولاء",
		activate_qr: "تشغيل رمز الاستجابة السريعة",
		edit_invoice_setting: "تعديل اعدادات الفاتورة",
		edit_invoice_header_and_footer: "تعديل راس وذيل الفاتورة",
		header: "الترويسة",
		footer: "الذيل",
		receptionist: "موظف إستقبال",
		management: "إدارة",
		manager: "الادارة",
		hair_services: "خدمات الشعر",
		hair_treatment: "علاجات الشعر",
		hair_color: "الصبغ",
		makeup: "المكياج",
		manicurist: "المانيكير",
		pedicurist: "البديكير",
		morrocan_bath: "الحمام المغربي",
		henna: "الحناء",
		message: "المساج",
		facial: "الوجه",
		wax: "الواكس",
		halawa: "الحلاوة",
		threding: "الخيط",
		eyebrow: "الحواجب",
		lashes: "الرموش",
		general_services: "خدمات عامة",
		not_specific: "غير محدد",
		relatives: "الاقارب",
		relatives_acquaintances: "أقارب أو معارف",
		facebook: "الفيسبوك",
		instagram: "الانستغرام",
		snapchat: "السناب شات",
		google: "جوجل",
		blogger_ads: "المؤثرين",
		coincidence: "صدفة",
		other: "اخرى",
		male: "ذكر",
		female: "انثى",
		business: "عمل",
		profile: "ملف العميل",
		activities: "النشاطات",
		this_month: "هذا الشهر",
		last_month: "الشهر السابق",
		invoice_payments_should_be_equal_to_invoice_total: "مجموع الدفعات يجب أن يساوي قيمة الفاتورة",
		invoice_payments_should_be_less_or_equal_to_invoice_total: "مجموع الدفعات يجب أن يكون أقل من أو يساوي قيمة الفاتورة",
		invoice_payments_is_not_the_same_as_invoice_total: "قيمة الدفعات ليست مساوية لقيمة الفاتورة",
		package_price_should_be_less_than_invoice_total: "قيمة الباقة يجب أن تكون أقل من أو تساوي قيمة الفاتورة",
		select_package_expiry: "إختر تاريخ إنتهاء الصلاحية",
		days: "أيام",
		select_date_manually: "إختر التاريخ يدويا",
		employees_information: "معلومات الموظفين",
		send_whatsapp: "إرسال واتس آب",
		disable_employee: "تعطيل موظف",
		employee_timing: "أوقات الموظف",
		no_show: "لم يحضر",
		pos: "الكاشير",
		today: "اليوم",
		whats_up_today: "نشاطات اليوم",
		booking: "حجز",
		profit_and_loss: "المبيعات و المصاريف",
		profit: "الربح",
		loss: "الخسارة",
		net_vat: "اجمالي ضريبة القيمة",
		collected_vat: "الضريبة المجمعة",
		due_on_goverment: "المسترد من الحكومة",
		due_to_goverment: "المورد الى الحكومة",
		search_by_mobile_or_name: "البحث برقم الموبايل او الاسم...",
		invoice_added_successfully: "تم اصدار الفاتورة بنجاح",
		order_submitted_successfully: "تم الطلب بنجاح",
		price: "السعر",
		my_profile: "الملف الشخصي",
		change_password: "تغيير كلمة المرور",
		custom_range: "فترة مخصصة",
		yesterday: "أمس",
		last_quarter: "آخر ربع",
		advanced: "مقدما",
		bank_status: "حالة البنك",
		create_invoice: "إنشاء فاتورة",
		appointment: "موعد",
		block_time: "وقت حظر",
		cancelled: "ملغي",
		scheduled: "في موعده",
		check_in: "تم الحضور",
		acheived: "مكتملة",
		frequent: "متكرر",
		missed: "مفقود",
		new: "جديد",
		end_time: "وقت الإنتهاء",
		is_required: "مطلوب",
		apply: "تطبيق",
		show_alone: "عرض وحيدا",
		show_all: "عرض الكل",
		am: "صباحا",
		pm: "مساء",
		groups_and_services: "المجموعات والخدمات",
		selected_appointments: "المواعيد المختارة",
		minutes_short: "د",
		by: "بواسطة",
		search_customer: "البحث عن عميل",
		appointment_added_successfully: "تم إضافة الموعد بنجاح",
		check_in_now: "إبدأ الآن",
		summary: "الملخص",
		invoices: "الفواتير",
		no_email: "لا يوجد بريد إلكتروني",
		select: "إختر",
		customer: "العميل",
		system: "النظام",
		online: "أونلاين",
		showing: "إظهار",
		records: "النتائج",
		employee_changed: "تغير الموظف",
		expired: "منتهي الصلاحية",
		id: "الرقم التعريفي",
		used_gifts_cards: "بطاقات الهدايا المستخدمة",
		whatsapp_marketing: "التسويق بالواتساب",
		reset_all: "إعادة ضبط الكل",
		active: "فعّال",
		unpaid: "غير مدفوع",
		paid: "مدفوع",
		waiting: "قيد الإنتظار",
		used: "مستخدم",
		authorization_code: "رقم الموافقة",
		add_deposit: "إضافة عربون",
		amount: "القيمة",
		payment: "الدفعة",
		customer_form: "نموذج العميل",
		balance: "الرصيد",
		loyality: "الولاء",
		package_expiry: "تاريخ إنتهاء الصلاحية",
		appointments_controller: "مراقب المواعيد",
		checkout: "الدفع",
		cancel_all: "إلغاء الكل",
		add_more_services: "إضافة المزيد من الخدمات",
		daily_activity_logs: "الأنشطة اليومية",
		redeemed_amount: "المبلغ المسترد",
		payments_details: "تفاصيل المدفوعات",
		access_level: "الصلاحيات",
		control_access: "التحكم في الوصول",
		role: "مجموعة الصلاحيات",
		edit_working_hours: "تعديل ساعات العمل",
		stock_operations: "عمليات المخزون",
		change_question: "تغيير؟",
		sell_without_stocks: "البيع بدون مخزون",
		welcome: "أهلا وسهلا",
		expired_gifts_cards: "بطاقات الهدايا المنتهية",
		prefrered_language: "اللغة المفضلة",
		left_working: "ترك العمل",
		if_you_need_more_info_please_check_out: "إذا كنت بحاجة إلى مزيد من المعلومات ، يرجى التحقق من",
		save_and_start: "إحفظ وإبدأ",
		please_wait_three_dots: "الرجاء الإنتظار...",
		data_saved_successfully: "تم حفظ البيانات بنجاح",
		backup: "نسخة إحتياطية",
		backup_account_data: "نسخة إحتياطية لمعلومات الحساب",
		email_it_now: "أرسل بالبريد الإلكتروني الآن",
		categories: "المجموعات",
		offers: "العروض",
		menu: "القائمة",
		preferred_language: "اللغة المفضلة",
		add_offer: "إضافة عرض",
		renew: "تجديد",
		disable_offer: "تعطيل العرض",
		renew_offer: "تجديد العرض",
		are_you_sure_you_want_to_renew_the_offer: "هل أنت متأكد من أنك تريد تجديد العرض",
		offer_expiry: "تاريخ إنتهاء الصلاحية",
		select_offer_expiry: "إختر تاريخ إنتهاء الصلاحية",
		offer_service_usage: "إستخدام خدمة عرض",
		add_custom_category: "إضافة مجموعة خاصة",
		add_category: "إضافة مجموعة",
		edit_category: "تعديل مجموعة",
		disable_category: "تعطيل مجموعة",
		invoice_gift_card_value_more_than_invoice_total_warning:
			"قيمة بطاقة الهدية اكبر من قيمة المبيعات،،، الرجاء اضافة المزيد من الخدمات حتى تتمكن من استخدام البطاقة",
		suggest_development: "اقتراح تطوير",
		home: "الرئيسية",
		wizard: "المساعد",
		select_category: "اختر مجموعة",
		add_custom_service: "اضافة خدمة خاصة",
		coming_soon: "لاحقا",
		expired_gift_card: "بطاقة الهدية منتهية الصلاحية",
		used_gift_card: "بطاقة الهدية مستخدمة مسبقا",
		legacy_version: "النسخة الأصلية",
		invoice_logo: "شعار الفاتورة",
		on: "مفعّل",
		off: "مطفئ",
		menu_off: "القائمة مغلقة",
		menu_on: "القائمة مفتوحة",
		setup_menu_now: "ضبط القائمة الآن",
		business_setup_guide: "إكمال ملفك التجاري",
		owner_email: "البريد الإلكتروني للمالك",
		request_free_trial: "طلب فترة تجريبية",
		login_by_email: "تسجيل الدخول عن طريق البريد الإلكتروني",
		login_by_account_id: "تسجيل الدخول عن طريق رقم الحساب",
		thank_you_for_your_time: "شكرا لك",
		request_sent_successfully: "تم إرسال الطلب بنجاح",
		we_will_send_you_instructions_to_get_started_via_email_or_whatsapp_within_24_hours:
			"سوف نقوم بإرسال التعليمات إلى البريد الإلكتروني المسجل او حساب الواتس آب خلال 24 ساعة",
		privacy_policy: "سياسة الخصوصية",
		features: "المميزات",
		programs: "البرامج",
		contact_us: "تواصل معنا",
		any_time_any_device_any_where: "في أي وقت ومن أي مكان ومن اي جهاز",
		smart_simple_secure: "ذكي, سهل, أمن",
		customers_support: "الدعم التقني",
		rules_and_permissions: "اداة مطورة لصلاحيات الموظفين",
		reports_and_analysis: "تقارير وتحاليل",
		secure_cloud_system: "نظام سحابي أمن",
		i_confirm_that_i_have_read_understood_and_agree_to_the: "أؤكد أنني قد قرأت وفهمت وأوافق على",
		you_agree_with_the_handling_business_data_in_accordance_to_our: "فإنك توافق على جمعنا لبياناتك الشخصية واستخدامها وتخزينها بالطريقة المبيّنة في",
		all_rights_reserved: "جميع الحقوق محفوظة",
		terms_and_conditions: "الشروط والاحكام",
		ultimate_technology: "ألتميت للتكنولوجيا",
		more: "المزيد",
		melcha_connect: "ملجه تواصل",
		main_office: "المقر الرئيسي",
		uae: "الامارات العربية المتحدة",
		ksa: "المملكة العربية السعودية",
		kuwait: "الكويت",
		later: "لاحقا",
		melcha_management_software_enhance_business_opearations_and_allow_oweners_and_decision_maker_complete_overview:
			"برنامج ملجه لادارة ملجه النسائية (المشاغل النسائية - ملجه - مراكز التجميل) المنكامل يمنح ادارة واصحاب مراكز التجميل النسائية ادارة متكاملة لاعمالهم",
		it_include_online_booking_digital_menu_home_services_booking_social_media_links_and_others:
			"وتشمل نظام حجز المواعيد الالكترونية, المنيو الالكتروني (عن طريق الرابط او عن طريق مسح رمز الاستجابة) , حجز الخدمات المنزلية, مواقع التواصل الاجتماعي.",
		our_services: "خدماتنا",
		ultimate_is_palestinian_information_technologies:
			"التميت للتكنولوجيا, شركة تكنولوجيا معلومات فلسطسينية, متخصصة في تطوير البرامج للاعمال, تصميم وتطوير المواقع الاكترونية, وتطبيقات الموبايل, التصميم الجرافيكي, التسويق الالكتروني, تحسين الظهور على مواقع البحث, استضافة المواقع الالكترونية, خدمات البريد الالكتروني للاعمال.",
		technical_support_and_backup: "الدعم الفني والنسخ الاحتياطي",
		our_team_consist:
			"				ان فريق العمل لدينا متخصص وذو خبرات عالية, مع ما يزيد عن 15 عام من الخبرة في مجال تطوير البرامج للاعمال, حيث يقوم الفريق بتدريب العملاء على كيفية استخدام البرنامج , كذلك نقوم بعمل نسخ احتياطية يومية لبيانات العملاء لتجنب فقدان اي بيانات مهمة للعملاء.",
		melcha_is_cloud_base_software:
			"				ان جميع برامج السحابة (نقاط البيع) – برامج السحابة لمحلات التجزئة مشتركة بالعديد من الميزات التي تغطي جميع احتياجات الاعمال كذلك لاداعي للقلق من تكاليف البرمجة المرتفعة, وتكاليف البرمجة المرتفعة للشبكة والخوادم, كذلك تكاليف الدعم الفني والصيانة, المميزات كما يلي:",
		respoinsive: "متوافق مع جميع احجام الشاشات",
		online_software: "يمكن العمل به عن طريق الانترنت",
		multi_languages_support_arabic: "متعدد اللغات وداعم للعربية",
		send_sms_through_whatsapp: "ارسال الرسائل عن طريق الوتساب",
		appointment_system: "نظام المواعيد",
		accounting_and_vat_calculations: "الحسابات العامة والضرائب",
		customers_survey_system: "نظام استبيان العملاء",
		daily_and_backup_reports: "التقارير اليومية والاحتياطية",
		respoinsive_descriptions:
			"ان جميع البرامج المطورة من جانبنا, تبنى لتكون متوافقة مع قياسات الشاشات المختلفة, لذلك يمكنك استخدامها سواء من الهاتف المتحرك او شاشة الحاسوب.",
		online_software_descriptions:
			"ان البرامج التي نطورها تسطيع ان تعمل عليها من انترنت السحابة من اي مكان في العالم, لذلك فانه لا حدود لديك لادارة اعمالك اينما تكون وفي اي وقت.",
		roles_and_permissions_descriptions:
			"							تسطيع بكل بساطة التحكم بصلاحيات الدخول للموظفين, ومنح الصلاحيات المناسبة لكل موظف من الموظفين بحسب درجته الوظيفية والمعلومات المسموح الاطلاع عليها",
		multi_languages_support_arabic_descriptions:
			"ان برامجنا تحتوي بشكل تلقائي على اللغة الانجليزية والعربية, لذلك بغض النظر عن اللغات والمهارات التي يحملها موظفك. فان برامجنا ستناسب الجميع.",
		send_sms_through_whatsapp_descriptions:
			"ان برامجنا ستحل لك قضية التخاطب مع العملاء لقضايا المخاطبات او التذكيرات باستخدام منصة واتساب الشهيرة, فهي مؤمنة ,مشفرة بالكامل ومجانية.",
		appointment_system_descriptions:
			"ان البرامج تحتوي على نظام حجوزات متطور, سهل الاستخدام, مع جدول زمني واضح والذي سيغطي جميع احتياجاتك لجدولة المواعيد او السلع الايجارية.",
		accounting_and_vat_calculations_descriptions:
			"احصل على تقرير مفصل للمبيعات وطرق الدفع للعملاء مع ديونهم وجميع احياجاتك المحاسبية للمبيعات اليومية والشهرية, كذلك حساب ضريبة المبيعات على البضائع المباعة",
		customers_survey_system_descriptions:
			"ان برامجنا يحتوي على نظام الكتروني لاستبيانات العملاء, لمعرفة مدى رضائهم عن الخدمات او السلع المقدمة من فريق العمل, كذلك لاستقبال الشكاوى بشكل حديث.",
		daily_and_backup_reports_descriptions:
			"سوف تتلقى بشكل اوتوماتيكي تقارير يومية الى صندوق البريد الالكتروني لتبقى على اطلاع دائم على كافة الامور الخاصة بعملك, احجام المبيعات, الدفعات والفرص التجارية.",
		try_software_for_seven_days_free: "جرب البرنامج لمدة 7 ايام مجانا",
		training_descriptions:
			"نحن نؤمن بان مشاهدة الفيديو هو افضل طريقة للتعلم والتدريب, وان افضل الممارسات العالمية الفعالة للتدريب هي عن طريق مشاهدة الفيديو القصيرة لمعالجات معينة في نظام البرنامج",
		already_have_an_account: "لديك حساب في ملجه؟",
		valid: "فعّال",
		send_message: "إرسال الرسالة",
		contact_us_message: "الرسالة",
		now_you_can_contact_us_any_time: "الان .. يمكنك التواصل معنا باي وقت للحصول على المزيد",
		commercial_partners_in_gulf: "الشركاء التجاريين في الخليج",
		telephone: "رقم الهاتف",
		faq: "الأسئلة المتكررة",
		we_received_your_message_our_team_will_contact_you_soon: "تم إرسال الرسالة بنجاح، سنقوم بالتواصل معك قريبا",
		business_ready: "الملف التجاري جاهز",
		wrong_number: "رقم خاطئ",
		online_services: "خدمات الأونلاين",
		english_qr: "الكود الإنجليزي",
		arabic_qr: "الكود العربي",
		business_alias: "الإسم الإلكتروني",
		disable_online_setting: "تعطيل خدمات الأونلاين",
		your_online_services_will_be_disabled_if_you_want_to_change_the_alias_name_please_contact_support:
			"سيتم تعطيل خدمات الاونلاين الخاصة بكم، اذا كنت تريد تغيير الاسم الرجاء التواصل مع قسم الدعم الفني الخاص بكم",
		activate_online_setting: "تفعيل إعدادات الأونلاين",
		arabic_link: "رابط الموقع بالعربية",
		english_link: "رابط الموقع بالإنجليزية",
		price_list: "قائمة الأسعار",
		incomplete_number: "الرقم غير مكتمل",
		show_price_list: "إظهار قائمة الأسعار",
		show_price_include_vat_message: "إظهار رسالة الأسعار تشمل الضريبة",
		show_offers: "إظهار العروض",
		press_ctrl_with_plus_or_minus_to_adjust_the_screen: "إضغط على (CTRL +) أو (CTRL -) لضبط البرنامج مع شاشة الحاسوب",
		show_menu: "إظهار القائمة",
		online_booking: "حجوزات الأونلاين",
		complains: "الشكاوى والإقتراحات",
		home_services: "الخدمات المنزلية",
		link: "الرابط",
		edit_customer: "تعديل العميل",
		add_note: "إضافة ملاحظة",
		edit_note: "تعديل ملاحظة",
		working: "يعمل",
		activated_products: "المنتجات الفعالة",
		not_exist_request_define_product: "غير موجود، طلب تعريف منتج؟",
		active_products: "المنتجات الفعالة",
		request_define_product: "طلب تعريف منتج",
		proceed: "متابعة",
		add_expense: "إضافة مصروف",
		time: "الوقت",
		i_do_not_want_to_add_expense: "أنا لا أريد إضافة مصروف",
		move_up: "تحريك لأعلى",
		move_down: "تحريك لأسفل",
		appointments_whatsapp: "مواعيد الواتساب",
		offers_whatsapp: "عروض الواتساب",
		alias_name: "الإسم المستعار",
		social_media: "التواصل الاجتماعي",
		google_maps: "خرائط جوجل",
		facebook_page_id: "معرّف فيسبوك الخاص",
		owner: "المالك",
		this_will_change_all_services_and_products_prices_according_to_the_new_vat:
			"سيؤدي ذلك إلى تغيير أسعار جميع الخدمات والمنتجات وفقًا لضريبة القيمة المضافة الجديدة",
		customer_added_successfully: "تم إضافة العميل بنجاح",
		employee_form: "نموذج الموظف",
		online_setting_privacy_policy_for_web_portal:
			'ان سياسة الخصوصية لبوابة الانترنت الخاصةبالشركاء (ملجه تواصل) هي ملحق لسياسة الخصوصية الرئيسية الموجودة في "موقع" ملجه.بقبول هذا الملحق ، فان (الشريك / الشركاء) تصرح لمنصة ملجه بعرض بعض المعلومات التجارية للمستخدمين (العميل / العملاء) مثل (خدمات وأسعار الصالون ، العروض ، ساعات العمل ، روابط وسائل التواصل الاجتماعي) ومنحهم الوصول إلى (حجز موعد ، طلب خدمات منزلية ، إرسال اقتراح أو شكوى لإدارة الأعمال).يمكن (للشريك / الشركاء) استخدام كلا من رمز الاستجابة السريعة ورابط الويب في مواقع الويب الخاصة بهم وصفحات وسائل التواصل الاجتماعي ومنطقة الاستقبال لإظهار المعلومات المختارة لعملائهم حسب اختيارهم في "خيار إعدادات صفحة الإنترنت".',
		online_setting_privacy_policy_aknowledge:
			'أنت تٌقر بأنك قد قرأت سياسة الخصوصية الخاصة بموقع ملجه هذه وتوافق على جميع بنودها وشروطها. كما أنك تقر بأنك على دراية بسياسة خصوصية البوابة الإلكترونية "ملجه تواصل" وتدرك أن المعلومات المذكورة أعلاه سيتم الوصول إليها من قبل (العملاء / العملاء) من موقع ملجه ، تطبيق MELCHA IOS ، تطبيق MELCHA Android.',
		apply_timing_for_all_days: "تطبيق على جميع أيّام الأسبوع",
		screen_adjust: "ضبط شاشة الحاسوب",
		coinsedence: "صدفة",
		primary_informations_updated_successfully: "تم تعديل المعلومات الرئيسية بنجاح",
		tax_updated_successfully: "تم تعديل الضريبة بنجاح",
		tax_status_updated_successfully: "تم تعديل حالة الضريبة بنجاح",
		new_updated_business_timing_will_be_applied_to_all_employees_working_hours: "سيتم تطبيق توقيت العمل الجديد / المحدث على جميع ساعات عمل الموظفين",
		new_updated_business_timing_will_be_applied_appointment_screen: "سيتم تطبيق توقيت العمل الجديد / المحدث على شاشة المواعيد",
		working_hours_updated_successfully: "تم تعديل ساعات العمل بنجاح",
		employee_added_successfully: "تم إضافة الموظف بنجاح",
		services_activated_successfully: "تم تفعيل الخدمات بنجاح",
		select_group: "إختيار مجموعة",
		there_are_services_not_saved_do_you_want_to_save_them_before_change_group: "هناك بعض الخدمات غير محفوظة بعد، هل تريد حفظهم قبل تغيير المجموعة؟",
		in: "في",
		email_should_be_correct: "البريد الإلكتروني للحجز يجب أن يكون صحيح",
		style: "النمط",
		background_image: "صورة الخلفية",
		primary_color: "اللون الرئيسي",
		add_role: "إضافة صلاحية",
		edit_role: "تعديل صلاحية",
		view_role: "مشاهدة صلاحية",
		view: "مشاهدة",
		no_access: "بدون صلاحيات",
		profile_and_activities: "الملف الشخصي والأنشطة",
		full_access: "جميع الصلاحيات",
		cashier: "كاشير",
		sell_without_stock: "البيع بدون مخزون",
		i_would_like_to_book_home_service_please: "أنا أريد حجز خدمة منزلية من فضلك",
		complain_sent_successfully: "تم إرسال الرأي/الشكوى بنجاح",
		book_appointment: "حجز موعد",
		telephone_should_start_with_plus_or_zero_zero: "رقم الهاتف يجب أن يبدأ ب (+ أو 00)",
		whatsapp_should_not_start_with_plus_or_zero_zero: "الواتس آب يجب أن لا يبدأ ب (+ أو 00)",
		username_is_incorrect: "إسم المستخدم غير صحيح",
		password_is_incorrect: "كلمة المرور غير صحيحة",
		should_be_a_number: "يجب أن يكون عدد",
		should_be_an_integer: "يجب أن يكون عدد صحيح",
		should_be_more_than: "يجب أن يكون أكثر من",
		should_be_less_than: "يجب أن يكون أقل من",
		should_be_letters: "يجب أن يكون أحرف",
		should_be_more_than_or_equal_to: "يجب أن يكون أكبر من أو يساوي",
		should_be_less_than_or_equal_to: "يجب أن يكون أصغر من أو يساوي",
		should_be_a_number_with_max_two_decimals: "يجب أن يكون رقم بعدد منازل عشرية 2 كحد أقصى",
		should_contain_only_digits: "يجب أن يحتوي على أرقام فقط",
		should_be_same_as: "يجب أن يكون مطابق ل",
		should_be_after: "يجب أن يكون بعد",
		should_be_after_or_equal: "يجب أن يكون بعد أو مساوي ل",
		should_be_multiple_of: "يجب أن يكون من مضاعفات",
		length_should_be_maximum: "طوله يجب أن يكون كحد أقصى",
		length_should_be: "طوله يجب أن يكون",
		should_contain_only_numbers_zero_to_nine_and_english_letters_small_capital: "يجب أن يحتوي فقط على أرقام (0 - 9) وأيضا حروف إنجليزية (a-z)(A-Z)",
		prefix: "البادئة",
		is_email_field: "هو حقل بريد إلكتروني",
		this_field_should_be_email: "هذا الحقل يجب أن يكون بريد إلكتروني",
		access: "الوصول",
		allow_change_price_in_invoice: "السماح بتغيير السعر في الفاتورة",
		customers_care_services: "خدمات العناية بالعملاء",
		others: "أخرى",
		sell: "بيع",
		sell_gift_card: "بيع بطاقة هدية",
		gift_card_value: "قيمة بطاقة الهدية",
		country_of_residency: "دولة الإقامة",
		you_are_connected_to: "لديك صلاحيات",
		current_password: "كلمة المرور الحالية",
		new_password: "كلمة المرور الجديدة",
		confirm_new_password: "تأكيد كلمة المرور الجديدة",
		i_want_to_add_expense: "أرغب بإضافة مصروف",
		quantity_added_successfully: "تم إضافة الكمية بنجاح",
		stock_operations_report: "تقرير عمليات المخزون",
		quantity_adjusted_successfully: "تم تعديل الكمية بنجاح",
		quantity_used_successfully: "تم إستخدام الكمية بنجاح",
		create_credit_note: "إنشاء اشعار دائن",
		credit_note: "اشعار دائن",
		service_on_item: "الخدمة / السلعة",
		qty: "الكمية",
		returned_value_including_vat: "القيمة المرجعة شاملة الضريبة",
		i_want_to_generate_manual_credit_note: "أرغب بإصدار اشعار دائن يدوي",
		edit_payment: "تعديل دفعة",
		business_name: "الإسم التجاري",
		daily_reports: "التقارير اليومية",
		remove_appointment: "حذف موعد",
		are_you_sure_you_want_remove_the_appointment: "هل أنت متأكد من حذف الموعد؟",
		are_you_sure_you_want_to_deposit_amount_to_this_customer: "هل أنت متأكد من أنك تريد إيداع ({amount}) لهذا العميل؟",
		are_you_sure_to_add_penalty_with_amount_to_this_customer: "هل أنت متأكد من أنك تريد إضافة غرامة بقيمة ({amount}) لهذا العميل؟",
		deposit: "دفعة مقدما",
		customer_edited_successfully: "تم تعديل العميل بنجاح",
		gifts_cards_used: "بطاقات الهدايا المستخدمة",
		gift_card: "بطاقة هدية",
		custom: "خاص",
		allow_add_free_gift_card: "السماح بإضافة بطاقة هدية مجانية",
		add_free_gift_card: "إضافة بطاقة هدية مجانية",
		percentage_can_not_be_zero_when_status_is_active: "النسبة يجب ان تكون أكبر من صفر عند تفعيل الضريبة",
		add_expenses_type: "إضافة نوع مصروف",
		add_supplier: "إضافة مورد",
		expense_type_added_successfully: "تم إضافة نوع المصروف بنجاح",
		supplier_added_successfully: "تم إضافة المورد بنجاح",
		expense_type_status_updated_successfully: "تم تحديث حالة نوع المصروف بنجاح",
		expense_type_already_exist: "نوع المصروف موجود مسبقا",
		supplier_status_updated_successfully: "تم تحديث حالة المورد",
		supplier_already_exist: "المورد موجود مسبقا",
		supplier_edited_successfully: "تم تعديل المورد بنجاح",
		expense_type_edited_successfully: "تم تعديل نوع المصروف بنجاح",
		edit_expense_type: "تعديل نوع المصروف",
		edit_supplier: "تعديل المورد",
		employee_profile_updated_successfully: "تم تحديث تفاصيل الموظف بنجاح",
		remaining_amount_will_be_registered_in_receivable: "سيتم تسجيل المبلغ المتبقي في ديون العملاء",
		pay_pending: "دفع معلق",
		add_payment: "إضافة دفعة",
		payment_added_successfully: "تم إضافة الدفعة بنجاح",
		partially_paid: "مدفوعة جزئيا",
		offer_added_successfully: "تم إضافة العرض بنجاح",
		offer_edited_successfully: "تم تعديل العرض بنجاح",
		some_block_times_conflict_with_previous_block_times: "تتعارض بعض أوقات الحظر مع أوقات الحظر الموجودة",
		block_times_added_successfully: "تم إضافة أوقات الحظر بنجاح",
		some_employees_are_not_allowed: "بعض الموظفين غير مسموح بهم",
		employee_does_not_have_email: "الموظف لا يملك بريد إلكتروني",
		email_sent_successfully: "تم إرسال البريد الإلكتروني بنجاح",
		block_time_cancelled_successfully: "تم إلغاء وقت الحظر بنجاح",
		service_added_successfully: "تم إضافة الخدمة بنجاح",
		customer_has_another_appointment_at_the_same_time: "العميل لديه موعد آخر في نفس التوقيت",
		you_have_another_appointment_at_the_same_time: "لديك موعد آخر بنفس التوقيت",
		please_add_at_least_one_service_to_the_appointment: "الرجاء إضافة على الأقل خدمة واحدة للموعد",
		employee_has_another_appointment_at_the_same_time: "الموظف لديه موعد آخر في نفس التوقيت",
		some_appointments_conflict_with_block_times: "بعض المواعيد تتعارض مع أوقات الحظر",
		employee_changed_successfully: "تم تغيير الموظف بنجاح",
		employee_is_the_same_as_old_employee: "الموظف هو نفس الموظف السابق",
		employee_not_exist: "الموظف غير موجود",
		item_not_exist: "العنصر غير موجود",
		changed: "تغيّر",
		edit_gift_card: "تعديل بطاقة هدية",
		gift_card_edited_successfully: "تم تعديل بطاقة الهدية بنجاح",
		reset_all_customers: "إعادة ضبط كل العملاء",
		are_you_sure_you_want_to_reset_the_sent_flag_for_all_customers: "هل أنت متأكد من أنك تريد إعادة ضبط تم الإرسال لكل العملاء؟",
		sent_status_reseted_successfully: "تم إعادة ضبط تم الإرسال بنجاح",
		customer_status_updated_successfully: "تم تحديث حالة العميل بنجاح",
		employee_status_updated_successfully: "تم تحديث حالة الموظف بنجاح",
		employee_access_edited_successfully: "تم تعديل صلاحيات دخول الموظف بنجاح",
		employee_edited_successfully: "تم تعديل الموظف بنجاح",
		new_updated_timing_will_be_applied_to_the_selected_employee_in_appointment_screen:
			"سيتم تطبيق التوقيت الجديد/المحدث على الموظف المحدد في شاشة الموعد",
		new_updated_timing_will_be_applied_to_the_selected_employee_in_online_booking_screen:
			"سيتم تطبيق التوقيت الجديد/المحدث على الموظف المختار في شاشة الحجز عبر الإنترنت",
		add_holiday: "إضافة إجازة",
		holiday_added_successfully: "تم إضافة الإجازة بنجاح",
		edit_holiday: "تعديل إجازة",
		holiday_edited_successfully: "تم تعديل الإجازة بنجاح",
		holiday_deleted_successfully: "تم حذف الإجازة بنجاح",
		note_added_successfully: "تم إضافة الملاحظة بنجاح",
		note_edited_successfully: "تم تعديل الملاحظة بنجاح",
		note_deleted_successfully: "تم حذف الملاحظة بنجاح",
		role_added_successfully: "تم إضافة الصلاحيات بنجاح",
		role_edited_successfully: "تم تعديل الصلاحيات بنجاح",
		credit_note_created_successfully: "تم إنشاء إشعار الدائن بنجاح",
		new_supplier: "مورد جديد",
		new_expense_type: "نوع مصروف جديد",
		expense_added_successfully: "تم إضافة المصروف بنجاح",
		payment_edited_successfully: "تم تعديل الدفعة بنجاح",
		billing: "الفواتير",
		category_edited_successfully: "تم تعديل المجموعة بنجاح",
		remove_category_and_it_is_services_from_billing_screen: "إزالة المجموعة وخدماتها من شاشة الفوترة",
		remove_category_and_it_is_services_from_appointment_screen: "إزالة المجموعة وخدماتها من شاشة المواعيد",
		remove_category_and_it_is_services_from_menu_screen: "إزالة المجموعة وخدماتها من شاشة القائمة",
		remove_services_within_group_from_assigned_employee: "إزالة الخدمات داخل المجموعة من الموظف المعين",
		hide_category_from_customers: "إخفاء المجموعة عن العملاء",
		status_updated_successfully: "تم تحديث الحالة بنجاح",
		show_to_customer_updated_successfully: "تم تحديث حالة إظهار للعميل بنجاح",
		order_updated_successfully: "تم تحديث الترتيب بنجاح",
		category_added_successfully: "تم إضافة المجموعة بنجاح",
		category_already_exist: "المجموعة موجودة مسبقا",
		category_exist_in_categories_to_activate_you_can_activate_it_from_there: "المجموعة موجودة في المجموعات للتفعيل، الرجاء تفعيلها من هناك",
		service_edited_successfully: "تم تعديل الخدمة بنجاح",
		disable_service: "تعطيل الخدمة",
		remove_service_from_billing_appointment_menu_screens: "حذف الخدمة من شاشات (الفواتير، الموعد، القائمة)",
		remove_service_from_assigned_group: "إزالة الخدمة من المجموعة المعينة",
		remove_service_from_assigned_employee: "إزالة الخدمة من الموظف المعين",
		hide_service_from_customers: "إخفاء الخدمة عن العملاء",
		remove_offer_from_billing_menu_screens: "إزالة العرض من شاشات (الفواتير والقائمة)",
		hide_offer_from_customers: "إخفاء العرض من العملاء",
		stock: "المخزون",
		product_edited_successfully: "تم تعديل المنتج بنجاح",
		disable_product: "تعطيل منتج",
		disable_selling_adding_adjusting_withdraw_from_inventory: "تعطيل البيع والإضافة والتعديل والسحب من المخزون",
		product_status_updated_successfully: "تم تحديث حالة المنتج بنجاح",
		product_defined_successfully: "تم تعريف المنتج بنجاح",
		image_updated_successfully: "تم تحديث الصورة بنجاح",
		image_deleted_successfully: "تم حذف الصورة بنجاح",
		controller_updated_successfully: "تم تحديث المتحكم بنجاح",
		account_backup_sent_successfully_to: "تم إرسال النسخة الاحتياطية للحساب بنجاح إلى",
		payment_type_status_updated_successfully: "تم تحديث حالة طريقة الدفع بنجاح",
		trn_number_should_be_15_characters: "الرقم الضريبي يجب أن يكون 15 حرف",
		everything: "كل شيء",
		discount_program_edited_successfully: "تم تعديل كود الخصم بنجاح",
		discount_program_already_exist: "كود الخصم موجود مسبقا",
		discount_program_added_successfully: "تم إضافة كود الخصم بنجاح",
		discount_program_status_updated_successfully: "تم تحديث حالة كود الخصم بنجاح",
		loyality_program_edited_successfully: "تم تعديل برنامج الولاء بنجاح",
		loyality_program_status_updated_successfully: "تم تحديث حالة برنامج الولاء بنجاح",
		invoice_setting_controller_edited_successfully: "تم تعديل المتحكم بالفاتورة بنجاح",
		logo_edited_successfully: "تم تعديل الشعار بنجاح",
		logo_deleted_successfully: "تم حذف الشعار بنجاح",
		account_not_exist: "الحساب غير موجود",
		invoice_setting_header_and_footer_edited_successfully: "تم تعديل إعدادات الترويسة والذيل للفاتورة بنجاح",
		online_setting_disabled_successfully: "تم تعطيل إعدادات الأونلاين بنجاح",
		online_setting_activated_successfully: "تم تفعيل إعدادات الأونلاين بنجاح",
		price_list_edited_successfully: "تم تعديل قائمة الأسعار بنجاح",
		online_booking_edited_successfully: "تم تعديل حجوزات الأونلاين بنجاح",
		social_media_edited_successfully: "تم تعديل التواصل الاجتماعي بنجاح",
		complains_edited_successfully: "تم تعديل الشكاوى والإقتراحات بنجاح",
		home_services_edited_successfully: "تم تعديل الخدمات المنزلية بنجاح",
		style_edited_successfully: "تم تعديل النمط بنجاح",
		background_image_edited_successfully: "تم تعديل صورة الخلفية بنجاح",
		background_image_deleted_successfully: "تم حذف صورة الخلفية بنجاح",
		online_setting_not_exist: "إعدادات الأونلاين غير موجودة",
		notification_setting_edited_successfully: "تم تعديل إعدادات الإشعارات بنجاح",
		body: "الجسم",
		some_data_not_allowed: "بعض البيانات غير مسموح بها",
		edit_appointment_sms: "تعديل رسالة موعد الواتساب ",
		edit_offers_whatsapp: "تعديل رسالة عروض الواتساب",
		start_date: "تاريخ البداية",
		end_date: "تاريخ النهاية",
		url: "الرابط",
		appointment_cancelled_successfully: "تم إلغاء الموعد بنجاح",
		appointments_cancelled_successfully: "تم إلغاء المواعيد بنجاح",
		appointment_attended_successfully: "تم الحضور للموعد بنجاح",
		cancel_appointments: "إلغاء المواعيد",
		are_you_sure_you_want_to_cancel_the_appointments: "هل أنت متأكد من أنك تريد إلغاء المواعيد؟",
		suggest_development_sent_successfully: "تم إرسال اقتراح التطوير بنجاح",
		current_password_is_incorrect: "كلمة المرور الحالية غير صحيحة",
		password_changed_successfully: "تم تغيير كلمة المرور بنجاح",
		sorry_there_is_an_error_happened: "نأسف حدث خطأ",
		send_to_buyer: "إرسال للمشتري",
		send_to_beneficiary: "إرسال للمستفيد",
		gift_card_logo: "شعار بطاقة الهدية",
		beneficiary: "المستفيد",
		this_payment_was_already_cancelled: "هذه الدفعة ملغية مسبقا",
		only_deposit_payments_can_be_cancelled: "فقط الدفعات المقدمة يمكن إلغاءها",
		data_was_not_updated_successfully: "لم يتم تحديث البيانات بنجاح",
		payment_cancelled_successfully: "تم إلغاء الدفعة بنجاح",
		authorization_code_is_required_when_the_payment_is_credit_card: "رقم الموافقة مطلوب في حال كانت طريقة الدفع بطاقة إئتمانية",
		customer_does_not_have_enough_balance: "رصيد العميل غير كافي",
		payment_method_not_exist: "طريقة الدفع غير موجودة",
		payment_method_not_changed: "طريقة الدفع لم تتغير",
		payment_not_exist: "الدفعة غير موجودة",
		service_added_sucessfully: "تم إضافة الخدمة بنجاح",
		item: "عنصر",
		all_invoice: "كل الفاتورة",
		part_from_invoice: "جزء من الفاتورة",
		free: "مجاني",
		daily_reports_sent_successfully: "تم إرسال التقارير اليومي بنجاح",
		complain_send: "إرسال الشكوى",
		seems_there_is_nothing_here: "الصفحة غير صحيحة",
		return_home: "العودة للرئيسية",
		backup_reports: "التقارير الاحتياطية",
		tiktok: "تيك توك",
		tiktok_should_start_with: "تيك توك يجب أن يبدأ ب",
		employee_email_already_exist: "البريد الإلكتروني للموظف موجود مسبقا",
		paid_voucher: "قسيمة مدفوعة",
		loyality_voucher: "قسيمة ولاء",
		free_voucher: "قسيمة مجانية",
		notifications: "التنبيهات",
		updates: "التحديثات",
		issued_gifts_cards: "بطاقات الهدايا المصدرة",
		is_not_arabic_text: "ليس نصا عربيا",
		is_not_english_text: "ليس نصا إنجليزيا",
		password_reset_link_sent_successfully_to_the_email: "تم إرسال رابط إعادة ضبط كلمة المرور إلى البريد الإلكتروني",
		set_new_password: "وضع كلمة مرور جديدة",
		enter_you_new_password: "كلمة المرور الجديدة الخاصة بك",
		confirm_password: "تأكيد كلمة المرور",
		submit_send: "إرسال",
		password_set_successfully: "تم حفظ كلمة المرور بنجاح",
		prices_are_vat_inclusinve: "الأسعار شاملة ضريبة القيمة المضافة",
		appointment_rescheduled: "تم إعادة جدولة الموعد بنجاح",
		copy_url_for_share: "نسخ الرابط للمشاركة",
		copied_to_clipboard: "تم النسخ للحافظة",
		trn_number_for_ksa_should_start_with_three_and_end_with_three: "الرقم الضريبي للسعودية يجب أن يبدأ ب 3 وينتهي ب 3",
		alias_name_is_incorrect: "الاسم المستعار غير صحيح",
		service_not_exist: "الخدمة غير موجودة",
		please_select_booking_date: "الرجاء إختيار تاريخ الحجز",
		book_today: "إحجز اليوم",
		do_you_want_to_add_another_service: "هل تريد حجز خدمة أخرى؟",
		customer_information: "معلومات العميل",
		services_are_incorrect: "الخدمات غير صحيحة",
		employees_are_incorrect: "بيانات الموظفين غير صحيحة",
		appointment_booked_successfully: "تم حجز الموعد بنجاح",
		add_more: "إضافة المزيد",
		review_booking: "مراجعة الحجز",
		total_services: "عدد الخدمات",
		confirm_booking: "تأكيد الحجز",
		light_primary_color: "اللون الرئيسي المخفّف",
		there_is_no_available_times_for_the_selected_date: "لا يوجد أوقات متاحة للتاريخ المختار",
		please_select_another_date: "الرجاء إختيار تاريخ آخر",
		reset: "الإفتراضي",
		incorrect_data: "بيانات غير صحيحة",
		reset_color: "إعادة اللون الإفتراضي",
		are_you_sure_you_want_to_reset_the_color: "هل أنت متأكد من إنك تريد إعادة اللون الإفتراضي؟",
		your_appointment_has_been_booked_successfully: "تم تأكيد الحجز بنجاح",
		booking_id: "رقم الحجز",
		booking_details: "تفاصيل الحجز",
		please_select_date: "الرجاء إختيار التاريخ",
		wizard_activate_vat: "تفعيل الضريبة",
		review: "مراجعة",
		change_booking_date: "تعديل تاريخ الحجز",
		this_option_will_delete_all_selected_services_from_basket: "هذا الخيار سوف يقوم بحذف كل الخدمات المختارة لديك في السلة",
		remove_last_service: "حذف آخر خدمة",
		this_option_will_return_you_to_home_page: "هذا الخيار سوف يعيدك للصفحة الرئيسية",
		mobile_already_exist: "رقم الموبايل موجود مسبقا",
		employee_mobile_already_exist: "رقم موبايل الموظف موجود مسبقا",
		email_already_exist: "البريد الإلكتروني موجود دسبقا",
		email_is_not_valid: "البريد الإلكتروني ليس صحيحا",
		edit_customer_email: "تعديل البريد الإلكتروني للعميل",
		customer_email_already_exist: "البريد الإلكتروني للعميل موجود مسبقا",
		customer_email_edited_successfully: "تم تعديل البريد الإلكتروني للعميل بنجاح",
		customer_mobile_already_exist: "رقم موبايل العميل موجود مسبقا",
		current_quantity: "الكمية الحالية",
		powered_by: "مشغل بواسطة",
		are_you_sure_you_want_to_cancel_the_payment_this_will_change_your_accounting_statements:
			"هل أنت متأكد من إلغاء الدفعة؟ هذا سيؤدي إلى تغيير البيانات المحاسبية",
		cancel_payment: "إلغاء دفعة",
		daily_reports_sent_successfully_to: "تم إرسال التقارير اليومية بنجاح إلى",
		deposit_return: "إرجاع دفعة مقدمة",
		to_activate_this_option_paid_amount_should_be_less_than_total_sales: "لتفعيل الخيار، يجب أن يكون المبلغ المدفوع أقل من قيمة المبيعات",
		to_activate_this_option_paid_amount_should_be_less_than_total_sales_and_payment_method_is_selected_for_all_payments:
			"لتفعيل الخيار يجب أن يكون المبلغ المدفوع أقل من قيمة المبيعات وطريقة الدفع مختارة لجميع الدفعات",
		f11_for_full_mode: "F11 لوضع ملء الشاشة",
		select_date: "إختر اليوم",
		product_request_sent_successfully: "تم إرسال الطلب بنجاح",
		product: "المنتج",
		invoice_not_exist: "الفاتورة غير موجودة",
		invoice_has_vat_and_can_not_be_cancelled: "يوجد ضريبة على الفاتورة ولا يمكن إلغاءها",
		can_not_cancel_invoice_contains_products: "لا يمكن إلغاء فاتورة تحتوي على منتجات",
		can_not_cancel_sell_gift_card_invoice: "لا يمكن إلغاء فاتورة بيع بطاقة هدية",
		invoice_cancelled_successfully: "تم إلغاء الفاتورة بنجاح",
		cancel_invoice: "إلغاء فاتورة",
		are_you_sure_you_want_to_cancel_the_invoice: "هل أنت متأكد من أنك تريد إلغاء الفاتورة؟",
		can_not_use_this_gift_card_since_it_is_for_another_customer: "لا يمكن إستخدام بطاقة الهدية لأنها لعميل آخر",
		you_dont_have_permission_to_use_this_screen_please_contact_system_admin: "ليس لديك صلاحيات لاستخدام هذه الشاشة ، يرجى الاتصال بمسؤول النظام",
		percentage_should_be_zero_when_status_is_disabled: "النسبة يجب أن تكون صفر عندما تكون الحالة معطلة",
		start: "البداية",
		all: "الكل",
		residancy_exipry: "تاريخ الإقامة",
		service_english_name_is_already_exist_in_active_or_disabled_services: "إسم الخدمة بالإنجليزية موجود مسبقا في الخدمات الفعّالة أو المعطلة",
		service_arabic_name_is_already_exist_in_active_or_disabled_services: "إسم الخدمة بالعربية موجود مسبقا في الخدمات الفعّالة أو المعطلة",
		group_english_name_is_already_exist_in_active_or_disabled_services: "إسم المجموعة بالإنجليزية موجود مسبقا في المجموعات الفعّالة أو المعطلة",
		group_arabic_name_is_already_exist_in_active_or_disabled_services: "إسم المجموعة بالعربية موجود مسبقا في المجموعات الفعّالة أو المعطلة",
		english_name_is_already_exist: "الإسم بالإنجليزية موجود مسبقا",
		arabic_name_is_already_exist: "الإسم بالعربية موجود مسبقا",
		change_branch: "تغيير الفرع",
		remember_password: "تذكر كلمة المرور",
		the_alias_is_not_correct_or_account_is_disabled: "الإسم المستعار ليس صحيحا أو أن الحساب معطّل",
		offer_type: "نوع العرض",
		fixed: "ثابت",
		variable: "متغيّر",
		max_number_of_services_to_use: "الحد الأقصى لعدد الخدمات للإستخدام",
		max_number_of_services_to_use_should_be_less_than_count_of_services: "الحد الأقصى لعدد الخدمات للإستخدام يجب أن يكون أقل من عدد الخدمات",
		max_number_of_services_to_use_is_required: "الحد الأقضى للخدمات للإستخدام مطلوب",
		some_offer_services_are_disabled: "بعض خدمات العرض معطلة",
		incorrect_parameters_for_offer: "مدخلات خاطئة للعرض",
		offer_not_exist: "العرض غير موجود",
		offer_is_disabled: "العرض معطّل",
		offer_expired: "العرض منتهي",
		fixed_services: "خدمات محددة",
		selection_from_customer: "خدمات مختارة من العميل",
		owner_name: "إسم المالك",
		service_date_and_time: "التاريخ والوقت للخدمة",
		this_product_is_already_defined: "هذا المنتج معرف مسبقا",
		this_product_is_already_exist: "هذا المنتج موجود مسبقا",
		there_is_already_a_request_for_this_product: "يوجد طلب مسبقا لهذا المنتج",
		already_defined: "معرف مسبقا",
		due_date: "تاريخ الإستحقاق",
		bill_from: "فاتورة من",
		expense_type: "نوع المصروف",
		bill_to: "فاتورة إلى",
		past: "سابقا",
		system_updates: "تحديثات النظام",
		offer_price_with_vat_without_vat: "سعر العرض (مع / بدون ضريبة القيمة)",
		sell_price_without_offer: "سعر البيع بدون عرض",
		some_appointments_to_add_are_at_the_same_time: "بعض المواعيد المراد إضافتها في نفس الوقت",
		appointment_overbooking: "حجز موعد زائد",
		activate_product: "تفعيل منتج",
		please_activate_some_products_for_sell_or_for_internal_use: "الرجاء تفعيل بعض المنتجات المخصصة للبيع أو الإستخدام الداخلي",
		employee_report: "تقرير الموظف",
		employee_commission_percentage: "نسبة عمولة الموظف",
		use_package_service: "إستخدام خدمة من الباقة",
		reminder_sent: "تم إرسال التذكير",
		refresh_the_page: "تحديث الصفحة",
		full_pay: "دفع كامل",
		partial_pay: "دفع جزئي",
		remaining_amount: "المبلغ المتبقي",
		this_product_is_already_exist_with_different_name: "هذا المنتج موجود مسبقا بإسم مختلف",
		old_sales: "مبيعات سابقة",
		appointment_not_exist_or_cancelled: "الموعد غير موجود أو ملغى",
		this_product_is_not_exist: "هذا المنتج غير موجود",
		group_exist_in_groups_to_activate_you_can_activate_it_from_there: "المجموعة موجود في المجموعات للتفعيل، يمكنك تفعيلها من هناك",
		group_already_exist: "المجموعة موجودة مسبقا",
		group_not_exist: "المجموعة غير موجودة",
		service_exist_in_services_to_activate_you_can_activate_it_from_there: "الخدمة موجودة في الخدمات للتفعيل، يمكنك تفعيل الخدمة من هناك",
		service_already_exist: "الخدمة موجودة مسبقا",
		your_request_has_been_submitted_successfully_suggested_product_will_be_approved_within_24_hours:
			"تم إرسال الطلب بنجاح، سيتم الموافقة على المنتج المقترح خلال 24 ساعة",
		allow_employee_overbooking: "السماح بالحجز الزائد للموظف",
		remember: "تذكر",
		print_services: "طباعة الخدمات",
		total_products: "عدد المنتجات",
		services_total: "مجموع الخدمات",
		products_total: "مجموع المنتجات",
		employee_profile: "الملف الوظيفي",
		invoice_hash: "رقم الفاتورة",
		supplier_invoice_date: "تاريخ فاتورة المورد",
		edit_expense: "تعديل مصروف",
		expense_edited_successfully: "تم تعديل المصروف بنجاح",
		cashier_summary: "ملخص الكاشير",
		authorization_code_is: "رقم الموافقة",
		are_you_sure_you_want_to_add_the_quantity: "هل أنت متأكد من إضافة الكمية؟",
		notify_management: "إرسال تنبيه للإدارة",
		notifications_sent_to_management_successfully: "تم إرسال التنبيهات للإدارة بنجاح",
		change_order: "تغيير الترتيب",
		please_add_the_services_that_the_employee_is_fluent_in_from_services_page: "يرجى إضافة الخدمات التي يجيدها الموظف من صفحة الخدمات",
		new_block_times_conflict_with_existing_block_times: "أوقات الحظر الجديدة تتعارض مع أوقات حظر موجودة",
		new_block_times_conflict_with_existing_appointments: "أوقات الحظر الجديدة تتعارض مع مواعيد موجودة",
		there_is_a_product_with_this_barcode_is_already_defined: "يوجد منتج بالباركود المرفق معرّف مسبقا",
		there_is_a_product_with_this_barcode_is_already_defined_you_need_to_activate_it: "يوجد منتج بالباركود المرفق معرّف مسبقا، أنت بحاجة لتفعيله",
		services_data_are_incorrect: "بيانات الخدمات غير صحيحة",
		employees_data_are_incorrect: "بيانات الموظفين غير صحيحة",
		cancel_invoice_or_create_credit_note: "إلغاء فاتورة / إنشاء اشعار دائن",
		edit_payment_method: "تعديل طريقة الدفع",
		cancel_advanced_payment: "إلغاء دفعة مقدمة",
		credit: "رصيد",
		add_penalty: "إضافة غرامة",
		penalty_added_successfully: "تم إضافة الغرامة بنجاح",
		customer_no_show: "عدم حضور العميل",
		new_penalty: "غرامة جديدة",
		penalty: "غرامة",
		account_balance_is_not_enough: "رصيد الحساب غير كافي",
		penalties_reports: "تقارير الغرامات",
		customers_penalties_report: "تقرير غرامات العملاء",
		customers_penalties: "غرامات العملاء",
		customers_offers_report: "تقرير عروض العملاء",
		remaining_services: "الباقي من الخدمات",
		offer: "العرض",
		advanced_credit: "رصيد إدخاري",
		confirm_appointment: "حجز موعد",
		other_reasons: "أسباب أخرى",
		debit_note: "إشعار مدين",
		unused_customers_offers_report: "تقرير الخدمات المتبقية من العروض",
		gift_card_description: "وصف بطاقة الهدية",
		payments_total_is_not_the_same_as_gift_card_value: "مجموع الدفعات لا يساوي قيمة بطاقة الهدية",
		training_account: "حساب التدريب",
		appointment_reminder: "تذكير بالموعد",
		reschedule_reminder: "تذكير لإعادة الحجز",
		rate_us: "تقييم الصالون",
		customer_care: "العناية بالعملاء",
		appointments_reminder: "التذكير بالمواعيد",
		no_show_reschedule: "إعادة جدولة عدم الحضور",
		edit_reschedule_appointments_message: "تعديل رسالة إعادة جدولة المواعيد",
		no_expiry: "غير منتهي",
		please_spend_the_current_quantity_and_disable_the_product_later: "الرجاء صرف الكمية الحالية وتعطيل المنتج لاحقا",
		export_to_excel_and_print: "التصدير لإكسل والطباعة",
		customer_account_balance_reminder: "تذكير برصيد حساب العميل",
		edit_customer_account_balance_reminder_sms: "تعديل رسالة التذكير برصيد الحساب للعميل",
		customer_account_balance_reminder_sent_successfully: "تم إرسال التذكير برصيد حساب العميل بنجاح",
		account_balance: "رصيد الحساب",
		rate_us_for_issued_invoices: "تقييم الصالون للفواتير المصدرة",
		rate_us_for_services_from_offers: "تقييم الصالون للخدمات من العروض",
		email_booking_reference: "البريد الإلكتروني (إختياري)",
		expense: "المصروف",
		supplier_not_exist: "المورد غير موجود",
		expense_type_not_exist: "نوع المصروف غير موجود",
		show_invoice: "عرض الفاتورة",
		submit_survey: "إرسال التقييم",
		kindly_rate_our_services: "الرجاء تقييم خدماتنا",
		rating: "التقييم",
		invoice_rated_successfully: "تم تقييم الفاتورة بنجاح",
		invoice_already_rated: "تم تقييم الفاتورة مسبقا",
		some_items_not_exist: "بعض العناصر غير موجودة",
		no_internet_connection: "لا يوجد إتصال بالإنترنت",
		please_check_and_try_again: "الرجاء التحقق والمحاولة مجددا",
		rating_from_5: "التقييم (من 5)",
		send_survey: "إرسال التقييم",
		send_invoice: "إرسال الفاتورة",
		survey_has_been_submitted_successfully: "تم مشاركة التقييم بنجاح",
		new_customers: "العملاء الجدد",
		another_session_is_already_exist_for_this_user_do_you_want_to_logout_from_it: "هناك جلسة أخرى فعالة لهذا المستخدم، هل تريد تسجيل الخروج منها؟",
		uploaded_file_must_be_one_of_these_extensions: "الملف المرفوع يجب أن يكون امتداده من هذه الامتدادات",
		percentage_of_each_employee: "نسبة كل موظف",
		employees_performance: "أداء الموظفين",
		best_services: "أفضل الخدمات",
		send_invoice_in_email_to_owner: "إرسال الفاتورة ببريد إلكتروني للمالك",
		the_session_has_been_expired_or_another_person_logged_in_with_the_same_user_please_login_again:
			"إنتهت صلاحية الجلسة أو قام أحد آخر بتسجيل الدخول بنفس المستخدم، الرجاء تسجيل الدخول مرة أخرى",
		another_person_logged_in_with_the_same_user: "قام شخص آخر بتسجيل الدخول بنفس المستخدم",
		the_session_has_been_expired_please_login_again: "إنتهت صلاحية الجلسة، الرجاء تسجيل الدخول مرة أخرى",
		you_are_not_logged_in_please_login_first: "أنت لم تقم بتسجيل الدخول، الرجاء تسجيل الدخول أولا",
		account_name: "إسم الحساب",
		total_sales_including_vat: "المبيعات شاملة الضريبة",
		vat_summary: "ملخص الضريبة",
		net_income: "المحصلة",
		general_accounting_report: "تقرير المحاسبة العام",
		vat_report: "تقرير ضريبة القيمة المضافة",
		vat_report_collected_vat: "الضريبة المحصلة من المبيعات",
		paid_vat_expenses: "الضريبة المدفوعة على المصاريف",
		vat_paid_to_goverment: "المحصلة",
		monthly_income: "الدخل الشهري",
		most_active_times: "أكثر الأوقات نشاطا",
		length_should_be_minimum: "طوله يجب أن يكون كحد أدنى",
		last_year: "السنة السابقة",
		max: "الحد الأقصى",
		last_three_months: "آخر 3 شهور",
		reminder_sent_successfully: "تم إرسال التذكير بنجاح",
		expense_with_same_supplier_invoice_number_and_date_already_exist_are_you_sure_that_you_want_to_continue:
			"يوجد مصروف بنفس رقم فاتورة المورد والتاريخ، هل أنت متأكد من أنك تريد المواصلة؟",
		all_services: "جميع الخدمات",
		you_can_choose_services: "يمكنك إختيار {numberOfServices} خدمات",
		add_item: "إضافة عنصر",
		add_custom_business_hours: "إضافة وقت عمل مخصص",
		edit_custom_business_hours: "تعديل وقت عمل مخصص",
		custom_business_hours: "أوقات العمل المخصصة",
		custom_working_hours_warning: "** سيتم تطبيق الاوقات الجديدة على اوقات المواعيد",
		this_date_already_has_record_you_can_edit_it: "هذا التاريخ يمتلك سجل يمكنك تعديله",
		custom_working_hours_added_successfully: "تم إضافة وقت العمل المخصص بنجاح",
		custom_working_hours_edited_successfully: "تم تعديل وقت العمل المخصص بنجاح",
		general_business_hours: "أوقات العمل العامة",
		orders: "الفواتير المعلقة",
		on_hold_report: "تقرير الفواتير المعلقة",
		order_id: "رقم الطلب",
		cancel_order: "إلغاء الطلب",
		are_you_sure_you_want_to_cancel_the_order: "هل أنت متأكد أنك تريد إلغاء الطلب؟",
		order_cancelled_successfully: "تم إلغاء الطلب بنجاح",
		employee_performance: "أداء الموظف",
		daily_income: "الدخل اليومي",
		show_on_online: "إظهار للأونلاين",
		hide_from_online: "اخفاء من الأونلاين",
		close: "إغلاق",
		services_without_the: "خدمات",
		image_should_be_square_width_equal_height: "الصورة يجب أن تكون مربعة (العرض = الارتفاع)",
		image: "الصورة",
		show_on_online_updated_successfully: "تم تعديل إظهار للأونلاين بنجاح",
		change_image: "تغيير الصورة",
		edit_offer: "تعديل العرض",
		send_automatic_rating_from_melcha: "إرسال التقييم التلقائي من ملجه",
		whatsapp_setting_edited_successfully: "تم تعديل إعدادات الواتس آب بنجاح",
		whatsapp_setting: "إعدادات الواتس آب",
		edit_whatsapp_setting: "تعديل إعدادات الواتس آب",
		whatsapp_bot: "الواتس آب الآلي",
		whatsapp_bot_setting: "إعدادات الواتس آب الآلي",
		disable_employee_will: "تعطيل الموظف سوف:",
		remove_employee_from_billing_screen: "إزالة الموظف من شاشة الفوترة",
		remove_employee_from_appointments_screen: "إزالة الموظف من شاشة المواعيد",
		remove_employee_from_assigned_services: "إزالة الموظف من الخدمات المعينة له",
		disable_employee_access: "تعطيل صلاحية دخول الموظف",
		activate_whatsapp_bot: "تفعيل الواتس آب الآلي",
		disconnected: "غير متصل",
		connected: "متصل",
		connect: "اتصل",
		connect_now: "اتصل الآن",
		please_contact_sales: "الرجاء التواصل مع المبيعات",
		whatsapp_already_connected: "الواتس آب متصل مسبقا",
		please_activate_two_step_verification_before_connect_to_whatsapp: "الرجاء تفعيل رمز المصادقة الثنائي في حساب الواتس آب الخاص بك قبل الاتصال",
		in_case_the_number_is_new_please_do_not_send_many_messages_to_prevent_blocking_the_number:
			"في حال أنّ الرقم جديد، الرجاء عدم إرسال كميات كبيرة من الرسائل حتى لا يتم إيقاف الرقم",
		connect_to_whatsapp: "اتصل بالواتس آب",
		you_can_not_use_offers_in_events_and_national_holidays: "لا يمكنك استخدام خدمات العروض في المناسبات والاعياد الوطنية",
		access_inside: "دخول",
		failed_to_get_the_qr: "لم يتم إحضار الرمز بنجاح",
		failed_to_send_the_message: "لم يتم إرسال الرسالة بنجاح",
		whatsapp_connected_successfully: "تم الإتصال بواتس آب بنجاح",
		whatsapp_not_connected_successfully: "لم يتم الإتصال بواتس آب بنجاح",
		whatsapp_message_sent_successfully: "تم إرسال رسالة الواتس آب بنجاح",
		whatsapp_is_not_connected: "الواتس آب غير متصل",
		you_do_not_have_valid_whatsapp_package_please_contact_sales: "لا يوجد لديك باقة واتس آب صالحة، الرجاء التواصل مع المبيعات",
		sent_updated_successfully: "تم تحديث إرسال بنجاح",
		please_connect_to_whatsapp_from_notifications_setting_screen: "الرجاء الاتصال مع الواتس آب من شاشة إعدادات التنبيهات",
		send_message_in_arabic: "إرسال الرسالة بالعربية",
		send_message_in_english: "إرسال الرسالة بالإنجليزية",
		one_of_the_messages_english_or_arabic_should_be_activated: "واحدة من الرسائل (الإنجليزية أو العربية) يجب أن تكون مفعلة",
		is_not_arabic_or_english_text: "يجب أن يكون أحرف (إنجليزية أو عربية) فقط",
		should_be_either_arabic_or_english_text_or_numbers: "يجب أن يكون إمّا أحرف (إنجليزية أو عربية) أو أرقام",
		your_package_is_paused_please_activate_it_from_notification_setting_screen:
			"الباقة الخاصة بك متوقفة مؤقتا، الرجاء تفعيلها من شاشة إعدادات التنبيهات",
		resume_package: "إستئناف الباقة",
		are_you_sure_you_want_to_resume_the_package: "هل أنت متأكد أنك تريد إستئناف الباقة؟",
		package_resumed_successfully: "تم إستئناف الباقة بنجاح",
		pause: "إيقاف مؤقتا",
		resume: "إستئناف",
		package_paused_successfully: "تم إيقاف الباقة مؤقتا بنجاح",
		pause_package: "إيقاف الباقة مؤقتا",
		are_you_sure_you_want_to_pause_the_package: "هل أنت متأكد أنك تريد إيقاف الباقة مؤقتا؟",
		offered_on_a_monthly_fee_by_third_party_companies_not_affiliated_with_ultimate_technologies:
			"تقدم برسوم شهرية عن طريق شركات طرف ثالث غير تابعة لالتميت للتكنولوجيا",
		your_package_has_been_expired_please_contact_sales: "الباقة الخاصة بك قد إنتهت صلاحيتها، الرجاء التواصل مع المبيعات",
		day_without_the: "يوم",
		created_by: "أنشئ من",
		please_try_again_later: "الرجاء المحاولة لاحقا",
		payments_summary: "ملخص الدفعات",
		paused: "متوقف مؤقتا",
		send_issued_invoice_to_customer: "إرسال الفاتورة المصدرة للعميل",
		send_employees_rating_from_melcha: "إرسال تقييم الموظفين من ملجه",
		send_appointment_confirmation_to_customer: "إرسال تأكيد الموعد للعميل",
		send_appointment_confirmation_to_customer_on_booking_system_or_online: "إرسال تأكيد الموعد للعميل بعد الحجز (النظام أو أونلاين)",
		send_online_appointment_confirmation_to_customer: "إرسال تأكيد لموعد الأونلاين للعميل",
		send_system_appointment_confirmation_to_customer: "إرسال تأكيد لموعد النظام للعميل",
		send_appointment_confirmation_for_bookings_from_online: "إرسال تأكيد الموعد للحجوزات من الأونلاين",
		send_appointment_confirmation_for_bookings_from_system: "إرسال تأكيد الموعد للحجوزات من النظام",
		whatsapp_bot_setting_edited_successfully: "تم تعديل إعدادات الواتس آب الآلي بنجاح",
		please_enter_a_number_which_has_whatsapp_service_activated_on_it: "الرجاء إدخال رقم مفعّل لخدمة الواتس آب",
		include_employees_assessment_link: "تضمين رابط تقييم الموظفين",
		this_option_is_not_allowed_when_automatic_whatsapp_is_connected: "هذا الخيار غير متاح في حال كان الواتس آب الآلي متصل",
		customer_not_exist: "العميل غير موجود",
		user_has_no_rights_to_the_system: "المستخدم غير حاصل على حقوق على النظام ",
		send_appointment_confirmation: "إرسال تأكيد الموعد",
		menu_qr: "رمز الاستجابة السريعة للقائمة",
		send_automatic_online_booking_confirmation_from_melcha: "إرسال تأكيد حجز أونلاين تلقائي من ملجه",
		this_option_is_not_allowed_when_automatic_whatsapp_is_connected_or_online_setting_is_disabled:
			"هذا الخيار غير متاح في حال كان الواتس آب الآلي متصل أو إعدادات الأونلاين معطلة",
		it_is_not_allowed_to_change_alias_name_after_selection: "* غير مسموح بتغيير الإسم المستعار بعد الإختيار",
		download_graphics_for_reception: "تنزيل النموذج الخاص بالاستقبال",
		basic: "أساسي",
		advanced_package: "متقدم",
		premium: "بريميوم",
		subscription_expired_at: "ينتهي الاشتراك في",
		expired_at: "تنتهي في",
		renew_subscription: "تجديد الاشتراك",
		allow_sell_products: "السماح ببيع المنتجات",
		this_number_does_not_have_active_whatsapp: "الرقم غير مفعّل لخدمة الواتس آب",
		this_number_have_active_whatsapp: "هذا الرقم مفعّل لخدمة الواتس آب",
		activate_customer: "تفعيل العميل",
		are_you_sure_you_want_to_activate_the_customer: "هل أنت متأكد أنك تريد تفعيل العميل؟",
		customer_activated_successfully: "تم تفعيل العميل بنجاح",
		not_allowed: "غير مسموح",
		check_whatsapp_number_in_process: "رقم الواتس آب قيد التحقق",
		whatsapp_number_checked_successfully: "تم التحقق من رقم الواتس آب بنجاح",
		verification_code: "رمز التحقق",
		please_enter_the_code_you_received_on_your_whatsapp: "من فضلك أدخل الرمز الذي تلقيته على الواتس آب الخاص بك",
		resend_code: "إعادة إرسال الرمز؟",
		the_code_you_entered_is_incorrect: "الرمز الذي أدخلته غير صحيح",
		customer_is_disabled_please_activate_him_first: "العميل معطّل، الرجاء تفعيله أولا",
		you_can_not_request_code_in_less_than_1_minute_please_try_again: "لا يمكنك طلب الرمز خلال أقل من دقيقة، الرجاء المحاولة مرة أخرى",
		added_at: "تمت الإضافة:",
		ads_campaigns: "الحملات الإعلانية",
		campaign_period: "فترة الحملة",
		customer_type: "نوع العميل",
		campaign_name: "اسم الحملة",
		the_code_has_been_expired_we_resend_another_one_to_you: "لقد انتهت صلاحية الرمز، تم إرسال رمز جديد إليك",
		send_otp_for_new_customers: "إرسال الOTP للعملاء الجدد",
		server_disconnected: "الخادم غير متصل",
		server_is_disconnected_please_try_again_later: "الخادم غير متصل، الرجاء المحاولة لاحقا",
		send_otp_for_new_customers_from_melcha: "إرسال الOTP للعملاء الجدد من ملجه",
		print_daily_activities_for_employees: "طباعة النشاطات اليومية للموظفين",
		employee_daily_activities: "النشاطات اليومية للموظف",
		campaign_start: "بداية الحملة",
		campaign_end: "نهاية الحملة",
		customers_count_total: "عدد العملاء الكلي",
		add_campaign: "إضافة حملة",
		done_services: "الخدمات المنجزة",
		starts_with_letter: "يبدأ بحرف",
		operand: "المعامل",
		less_than_or_equal: "أقل من أو يساوي",
		less_than: "أقل من",
		equal: "يساوي",
		greater_than: "أكبر من",
		greater_than_or_equal: "أكبر من أو يساوي",
		starts_with: "يبدأ ب",
		contains: "يحتوي على",
		customers_count_within_campaign: "عدد العملاء ضمن الحملة",
		campaign_content: "محتوى الحملة",
		customers_count_within_campaign_should_be_more_than_zero: "عدد العملاء ضمن الحملة يجب أن يكون أكبر من 0",
		customers_total_count: "عدد العملاء الكلي",
		success: "نجح",
		failed: "فشل",
		sending: "قيد الإرسال",
		campaign_not_exist_or_not_allowed_to_edit: "الحملة غير موجودة أو غير مسموح بتعديلها",
		temporary_save: "حفظ مؤقت",
		temporary_saved: "محفوظ مؤقتا",
		can_not_change_the_status_of_this_campaign: "لا يمكن تغيير الحالة لهذه الحملة",
		status_changed_successfully: "تم تغيير الحالة بنجاح",
		start_sending: "إبدأ الإرسال",
		disable_campaign: "إلغاء الحملة",
		when_you_disable_campaign_you_can_not_activate_it_again: "عند إلغاء الحملة أنت لا تستطيع تفعيلها مجددا",
		report: "التقرير",
		ads_campaign_report: "تقرير الحملة",
		drop_file_here: "لتحميل الصور والملفات",
		campaign_ready: "الحملة جاهزة",
		you_can_not_edit_customer_list_after_save: "- لن تستطيع تعديل قائمة العملاء بعد الحفظ",
		campaign_added_successfull: "تم إضافة الحملة بنجاح",
		customer_selection: "اختيار العملاء",
		sent_date_and_time: "تاريخ ووقت الإرسال",
		campaign_content_edited_successfully: "تم تعديل محتوى الحملة بنجاح",
		google_review: "مراجعة جوجل",
		product_have_barcode: "المنتج لديه باركود",
		product_does_not_have_barcode: "المنتج ليس لديه باركود",
		expired_only: "تنتهي",
		only_images_videos_pdf_files_are_supported: "فقط الصور، الفيديو، الPDF مدعوم",
		file_size_should_be_less_5_mb: "حجم الملف يجب أن يكون أقل من 5 MB",
		please_complete_form_to_calculate_number_of_campaign_customers: "الرجاء إكمال الشروط لإحصاء عدد عملاء الحملة",
		start_now: "إبدا الآن",
		max_size_5_mb: "الحد الأقصى 5 MB",
		pending: "قيد الإنتظار",
		show_customers: "إظهار العملاء",
		hide_customers: "إخفاء العملاء",
		adding_date: "تاريخ الإضافة",
		resend_faild: "إعادة إرسال الفشل",
		there_are_no_failed_customers_for_this_campaign: "لا يوجد فشل في هذه الحملة",
		resend_failed_started_successfully: "تم بدء إعادة إرسال الفشل",
		renew_campaign: "تجديد الحملة",
		campaign_renewed_successfully: "تم تجديد الحملة بنجاح",
		send_test: "أرسل تجربة",
		campaign_test_send_successfully: "إرسال التجربة تم بنجاح",
		messages_in_the_campaign_are_sent_with_a_time_difference_of_30_to_90_seconds_between_each_client_and_another:
			"- يتم ارسال الرسائل في الحملة بفرق توقيت من 30 - 90 ثانية بين كل عميل واخر",
		the_sending_stops_for_approximately_5_to_15_minutes_after_sending_100_messages: "- يتوقف الارسال لمدة 5 - 15 دقيقة تقريبا بعد ارسال 100 رسالة",
		messages_will_be_sent_between_9am_to_11pm_only: "- سيتم إرسال الرسائل بين ال9:00 صباحا حتى ال11:00 مساءا فقط",
		serial: "الرقم التسلسلي",
		full_name: "الاسم كامل",
		first_name: "الإسم الأول",
		allow_whatsapp_contact: "السماح بالتواصل عالواتس آب",
		we_serve_only_ladies_customers: "نحن نخدم فقط عملاء نساء",
		we_serve_only_mens_customers: "نحن نخدم فقط عملاء رجال",
		we_serve_only_kids_customers: "نحن نخدم فقط عملاء أطفال",
		campaign_starts_sending_after_approximately_10_minutes_of_starting: "- تبدا الحملة تقريبا بعد 10 دقائق من التشغيل",
		melcha_is_not_responsible_of_banning_the_number_from_whatsapp_company_due_to_misuse:
			"- ملجه غير مسؤولة عن حظر الرقم من شركة واتس آب بسبب سوء الاستخدام",
		remove_package: "حذف الباقة",
		are_you_sure_you_want_to_remove_the_package: "هل أنت متأكد أنك تريد حذف الباقة؟",
		please_connect_your_whatsapp_automatic_whatsapp_is_disconnected: "الرجاء الاتصال بالواتس آب الخاص بك، الواتس آب الآلي غير متصل",
		whatsapp_number_has_been_disconnected_successfully_from_melcha: "تم فصل الرقم بنجاح من ملجه",
		birth_month: "شهر الميلاد",
		january: "كانون الثاني",
		february: "شباط",
		march: "آذار",
		april: "نيسان",
		may: "أيار",
		june: "حزيران",
		july: "تموز",
		august: "آب",
		september: "أيلول",
		october: "تشرين الأول",
		november: "تشرين الثاني",
		december: "كانون الأول",
		frequent_has_1_invoice_and_last_invoice_is_before_less_than_60_days: "المتكرر: لديه فاتورة و آخر فاتورة له قبل أقل من 60 يوم",
		missed_has_no_invoices_or_last_invoice_is_before_more_than_60_days: "المفقود: ليس لديه أي فاتورة أو آخر فاتورة له قبل أكثر من 60 يوم",
		new_the_others_are_new_customers: "الجديد: ما تبقى هم عملاء جدد",
		current_commission: "العمولة الحالية",
		employees_commissions_report: "تقرير عمولات الموظفين",
		activities_report_summary: "ملخص تقرير الحركات",
		activities_summary: "ملخص الحركات",
		reception_and_stuff_treatment: "الاستقبال وتعامل الموظفين",
		cleanliness_and_place_arrangment: "النظافة وترتيب المكان",
		prices: "الأسعار",
		quality_of_provided_services: "جودة الخدمات المقدمة",
		complains_report: "تقرير الشكاوى",
		suggestions_and_complains_report: "تقرير الاقتراحات والشكاوى",
		suggestions_and_complains: "الاقتراحات والشكاوى",
		surveys_report: "تقرير الاستبيانات",
		remove_connection: "حذف الاتصال",
		are_you_sure_you_want_to_remove_whatsapp_from_your_account: "هل أنت متأكد أنك تريد حذف اتصال الواتس آب في حسابك؟",
		pdf: "PDF",
		edit_name_request: "طلب تعديل الاسم",
		edit_name_request_sent_successfully: "تم إرسال طلب تعديل الاسم بنجاح",
		main_header: "العنوان الرئيسي",
		website_setting: "إعدادات الموقع",
		secondary_header: "العنوان الثانوي",
		paragraph: "الفقرة",
		main_image: "الصورة الرئيسية",
		home_page: "الصفحة الرئيسية",
		images: "الصور",
		ads_banner: "لافتة الإعلانات",
		show_counters: "إظهار الأعداد",
		favicon: "رمز الشريط",
		english_logo: "شعار الإنجليزية",
		arabic_logo: "شعار العربية",
		home_page_setting_updated_successfully: "تم تحديث إعدادات الصفحة الرئيسية بنجاح",
		favicon_updated_successfully: "تم تحديث رمز الشريط بنجاح",
		image_deleted_succesfully: "تم حذف الصورة بنجاح",
		sign_up: "سجل الآن",
		english_subject: "العنوان بالإنجليزية ",
		arabic_subject: "العنوان بالعربية",
		english_description: "الوصف بالإنجليزية",
		arabic_description: "الوصف بالعربية",
		english_question: "السؤال بالإنجليزية",
		arabic_question: "السؤال بالعربية",
		english_answer: "الإجابة بالإنجليزية",
		arabic_answer: "الإجابة بالعربية",
		add_term_and_condition: "إضافة شرط وحكم",
		edit_term_and_condition: "تعديل شرط وحكم",
		term_and_condition_added_successfully: "تم إضافة الشرط والحكم بنجاح",
		term_and_condition_edited_successfully: "تم تعديل الشرط والحكم بنجاح",
		add_faq: "إضافة سؤال متكرر",
		edit_faq: "تعديل سؤال متكرر",
		faq_added_successfully: "تم إضافة السؤال المتكرر بنجاح",
		faq_edited_successfully: "تم تعديل السؤال المتكرر بنجاح",
		add_privacy_policy: "إضافة سياسة خصوصية",
		edit_privacy_policy: "تعديل سياسة خصوصية",
		privacy_policy_added_successfully: "تم إضافة سياسة الخصوصية بنجاح",
		privacy_policy_edited_successfully: "تم تعديل سياسة الخصوصية بنجاح",
		me: "أنا",
		woman: "امرأة",
		man: "رجل",
		show_name: "إسم الظهور",
		the_activation_code_will_be_sent: "سيتم إرسال كود التفعيل عليه",
		membership_email_will_not_be_visible_to_members: "البريد الإلكتروني (لن يظهر للأعضاء)",
		analysis: "التحليلات",
		requested_customers: "طلبات العملاء",
		men: "الرجال",
		women: "النساء",
		current_relations: "العلاقات الحالية",
		banned_relations: "العلاقات المحظورة",
		db_connections: "اتصالات المستخدمين",
		backup_database: "نسخة احتياطية للبيانات",
		whatsapp_connection: "اتصال الواتس آب",
		welcome_message: "رسالة الترحيب",
		approval_message: "الموافقة",
		boqsha_product: "من منتجات بقشه",
		countries: "الدول",
		currency: "العملة",
		add_country: "إضافة دولة",
		edit_country: "تعديل دولة",
		country_added_successfully: "تم إضافة الدولة بنجاح",
		country_edited_successfully: "تم تعديل الدولة بنجاح",
		cities: "المدن",
		add_city: "إضافة مدينة",
		edit_city: "تعديل مدينة",
		city_added_successfully: "تم إضافة المدينة بنجاح",
		city_edited_successfully: "تم تعديل المدينة بنجاح",
		mobile_number_will_not_be_visible_to_members: "رقم الهاتف (لن يظهر للأعضاء)",
		age: "العمر",
		weight: "الوزن",
		weight_kg: "الوزن (كيلوغرام)",
		religion: "الدين",
		occupation: "المهنة",
		residance_country: "بلد الإقامة",
		marital_status: "الحالة الاجتماعية",
		education_level: "المستوى التعليمي",
		marriage_type: "نوع الزواج",
		traditional_marriage_is_the_official_marriage_known_between_the_parents_legally_documented_and_fulfilling_all_marital_rights:
			"1- الزواج التقليدي هو الزواج الرسمي المعروف بين الأهل والموثق قانونا والمستوفي جميع الحقوق الزوجية .",
		marriage_under_special_conditions_marriage_under_conditions_agreed_upon_by_both_parties_each_according_to_their_circumstances_such_as_not_committing_to_daily_overnight_stays_or_accepting_the_womans_children_from_a_previous_marriage_to_live_in_the_new_marriage_or_any_conditions_agreed_upon_by_both_parties:
			"2- الزواج بشروط خاصة : الزواج بشروط يتفق عليها الطرفين كل حسب ظروفه مثل عدم الإلتزام بالمبيت اليومي أو قبول سكن أطفال المرأة من زواج سابق بالزواج الجديد أو أي شروط يتفق عليها الطرفين .",
		accept_pluralism: "قبول التعدد",
		about_me: "معلومات عني",
		my_desires: "رغباتي بالطرف الآخر",
		islam: "الإسلام",
		christianity: "المسيحية",
		single: "أعزب",
		married: "متزوج",
		divorced_without_children: "مطلق بدون أولاد",
		divorced_with_children: "مطلق مع أولاد",
		seperated_without_divorce: "منفصل بدون طلاق",
		widowed_without_children: "أرمل بدون أولاد",
		widowed_with_children: "أرمل مع أولاد",
		uneducated: "غير متعلم",
		primary: "ابتدائي",
		middle: "متوسط",
		secondary: "ثانوي",
		bachelor: "بكالوريوس",
		master: "ماجستير",
		phd: "دكتوراة",
		diploma: "دبلوم",
		hijab: "الحجاب",
		veiled: "منقبة",
		not_veiled: "غير محجبة",
		traditional_marriage: "زواج تقليدي",
		marriage_under_special_conditions: "زواج بشروط خاصة",
		sign_up_done_successfully: "تم التسجيل بنجاح",
		we_sent_an_email_to_your_email_address: "تم إرسال بريد إلكتروني إلى البريد الإلكتروني المسجل",
		please_verify_your_email: "الرجاء الضغط على الرابط لتوثيق البريد الإلكتروني",
		sign_in_please_verify_your_email: "الرجاء توثيق البريد الإلكتروني",
		please_check_the_email_either_in_inbox_or_spam_folder: "الرجاء التأكد من البريد الإلكتروني في مجلد الوارد أو الرسائل غير مرغوب فيها",
		invalid_token: "رمز خاطئ",
		token_already_verified: "تم تفعيل الرمز مسبقا",
		user_verified_successfully: "تم تفعيل المستخدم بنجاح",
		token_expired: "تم انتهاء صلاحية الرمز",
		with_hijab: "محجبة",
		without_hijab: "غير محجبة",
		i_am_woman: "أنا إمرأة",
		i_am_man: "أنا رجل",
		subscription: "الاشتراك",
		subscription_updated_successfully: "تم تعديل الاشتراك بنجاح",
		customers_requests: "طلبات العملاء",
		verified: "تم التحقق",
		created_at: "أنشئ في",
		ip: "عنوان الشبكة",
		device_type: "نوع الجهاز",
		browser_type: "نوع المتصفح",
		and_are_non_refundable: "وهي غير مستردة",
		subscription_fees_are: "رسوم التسجيل",
		i_have_read_and_agree_on_terms_and_conditions: "قرأت وأوافق على الشروط والأحكام",
		sponsor_name: "إسم ولي الأمر",
		sponsor_relation: "علاقة ولي الأمر",
		sponsor_mobile: "هاتف ولي الأمر",
		pay_and_complete_registration: "الدفع وإكمال التسجيل",
		father: "الأب",
		mother: "الأم",
		brother: "الأخ",
		sister: "الأخت",
		uncle: "الخال / العم",
		approve: "موافقة",
		reject: "رفض",
		customers_editing: "تعديلات العملاء",
		preferred_partners: "الشركاء المفضلين",
		whom_i_liked: "الأشخاص المعجب بهم",
		whom_liked_me: "الأشخاص المعجبين بي",
		profile_edited_successfully: "تم تعديل الملف الشخصي بنجاح",
		employee_sign_in: "دخول الموظفين",
		height_cm: "الطول (سم)",
		height: "الطول",
		islam_soni: "الإسلام (سني)",
		islam_sheaa: "الإسلام (شيعي)",
		white: "أبيض",
		white_brown: "قمحي فاتح",
		brown: "قمحي",
		bronze: "برونزي",
		light_black: "أسمر فاتح",
		black: "أسمر",
		skin_color: "لون البشرة",
		medical_status: "الحالة الصحية",
		good_condition: "الحالة جيدة",
		have_a_chronic_disease: "مع مرض مزمن",
		medical_disabled: "من ذوي الهمم",
		hijab_type: "نوع الحجاب",
		sponsor: "ولي الأمر",
		student: "طالب",
		goverment_employee: "موظف حكومي",
		private_sector_employee: "موظف قطاع خاص",
		retired: "متقاعد",
		apple_touch_icon: "Apple Touch Icon",
		apple_touch_icon_updated_successfully: "تم تحديث (Apple Touch Icon) بنجاح",
		mutual_admiration: "الاعجاب المتبادل",
		approval_after_edit_message: "الموافقة بعد التعديل",
		like_message: "ابداء الاعجاب",
		database_backup_sent_successfully_to: "تم إرسال النسخة الاحتياطية للبيانات إلى",
		men_who_like_women: "الرجال المعجبون بنساء",
		women_who_like_men: "النساء المعجبات برجال",
		email_setting: "إعدادات البريد الإلكتروني",
		subscription_and_verify_email_message: "الاشتراك وتوثيق البريد الإلكتروني",
		whatsapp_resumed_successfully: "تم استئناف الواتس آب بنجاح",
		whatsapp_paused_successfully: "تم إيقاف الواتس آب مؤقتا بنجاح",
		whatsapp_connection_removed_successfully: "تم حذف اتصال الواتس آب بنجاح",
		resume_whatsapp: "إستئناف الواتس آب",
		are_you_sure_you_want_to_resume_whatsapp: "هل أنت متأكد أنك تريد إستئناف الواتس آب؟",
		pause_whatsapp: "إيقاف مؤقت للواتس آب",
		are_you_sure_you_want_to_pause_whatsapp: "هل أنت متأكد أنك تريد إستئناف الواتس آب مؤقتا؟",
		remove_whatsapp_connection: "حذف اتصال الواتس آب",
		are_you_sure_you_want_to_remove_whatsapp_connection: "هل أنت متأكد أنك تريد حذف اتصال الواتس آب؟",
		mail_mailer: "نوع البريد",
		mail_host: "مضيف البريد",
		mail_port: "منفذ البريد",
		mail_encryption: "تشفير البريد",
		mail_email: "البريد الإلكتروني للبريد",
		mail_email_password: "كلمة المرور للبريد الإلكتروني",
		mail_from: "إسم المرسال للبريد",
		new_sign_up_email: "البريد الإلكتروني للتسجيل الجديد",
		new_edit_request_email: "البريد الإلكتروني لطلب التعديل",
		current_relation_email: "البريد الإلكتروني للعلاقات الحالية",
		lost_whatsapp_connection_email: "البريد الإلكتروني لفقدان الاتصال بالواتس آب",
		email_setting_edited_successfully: "تم تعديل إعدادات البريد الإلكتروني بنجاح",
		email_subject: "موضوع البريد الإلكتروني",
		sponsor_mobile_should_not_be_the_same_as_mobile: "رقم هاتف ولي الأمر يجب أن لا يكون مشابه لرقم الهاتف",
		waiting_email_verification: "انتظار توثيق البريد الالكتروني",
		delete_my_account: "حذف حسابي",
		main_data: "المعلومات الرئيسية",
		subscription_fees: "رسوم الاشتراك",
		payment_methods: "طرق الدفع",
		main_data_edited_successfully: "تم تعديل المعلومات الرئيسية بنجاح",
		extra_information_edited_successfully: "تم تعديل المعلومات الإضافة بنجاح",
		about_me_edited_successfully: "تم تعديل معلومات عني بنجاح",
		my_desires_edited_successfully: "تم تعديل رغباتي بالطرف الآخر بنجاح",
		resend_email: "إعادة إرسال البريد الإلكتروني",
		verify: "توثيق",
		rejected_requests: "الطلبات المرفوضة",
		change_email: "تغيير البريد الإلكتروني",
		email_changed_successfully: "تم تغيير الايميل بنجاح",
		reject_message: "رسالة الرفض",
		customers_women: "العملاء (النساء)",
		customers_men: "العملاء (الرجال)",
		waiting_user_to_change: "بإنتظار تعديل المستخدم",
		user_changed_profile: "المستخدم عدّل الملف الشخصي",
		customer_request_not_exist: "طلب العميل غير موجود",
		delete_customer: "حذف العميل",
		are_you_sure_you_want_to_delete_the_customer: "هل أنت متأكد أنك تريد حذف العميل؟",
		customer_deleted_successfully: "تم حذف العميل بنجاح",
		my_account_deleted_successfully: "تم حذف حسابي بنجاح",
		are_you_sure_you_want_to_delete_the_account: "هل أنت متأكد أنك تريد حذف الحساب؟",
		owner_whatsapp: "واتس آب المالك",
		contract: "العقد",
		we_will_delete_all_your_information_and_you_can_not_return_your_account_after_that: "سنقوم بحذف جميع بياناتك ولن تتمكن من إستعادة حسابك بعد ذلك",
		payment_gateway_setting: "إعدادات بوابة الدفع",
		email_logo: "شعار البريد الإلكتروني",
		email_is_disabled: "الايميل غير مفعّل",
		you_need_to_sign_up_first: "أنت بحاجة للتسجيل أولا",
		payment_gateway_setting_edited_successfully: "تم تعديل إعدادات بوابة الدفع بنجاح",
		hosting: "المضيف",
		token: "الرمز",
		no_payment_gateway: "لا يوجد بوابة دفع",
		my_fatoorah: "My Fatoorah",
		ready_profile: "ملف كامل",
		under_review: "قيد المراجعة",
		new_under_review: "جديد، تحت المراجعة",
		all_ages: "كل الأعمار",
		all_nationalities: "كل الجنسيات",
		all_countries: "كل الدول",
		all_cities: "كل المدن",
		all_colors: "كل الألوان",
		all_weights: "كل الأوزان",
		all_heights: "كل الأطوال",
		all_medical_statuses: "كل الحالات الصحية",
		all_religions: "كل الأديان",
		all_occupations: "كل المهن",
		all_martial_statuses: "كل الحالات الاجتماعية",
		all_education_levels: "كل المستويات التعليمية",
		all_hijab_types: "كل أنواع الحجاب",
		not_specified: "غير محدد",
		should_be_between_21_and_60: "يجب أن يكون بين 21 و 60",
		men_who_want_a_ligitimate_look: "الرحال الراغبون بنظرة شرعية",
		women_who_want_a_ligitimate_look: "النساء الراغبات بنظرة شرعية",
		those_who_agree_on_a_ligitimacy_look: "المتوافقون على نظرة شرعية",
		my_requests_for_a_ligitimacy_look: "طلباتي لنظرة شرعية",
		others_requests_for_a_ligitimacy_look: "رغبات الآخرين بنظرة شرعية",
		favorite_list: "قائمة التفضيل",
		the_customer_has_been_disabled_please_contact_administrator: "العميل معطّل، الرجاء التواصل مع الإدارة",
		legitimacy_look: "النظرة الشرعية",
		request_legitimacy_look: "طلب نظرة شرعية",
		the_subscriber_has_risen: "لقد قام المشترك",
		incomplete_profile: "الملف الشخصي غير مكتمل",
		or_waiting_management_approval: "أو بإنتظار موافقة الإدارة",
		pluralism_accepted: "تقبل التعدد",
		pluralism_rejected: "ترفض التعدد",
		kg: "كغم",
		cm: "سم",
		accept_legitimacy_look: "الموافقة على نظرة شرعية",
		reject_legitimacy_look: "رفض نظرة شرعية",
		research: "إعادة البحث",
		added_to_favorite_list: "تمت الإضافة إلى قائمة التفضيل",
		removed_from_favorite_list: "تم الحذف من قائمة التفضيل",
		logitimacy_look_has_been_requested_successfully: "تم طلب النظرة الشرعية بنجاح",
		are_you_sure_you_want_to_request_a_legitimacy_look: "هل أنت متأكد أنك تريد طلب نظرة شرعية؟",
		will_be_notified_to_respond_to_your_request: "سيتم إشعار المشترك للرد على طلبك",
		i_accept_conditions: "أوافق على الشروط",
		accept_description: "الموافقة على الوصف",
		accept_other_party_on_description: "موافقة الطرف الآخر على الوصف",
		reject_other_party_on_description: "رفض الطرف الآخر للوصف",
		men_accept_description: "الرجال الموافقون على الوصف",
		women_accept_description: "النساء الموافقات على الوصف",
		agreed: "المتوافقون ",
		years: "سنوات",
		search_year: "سنة",
		i_accept_description: "أوافق على الوصف",
		are_you_sure_you_accept_description: "هل أنت متأكد أنك توافق على وصف",
		description_has_been_accepted_successfully: "تمت الموافقة على الوصف بنجاح",
		my_acceptances_on_description: "موافقاتي على الوصف",
		those_are_accepted_my_description: "الموافقون على وصفي",
		description_accepted: "تمت الموافقة",
		subscriper_has_been_moved_to_my_acceptances_on_description: 'تم نقل المشترك إلى قائمة "موافقاتي على الوصف"',
		will_be_notified_to_respond_to_your_request_and_will_be_moved_to_my_acceptances_on_description:
			'سيتم إشعار المشترك للرد على طلبك وسيتم نقله إلى قائمة "موافقاتي على الوصف"',
		cancel_accept_description: "إلغاء الموافقة على الوصف",
		cancel_accept: "إلغاء الموافقة",
		are_you_sure_you_want_to_cancel_accept_description: "هل أنت متأكد أنك تريد إلغاء الموافقة على الوصف",
		will_be_notified_and_will_be_moved_to_search: 'سيتم إشعار المشترك وسيتم نقله لقائمة "بحث"',
		subscriper_has_been_moved_to_search: 'تم نقل المشترك إلى قائمة "بحث"',
		responses_and_actions: "الردود والإجراءات",
		this_list_include_subscripers_whom_accepted_your_description: "هذه القائمة تحتوي على المشتركين الموافقون على وصفك",
		please_accept_or_reject_the_request: "الرجاء الموافقة او الرفض على الطلب",
		acceptance: "موافقة",
		are_you_sure_you_want_to_accept_the_description_of: "هل أنت متأكد أنك تريد الموافقة على وصف",
		will_be_notified_and_will_be_moved_to_agreed_on_description: 'سيتم إشعار المشترك وسيتم نقله لقائمة "المتوافقون على الوصف"',
		are_you_sure_you_want_to_reject_the_description_of: "هل أنت متأكد أنك تريد الرفض على وصف",
		subscriber_has_been_moved_to_search_list: 'تم نقل المشترك إلى قائمة "بحث"',
		subscriber_has_been_moved_to_favorite_list: 'تم نقل المشترك إلى قائمة "قائمة التفضيل"',
		subscriper_has_been_moved_to_agreed: 'تم نقل المشترك إلى قائمة "المتوافقون"',
		waiting_for_management: "بانتظار الإدارة",
		agreed_on_description: "المتوافقون على الوصف",
		agreed_on_legitimacy_look: "المتوافقون على النظرة الشرعية",
		banned: "المحظورون",
		married_dashboard: "المتزوجون",
		move_to_legitimacy_look: "نقل إلى النظرة الشرعية",
		relation: "العلاقة",
		moved_to_agreed_on_logitimacy_look: "تم النقل إلى المتوافقون على النظرة الشرعية",
		move_to_banned: "نقل إلى المحظورون",
		moved_to_banned: "تم النقل إلى المحظورون",
		reject_description: "رفض الوصف",
		move_to_logitimacy_look: "النقل إلى النظرة الشرعية",
		accept_logitimacy_look: "الموافقة على النظرة الشرعية",
		reject_logitimacy_look: "رفض النظرة الشرعية",
		i_accept_logitimacy_look: "أوافق على النظرة الشرعية",
		are_you_sure_you_want_to_accept_the_logitimacy_look_with: "هل أنت متأكد أنك توافق على النظرة الشرعية مع",
		are_you_sure_you_want_to_reject_the_logitimacy_look_with: "هل أنت متأكد أنك ترفض النظرة الشرعية مع",
		will_be_notified_and_will_be_moved_to_agreed_on_logitimacy_look: 'سيتم إشعار المشترك وسيتم نقله لقائمة "المتوافقون على النظرة الشرعية"',
		subscriper_has_been_moved_to_agreed_on_logitimacy_look: 'تم نقل المشترك إلى قائمة "المتوافقون على النظرة الشرعية"',
		logitimacy_look_has_been_rejected: "تم رفض النظرة الشرعية",
		waiting_for_other_subscriber_to_approve: "بانتظار موافقة المشترك الآخر",
		logitimacy_look_has_been_agreed_waiting_for_management: "تم الاتفاق على النظرة الشرعية، بانتظار الإدارة",
		failed_from_our_side: "فشلت الدفعة لدينا",
		failed_from_knet: "فشلت الدفعة لدى كي نت",
		knet: "كي نت",
		retry_payment: "إعادة محاولة الدفع",
		payment_failed: "فشل في الدفعة",
		payment_id: "رقم الدفعة",
		tran_id: "رقم العملية",
		track_id: "رقم التتبع",
		print_a4: "طباعة A4",
		quantization_process: "عملية تكميم",
		plastic_surgery: "عملية تجميل",
		moved_to_married: "تم النقل إلى المتزوجون",
		move_to_married: "نقل إلى المتزوجون",
		new_sign_up: "تسجيل جديد",
	},
};

const i18n = createI18n({
	legacy: false,
	locale: "en",
	globalInjection: true,
	messages,
});

export default i18n;
