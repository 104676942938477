import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { setToolBarButtons } from "@/core/helpers/PagesCustoms";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/:language(en|ar|)?/",
		component: () => import("@/components/page-layouts/Website.vue"),
		children: [
			{
				path: "",
				name: "website-landing",
				component: () => import("@/views/website/Landing.vue"),
			},
			{
				path: "sign-up",
				name: "website-sign-up",
				component: () => import("@/views/website/SignUp.vue"),
			},
			{
				path: "terms-and-conditions",
				name: "website-terms-and-conditions",
				component: () => import("@/views/website/TermsAndConditions.vue"),
			},
			{
				path: "privacy-policy",
				name: "website-privacy-policy",
				component: () => import("@/views/website/PrivacyPolicy.vue"),
			},
		],
	},
	{
		path: "/:language(en|ar|)?/",
		component: () => import("@/layout/Layout.vue"),
		meta: {
			type: "app",
		},
		children: [
			{
				path: "welcome",
				name: "welcome",
				component: () => import("@/views/Welcome.vue"),
			},
			{
				path: "customer-dashboard",
				name: "customer-dashboard",
				component: () => import("@/views/CustomerDashboard.vue"),
			},
			{
				path: "no-permission",
				name: "no-permission",
				component: () => import("@/views/NoPermission.vue"),
			},
			{
				path: "profile",
				name: "profile",
				component: () => import("@/views/profile/Profile.vue"),
			},
			{
				path: "dashboard",
				name: "dashboard",
				component: () => import("@/views/dashboard/Dashboard.vue"),
			},
			{
				path: "dashboard-men-accept-description",
				name: "dashboard-men-accept-description",
				component: () => import("@/views/dashboard/MenAcceptDescription.vue"),
			},
			{
				path: "dashboard-women-accept-description",
				name: "dashboard-women-accept-description",
				component: () => import("@/views/dashboard/WomenAcceptDescription.vue"),
			},
			{
				path: "dashboard-agreed-on-description",
				name: "dashboard-agreed-on-description",
				component: () => import("@/views/dashboard/AgreedOnDescription.vue"),
			},
			{
				path: "dashboard-agreed-on-legitimacy-look",
				name: "dashboard-agreed-on-legitimacy-look",
				component: () => import("@/views/dashboard/AgreedOnLegitimacyLook.vue"),
			},
			{
				path: "dashboard-banned",
				name: "dashboard-banned",
				component: () => import("@/views/dashboard/Banned.vue"),
			},
			{
				path: "dashboard-married",
				name: "dashboard-married",
				component: () => import("@/views/dashboard/Married.vue"),
			},
			{
				path: "dashboard-current-connections",
				name: "dashboard-current-connections",
				component: () => import("@/views/dashboard/UsersConnections.vue"),
			},
			{
				path: "analysis",
				name: "analysis",
				component: () => import("@/views/analysis/Analysis.vue"),
			},
			{
				path: "analysis/payments-details",
				name: "analysis-payments-details",
				component: () => import("@/views/analysis/PaymentsDetails.vue"),
			},
			{
				path: "analysis/profit-and-loss",
				name: "analysis-profit-and-loss",
				component: () => import("@/views/analysis/ProfitAndLoss.vue"),
			},
			{
				path: "customers-waiting-email-verification",
				name: "customers-waiting-email-verification",
				component: () => import("@/views/customers/CustomersWaitingEmailVerification.vue"),
			},
			{
				path: "customers-requests",
				name: "customers-requests",
				component: () => import("@/views/customers/CustomersRequests.vue"),
			},
			{
				path: "customers",
				name: "customers",
				component: () => import("@/views/customers/Customers.vue"),
			},
			{
				path: "customer/:id",
				name: "customer",
				component: () => import("@/views/customers/Customer.vue"),
			},
			{
				path: "employees",
				name: "employees",
				component: () => import("@/views/employees/Employees.vue"),
			},
			{
				path: "employees/:id",
				name: "employee",
				component: () => import("@/views/employees/Employee.vue"),
			},
			{
				path: "roles",
				name: "roles",
				component: () => import("@/views/employees/Roles.vue"),
			},
			{
				path: "roles/new",
				name: "add-role",
				component: () => import("@/views/employees/Role.vue"),
			},
			{
				path: "roles/:id",
				name: "edit-role",
				component: () => import("@/views/employees/Role.vue"),
			},
			{
				path: "employees-information",
				name: "employees-information",
				component: () => import("@/views/employees/EmployeesInformation.vue"),
			},
			{
				path: "accounting/total-sales",
				name: "total-sales",
				component: () => import("@/views/accounting/TotalSales.vue"),
			},
			{
				path: "accounting/expenses",
				name: "expenses",
				component: () => import("@/views/accounting/Expenses.vue"),
			},
			{
				path: "accounting/expenses/invoice",
				name: "accounting-expenses-invoice",
				component: () => import("@/views/expenses/Invoice.vue"),
			},
			{
				path: "accounting/expenses/invoice/:id",
				name: "accounting-edit-expenses-invoice",
				component: () => import("@/views/expenses/Invoice.vue"),
			},
			{
				path: "accounting/payments",
				name: "payments",
				component: () => import("@/views/accounting/Payments.vue"),
			},
			{
				path: "accounting/knet",
				name: "knet",
				component: () => import("@/views/accounting/Knet.vue"),
			},
			{
				path: "reports/accounting/credit-notes",
				name: "credit-notes",
				component: () => import("@/views/reports/accounting/CreditNotes.vue"),
			},
			{
				path: "setting/accounting-setting",
				name: "accounting-setting",
				component: () => import("@/views/setting/AccountingSetting.vue"),
			},
			{
				path: "setting/website-setting",
				name: "website-setting",
				component: () => import("@/views/setting/WebsiteSetting.vue"),
			},
			{
				path: "setting/notification-setting",
				name: "notification-setting",
				component: () => import("@/views/setting/NotificationSetting.vue"),
			},
			{
				path: "setting/payment-gateway-setting",
				name: "payment-gateway-setting",
				component: () => import("@/views/setting/PaymentGatewaySetting.vue"),
			},
			{
				path: "credit-note",
				name: "add-credit-note",
				component: () => import("@/views/CreditNote.vue"),
			},
			{
				path: "customer-profile",
				name: "customer-profile",
				component: () => import("@/views/customer/Profile.vue"),
			},
			{
				path: "customer-search",
				name: "customer-search",
				component: () => import("@/views/customer/Search.vue"),
			},
			{
				path: "customer-favorite-list",
				name: "customer-favorite-list",
				component: () => import("@/views/customer/FavoriteList.vue"),
			},
			{
				path: "customer-whom-i-liked",
				name: "customer-whom-i-liked",
				component: () => import("@/views/customer/AcceptedDescriptions.vue"),
			},
			{
				path: "responses-and-actions",
				name: "responses-and-actions",
				component: () => import("@/views/customer/ResponsesAndActions.vue"),
			},
			{
				path: "agreed-on-description",
				name: "agreed-on-description",
				component: () => import("@/views/customer/AgreedOnDescription.vue"),
			},
			{
				path: "agreed-on-legitimacy-look",
				name: "agreed-on-legitimacy-look",
				component: () => import("@/views/customer/AgreedOnLegitimacyLook.vue"),
			},
			{
				path: "logout",
				name: "logout",
				component: () => import("@/views/authentication/basic-flow/SignIn.vue"),
			},
		],
	},
	{
		path: "/:language(en|ar|)?/",
		component: () => import("@/components/page-layouts/Auth.vue"),
		children: [
			{
				path: "employee-sign-in",
				name: "employee-sign-in",
				component: () => import("@/views/authentication/basic-flow/EmployeeSignIn.vue"),
			},
			{
				path: "sign-in",
				name: "sign-in",
				component: () => import("@/views/authentication/basic-flow/SignIn.vue"),
			},
			{
				path: "forgot-password",
				name: "forgot-password",
				component: () => import("@/views/authentication/basic-flow/ForgotPassword.vue"),
			},
			{
				path: "reset-password/:token",
				name: "reset-password",
				component: () => import("@/views/authentication/basic-flow/ResetPassword.vue"),
			},
		],
	},
	{
		path: "/:language(en|ar|)?/web-invoice/:uniqueId",
		name: "web-invoice",
		component: () => import("@/views/invoice/WebInvoice.vue"),
	},
	{
		path: "/:language(en|ar|)?/404",
		name: "404",
		component: () => import("@/views/authentication/Error404.vue"),
	},
	{
		path: "/:language(en|ar|)?/500",
		name: "500",
		component: () => import("@/views/authentication/Error500.vue"),
	},
	{
		path: "/:language(en|ar|)?/sign-up-success",
		name: "website-sign-up-success",
		component: () => import("@/views/website/SignUpSuccess.vue"),
	},
	{
		path: "/:language(en|ar|)?/sign-up-error/:paymentUniqueId",
		name: "website-sign-up-error",
		component: () => import("@/views/website/SignUpError.vue"),
	},
	{
		path: "/:language(en|ar|)?/verify/:token",
		name: "website-verify-sign-up",
		component: () => import("@/views/website/VerifySignUp.vue"),
	},
	{
		path: "/:language(en|ar|)?/:pathMatch(.*)*",
		redirect: "/404",
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.onError((error) => {
	if (/ChunkLoadError:.*failed./i.test(error.message)) {
		window.location.reload();
	} else if (/Loading.*chunk.*failed./i.test(error.message)) {
		window.location.reload();
	}
});

router.beforeEach((to, from) => {
	setToolBarButtons([]);

	if (to.meta != undefined && to.meta != null && to.meta.type == "app") {
		store.dispatch(Actions.GET_GLOBAL_INFORMATIONS);
	}

	// Scroll page to top on every route change
	setTimeout(() => {
		window.scrollTo(0, 0);
	}, 100);
});

export default router;
